
import React, { createContext, useContext, useMemo, useReducer } from "react"


type Action = 
    | { type: "setUsageBillingType"; payload: string | null}
    | { type: "setPersonName"; payload: string | null }
    | { type: "setEmail"; payload: string }
    | { type: "setPersonTel"; payload: string | null }
    | { type: "setAddress"; payload: string | null }
    | { type: "setPostalCode"; payload: string | null }
    | { type: "setPostalDistric"; payload: string | null }
    | { type: "setInvoiceSendingType"; payload: number  }
    | { type: "setCompanyName"; payload: string | null }
    | { type: "setContactPerson"; payload: string | null }
    | { type: "setBusinessId"; payload: string | null }
    | { type: "setEInvoiceAddress"; payload: string | null }
    | { type: "setEInvoiceOperator"; payload: string | null }
    | { type: "setMMLPropertyId"; payload: string | null }
    | { type: "setUsageUnitType"; payload: string | null }
    | { type: "setUsageUnit"; payload: string | null }
    | { type: "setWeightCorrectionMultiplier"; payload: number | null }
    | { type: "setDistanceCorrectionMultiplier"; payload: number | null }
    | { type: "setDiscretionalyCorrectionMultiplier"; payload: number | null }
    | { type: "setRoadUnits"; payload: number | null }
    | { type: "setMeetingInviteType"; payload: number  }
    | { type: "setWeight"; payload:  number | null }
    | { type: "setAmount"; payload: number | null }
    | { type: "setAdditionalInformation"; payload: string | null }
    | { type: "setOperatingDistance"; payload: number | null }
    | { type: "setMemberType"; payload: any }
    | { type: "setCostCenterId"; payload: any }
    | { type: "setPropertyId"; payload: any }
    | { type: "setLanguageCode"; payload: any }
    | { type: "setCountryCode"; payload: any }
    | { type: "setUsageUnitId"; payload: any }
    | { type: "setPropertyName"; payload: string | null }



type Dispatch = (action: Action) => void

type State = {
    usageBillingType: string | null ,
    personName: string | null,
    email: string | null,
    personTel: string | null,
    address: string | null,
    postalCode: string | null,
    postalDistric: string | null,
    invoiceSendingType: number ,
    companyName: string | null,
    contactPerson: string | null,
    businessId: string | null,
    eInvoiceAddress: string | null,
    eInvoiceOperator: string | null,
    MMLpropertyId: string | null,
    usageUnitType: string | null,
    usageUnit: string | null,
    weightCorrectionMultiplier: any | number ,
    distanceCorrectionMultiplier: any | number ,
    discretionalyCorrectionMultiplier: any | number,
    roadUnits: any | number ,
    meetingInviteType: number ,
    weight: any | number ,
    amount: any | number ,
    additionalInformation: string | null,
    operatingDistance: any | number ,
    memberType: any,
    costCenterId?: any,
    propertyId?: any ,
    languageCode?: string,
    countryCode?: string,
    usageUnitId?: any,
    propertyName?: string | null
}

const DEFAULT_STATE: State = {
	usageBillingType: null,
	personName: null,
	email: null,
	personTel: "",
	address: null,
	postalCode: null,
	postalDistric: null,
	invoiceSendingType: 1,
	companyName: null,
	contactPerson: "",
	businessId: null,
	eInvoiceAddress:  null,
	eInvoiceOperator: null,
	MMLpropertyId: null,
	usageUnitType: "Asuinkiinteistö",
	usageUnit: "ULA",
	weightCorrectionMultiplier: 1,
	distanceCorrectionMultiplier: 1,
	discretionalyCorrectionMultiplier: 1,
	roadUnits: 1,
	meetingInviteType: 1,
	weight: 1,
	amount: 1,
	additionalInformation: "",
	operatingDistance: 1,
	memberType: "yksityishenkilö",
	costCenterId: null,
	propertyId: null,
	countryCode: "FI",
	languageCode: "fi" ,
	usageUnitId: null,
	propertyName: null
}



const createUserReducer = (state: State, action: Action) => {
	switch(action.type) {
	case "setUsageBillingType":
		return {...state, usageBillingType: action.payload}
	case "setPersonName":
		return {...state, personName: action.payload}
	case "setEmail":
		return {...state, email: action.payload}
	case "setPropertyName":
		return {...state, propertyName: action.payload}
	case "setPersonTel":
		return {...state, personTel: action.payload}
	case "setAddress":
		return {...state, address: action.payload}        
	case "setPostalCode":
		return {...state, postalCode: action.payload}
	case "setPostalDistric":
		return {...state, postalDistric: action.payload}
	case "setInvoiceSendingType":
		return {...state, invoiceSendingType: action.payload}
	case "setCompanyName":
		return {...state, companyName: action.payload}
	case "setContactPerson":
		return {...state, contactPerson: action.payload}
	case "setBusinessId":
		return {...state, businessId: action.payload}
	case "setEInvoiceAddress":
		return {...state, eInvoiceAddress: action.payload}
	case "setEInvoiceOperator":
		return {...state, eInvoiceOperator: action.payload}  
	case "setMMLPropertyId":
		return {...state, MMLpropertyId: action.payload}
	case "setUsageUnitType":
		return {...state, usageUnitType: action.payload}        
	case "setUsageUnit":
		return {...state, usageUnit: action.payload}
	case "setWeightCorrectionMultiplier":
		return {...state, weightCorrectionMultiplier: action.payload}
	case "setDistanceCorrectionMultiplier":
		return {...state, distanceCorrectionMultiplier: action.payload}
	case "setDiscretionalyCorrectionMultiplier":
		return {...state, discretionalyCorrectionMultiplier: action.payload}
	case "setRoadUnits":
		return {...state, roadUnits: action.payload}
	case "setMeetingInviteType":
		return {...state, meetingInviteType: action.payload}
	case "setWeight":
		return {...state, weight: action.payload}  
	case "setAmount":
		return {...state, amount: action.payload}
	case "setAdditionalInformation":
		return {...state, additionalInformation: action.payload}
	case "setOperatingDistance":
		return {...state, operatingDistance: action.payload}
	case "setMemberType":
		return {...state, memberType: action.payload}
	case "setCostCenterId":
		return {...state, costCenterId: action.payload}
	case "setPropertyId":
		return {...state, propertyId: action.payload}
	case "setLanguageCode":
		return {...state, languageCode: action.payload}
	case "setCountryCode":
		return {...state, countryCode: action.payload}
	case "setUsageUnitId":
		return {...state, usageUnitId: action.payload}
	}
} 



type CreateUserProviderProps = {children: React.ReactNode}

const CreateUserStateContext = createContext<
    {state: State; dispatch: Dispatch} | undefined
    >(undefined)

const CreateUserStateProvider = ({children}: CreateUserProviderProps) => {
	const memoChildren = useMemo(() => children, [])
	const [state, dispatch] = useReducer(createUserReducer, DEFAULT_STATE)
	const value = {state, dispatch}
	return (
		<CreateUserStateContext.Provider value={value}>
			{memoChildren}
		</CreateUserStateContext.Provider>
	)
}

const  useCreateUserState = () => {
	const context = useContext(CreateUserStateContext)
	if (context === undefined) {
		throw new Error("useCreateUserState hook must be used within a CreateUserStateProvider")
	}
	return context
}

export {CreateUserStateProvider, useCreateUserState }

