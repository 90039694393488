import React from "react"
import { Chip } from "@mui/material"
import DeleteIcon from "@mui/icons-material/Delete"
import isMobile from "ismobilejs"
import { IconButton } from "@mui/material"

type DeleteChipProps = {
    deleteFunction: (functionParams?: any) => void,
    functionParams?: any
}

const DeleteChip = ({deleteFunction, functionParams}: DeleteChipProps) => {

	const handleDeleteFunction = () => {
		try {
			if(functionParams) {
				deleteFunction(functionParams)
			} else {
				deleteFunction()
			}
		} catch (error) {
			console.error("Error in Delete Function")
			console.error("Function with error: ", deleteFunction())
			console.error("Function parameters: ", functionParams)
		}
	}

	return (
		<React.Fragment>
			{isMobile().any ? 

				<IconButton
					onClick={handleDeleteFunction}
					icon={<DeleteIcon/>}
					sx={{
						position: "absolute",
						top: "5px",
						left: "5px",
					}}
				><DeleteIcon/></IconButton>        
				:
				<Chip
					// color='primary'
					label='Poista'
					variant='contained'
					onClick={handleDeleteFunction}
					icon={<DeleteIcon/>}
					sx={{
						position: "absolute",
						top: "10px",
						left: "10px",
					}}
				/>
			}
		</React.Fragment>
	)
}

export default DeleteChip