const fi = {
	menu: {
		home: "Koti",
		finances: "Talous",
		unravel: "Selvitä",
		chat: "Keskustele",
		partnersInfo: "Osakkaat",
		map: "Kartta",
		meetings: "Kokoukset",
		roadcooperative: "Tiekunta",
		document: "Asiakirjat",
		profile: "Profiili",
		settings: "Asetukset",
		notes: "Muistio",
		ChatBot: "Tuki",
		unitizationTool: "Yksiköinti",
	},
}

export default fi
