import { Tooltip } from "@mui/material"
import { Popover } from "@mui/material"
import { Typography } from "@mui/material"
import { Box } from "@mui/material"
import React, { useState } from "react"

const CreativeCommosForMap = () => {
	const [CCOpen, setCCOpen] = useState<any>()

	return (
		<Box sx={{}}>
			<Tooltip title="">
				<Typography
					onClick={(event: React.MouseEvent<HTMLButtonElement>) => setCCOpen(event.currentTarget)}
					sx={{
						zIndex: 5,
						position: "fixed",
						bottom: 10,
						right: 10,
						background: "#00000057",
						borderRadius: "10px",
						height: "15px",
						width: "25px",
						fontSize: "0.7rem",
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						cursor: "pointer",
					}}
				>
					CC
				</Typography>
			</Tooltip>
			<Popover
				open={CCOpen ? true : false}
				onClose={() => setCCOpen(undefined)}
				anchorEl={CCOpen}
				anchorOrigin={{
					vertical: "top",
					horizontal: "left",
				}}
				transformOrigin={{
					vertical: "bottom",
					horizontal: "right",
				}}
			>
				<Box
					sx={{
						fontSize: "0.5rem",
						padding: "3px",
					}}
				>
					<Typography
						sx={{
							fontSize: "0.5rem",
						}}
					>
						Maanmittauslaitos, vektoritiilitaustakartta sekä kiinteistöjaotustieto.
					</Typography>
					<Typography
						sx={{
							fontSize: "0.5rem",
						}}
					>
						Tieto on ajantasainen. https://www.maanmittauslaitos.fi/avoindata-lisenssi-cc40
					</Typography>
				</Box>
			</Popover>
		</Box>
	)
}

export default CreativeCommosForMap
