import React, { useState } from "react"
import { FormControlLabel } from "@mui/material"
import { IconButton } from "@mui/material"
import VisibilityIcon from "@mui/icons-material/Visibility"
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff"
import { TextField } from "@mui/material"
import { InputAdornment } from "@mui/material"
import { makeStyles } from "@mui/styles"

const useStyles = makeStyles({
	root: {
		"& .MuiInput-underline:after": {
			// Alaviivan tyylit kun elementti on fokuksessa
			border: "none",
		},
		"& .MuiOutlinedInput-root": {
			borderColor: "#e0e0e0", // Poistaa reunan
			"&.Mui-focused fieldset": {
				borderColor: "#e0e0e0", // Poistaa reunan

				border: "none", // Poistaa reunan
				boxShadow: "none", // Poistaa mahdollisen varjon
			},
		},
		"& .MuiOutlinedInput-root:hover fieldset": {
			borderColor: "#ffffff00", // Poistaa reunan
		},
		"& .MuiOutlinedInput-notchedOutline": {
			borderColor: "#e0e0e0",
		},
		"& .MuiFormControl-root.MuiTextField-root": {
			margin: "10px",
			"& .MuiTextField-root": {},
		},
		"& .MuiTextField-root": {
			margin: "10px",
		},
	},
})

type PasswordFieldProps = {
	state?: any
	setState?: React.Dispatch<React.SetStateAction<any>>
	field?: string
	placeholder: string
	id: string
	className?: string
}

const PasswordField = ({ setState, state, field, placeholder, id, className }: PasswordFieldProps) => {
	const classes = useStyles()

	const [passwordShown, setPasswordShown] = useState(false)

	const togglePasswordVisibility = () => {
		setPasswordShown(!passwordShown)
	}

	return (
		// <div style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
		<TextField
			onChange={(e: { target: { value: string } }) => setState && setState({ ...state, [field ? field : ""]: e.target.value })}
			type={passwordShown ? "text" : "password"}
			id={id}
			className={`${classes.root} ${className ? className : "loginFormText2 fadeIn third"}`}
			// className={"fadeIn third"}
			sx={{ width: "89%", textAlignLast: "center", border: "none", margin: "10px" }}
			// variant="standart"
			color="primary"
			name="password"
			placeholder={placeholder}
			InputProps={{
				endAdornment: (
					<InputAdornment position="end">
						<IconButton color="primary" onClick={togglePasswordVisibility} type="button">
							{passwordShown ? <VisibilityOffIcon /> : <VisibilityIcon />}
						</IconButton>
					</InputAdornment>
				),
			}}
		/>

		// </div>
	)
}

export default PasswordField
