import axios from "axios"
import { toast } from "react-toastify"
import authToken from "../../utils/authToken"
import config from "../../utils/config"

const axiosClient = axios.create({
	baseURL: `${config.BACKEND_ROOT_URL}`,
	headers: {
		"Content-type": "application/json",
	},
	proxy:
		// process.env.REACT_APP_ENVIRONMENT == "development"
		// 	? false
		{
			host: `${config.BACKEND_PROXY_HOST}`,
			port: Number(config.BACKEND_PROXY_PORT),
		},
})

axiosClient.interceptors.request.use(
	async (req) => {
		await authToken.checkToken()
		// console.log(" -- 1. AXIOS CLIENT INTERCEPTOR REQUEST --", req, " -- 1. AXIOS CLIENT INTERCEPTOR REQUEST --")
		req.headers["Authorization"] = "Bearer " + authToken.getToken()
		return req
	},
	(error) => {
		console.error(" -- 1. AXIOS CLIENT INTERCEPTOR REQUEST ERROR --", error, " -- 1. AXIOS CLIENT INTERCEPTOR REQUEST ERROR --")
		const res = error.response
		if (res >= 500) {
			toast.error("Ei yhteyttä serveriin. Tarkista internet yhteys.", { role: "global" })
		}
		console.error(error)
		return error
	}
)

axiosClient.interceptors.response.use(
	(res) => {
		// console.log(" -- 1. AXOIS CLIENT INTERCEPTOR RESPONSE -- ", res, " -- 1. AXOIS CLIENT INTERCEPTOR RESPONSE -- ")
		return res
	},
	(error) => {
		console.error(" -- 1. AXOIS CLIENT INTERCEPTOR RESPONSE ERROR -- ", error, " -- 1. AXOIS CLIENT INTERCEPTOR RESPONSE ERROR -- ")
		return error
	}
)

export default axiosClient
