import React, { useEffect, useState } from "react"
import { Typography, Box, Grid, TextField, IconButton, Collapse } from "@mui/material"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import ExpandLessIcon from "@mui/icons-material/ExpandLess"
import CommentsInChat from "./CommentsInChat"
import { toast } from "react-toastify"
import SendIcon from "@mui/icons-material/Send"
import { Tooltip } from "@mui/material"
import { ProfileInfo, CommentProps, ChatProps } from "../Map/ChatPopUp"
import { gql, useMutation } from "@apollo/client"

const CREATE_NEW_CHAT_COMMENT = gql`
	mutation AddNewComment($chatId: IdType = "", $chatUser: String = "", $content: String = "", $userEmail: String = "") {
		createChatComment(input: { chatComment: { chatId: $chatId, chatUser: $chatUser, content: $content, userEmail: $userEmail } }) {
			chatByChatId {
				chatCommentsByChatId {
					nodes {
						chatId
						chatUser
						content
						createdOn
						id
						userEmail
					}
				}
			}
		}
	}
`

interface ChatProps2 {
	chat: {
		id: number
		roadCooperativeId: string
		createdOn: string
		chatUser: string
		userEmail: string
		title: string
		content: string
		chatCommentsByChatId: {
			nodes: CommentProps[]
		}
	}

	profileInfo: ProfileInfo
	refetch: () => void
}

const Chat: React.FC<ChatProps2> = ({ chat, profileInfo, refetch }) => {
	const [newComment, setNewComment] = useState<string>("")
	const [currentid, setCurrentid] = useState<number | null>(null)
	const [expandedChats, setExpandedChats] = useState<{ [key: string]: boolean }>({})
	const [isText, setIsText] = useState(false)
	const [buttonColor, setButtonColor] = useState("gray")

	const [addNewComment, { data, loading, error }] = useMutation(CREATE_NEW_CHAT_COMMENT, {
		onCompleted(data) {
			const newComments = data.createChatComment.chatByChatId.chatCommentsByChatId.nodes
			refetch()
		},

		onError(error) {
			console.error("Error adding comment:", error)
		},
	})

	const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
		if (event.key === "Enter") {
			event.preventDefault()
			setNewComment((prev) => prev + "\n")
		}
	}

	const handleCommentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setNewComment(event.target.value)
	}

	const handleCommentSave = async (id: any) => {
		if (newComment !== "" && newComment) {
			const commentToSave: CommentProps = {
				chatId: chat.id,
				chatUser: profileInfo.name,
				userEmail: profileInfo.email,
				content: newComment,
			}

			const { data } = await addNewComment({ variables: commentToSave })

			setNewComment("")
			setCurrentid(null)
		} else {
			toast.warning("Täytä tekstikenttä")
		}
	}

	const handleInputFocus = (id: any) => {
		setCurrentid(id)
	}

	const toggleChatExpansion = (id: any) => {
		setExpandedChats((prev) => ({ ...prev, [id]: !prev[id] }))
	}

	useEffect(() => {
		if (newComment !== "" && newComment) {
			setIsText(true)
			setButtonColor("primary")
		} else {
			setIsText(false)
			setButtonColor("gray")
		}
	}, [newComment])

	return (
		<Box padding="4px">
			<Typography paddingLeft="10px" paddingRight="3px" paddingTop="2px" variant="subtitle1" color="textSecondary">
				{chat.chatUser} - <br />
				{new Date(chat.createdOn).toLocaleString([], {
					hour12: false,
					year: "numeric",
					month: "numeric",
					day: "numeric",
					hour: "2-digit",
					minute: "2-digit",
				})}
			</Typography>
			<br></br>
			<Grid paddingLeft="10px" paddingRight="3px">
				<Typography variant="h6" sx={{ maxWidth: "700px", whiteSpace: "pre-line", wordWrap: "break-word" }}>
					{chat.title}
				</Typography>
				<Typography variant="body1" sx={{ maxWidth: "700px", fontWeight: 500, whiteSpace: "pre-line", wordWrap: "break-word" }}>
					{chat.content}
				</Typography>
			</Grid>
			<Tooltip title="Näytä kommentit">
				<IconButton onClick={() => toggleChatExpansion(chat.id)}>{expandedChats[chat.id] ? <ExpandLessIcon /> : <ExpandMoreIcon />}</IconButton>
			</Tooltip>
			{expandedChats[chat.id] && (
				<Collapse in={expandedChats[chat.id]} unmountOnExit>
					<CommentsInChat comments={chat.chatCommentsByChatId?.nodes} userEmail={profileInfo.email} />

					<Box container alignItems="center" sx={{ display: "flex", alignItems: "center", alignContent: "center" }}>
						<TextField
							placeholder="Lisää kommentti"
							size="small"
							value={currentid === chat.id ? newComment : ""}
							onChange={handleCommentChange}
							onFocus={() => handleInputFocus(chat.id)}
							onKeyPress={handleKeyPress}
							multiline
							sx={{
								"@media screen and (max-width: 800px)": {
									width: "80%",
								},
								width: "35%",
								minWidth: "220px",
								marginTop: "4px",
								marginBottom: "5px",
								overflow: "hidden",
								fontSize: "1rem",
								lineHeight: "1.5",
								alignSelf: "center",
							}}
						/>

						<Tooltip title="Lähetä">
							<span>
								<IconButton
									disabled={!isText}
									variant="contained"
									color={buttonColor}
									onClick={() => handleCommentSave(chat.id)}
									sx={{ fontWeight: 600 }}
								>
									<SendIcon />
								</IconButton>
							</span>
						</Tooltip>
					</Box>
				</Collapse>
			)}
		</Box>
	)
}

export default Chat
