import { ApolloQueryResult, gql, OperationVariables, useMutation, useQuery } from "@apollo/client"
import { Alert, Button, Stack, Step, StepLabel, Stepper, TextField } from "@mui/material"
import React, { useEffect, useState } from "react"
import { costCenterLinksVar, costCenterPropertyVar } from "../../../network/apolloClient/apolloClientMakeVars"
import { MapComponent } from "../../Map/Map"
import { Box } from "@mui/material"
import { Container } from "@mui/material"
import { Paper } from "@mui/material"
import { Typography } from "@mui/material"
import { Divider } from "@mui/material"
import { toast, ToastContainer } from "react-toastify"
import vaylaDataService from "../../../network/dataServices/vaylaDataService"
import { getKuntakoodiFromName } from "../../Map/mapUtils/kuntakoodit"
import userProfileStorage from "../../../utils/providers/userProfileProvider/userProfileProvider"
import CustomNotification from "../../reusables/CustomNotification"

const costCenterLinksQuery = gql`
	query getCostCenterLinks {
		costCenterLinksVar @client
	}
`

const costCenterPropertyQuery = gql`
	query getCostCenterProperty {
		costCenterPropertyVar @client
	}
`

const CREATE_COST_CENTER = gql`
	mutation CreateCostCenter($properties: [PropertyInput] = {}, $roadLinks: [Link] = {}, $length: Int = 10, $name: String = "") {
		createWholeCostCenter(input: { length: $length, name: $name, properties: $properties, roadLinks: $roadLinks })
	}
`
interface CreateNewCostCenterModalInterface {
	handleCloseCreateCostCenter: VoidFunction
	refetchRCGeoJson: (variables?: Partial<OperationVariables> | undefined) => Promise<ApolloQueryResult<any>>
}

const CreateNewCostCenterModal = ({ handleCloseCreateCostCenter, refetchRCGeoJson }: CreateNewCostCenterModalInterface) => {
	const [activeStep, setActiveStep] = useState<number>(0)

	const [mapContent, setMapContent] = useState<JSX.Element>(<SelectCostCenterRoadsView />)

	const [kunta, setKunta] = useState<string>("")
	const [katu, setKatu] = useState<string>("")
	const [showAlert, setShowAlert] = useState(false)
	const [costCenterLength, setCostCenterLength] = useState<number>(0)
	const [costCenterName, setCostCenterName] = useState<string>(katu ?? "")

	const costCenterPropertyData = useQuery(costCenterPropertyQuery)
	const costCenterLinksData = useQuery(costCenterLinksQuery)

	const [createCostCenter] = useMutation(CREATE_COST_CENTER)
	const [finalPropertyList, setFinalPropertyList] = useState(costCenterPropertyData.data.costCenterPropertyVar)

	const handleCreateCostCenter = async () => {
		try {
			await createCostCenter({
				variables: {
					name: costCenterName,
					length: Math.round(costCenterLength),
					properties: finalPropertyList,
					roadLinks: costCenterLinksData.data.costCenterLinksVar.roadLinks,
				},
				onCompleted() {
					costCenterPropertyVar({ properties: [] })
					costCenterLinksVar({ roadLinks: [] })
					refetchRCGeoJson()
				},
				onError(error) {
					console.error(error)
				},
			})

			handleCloseCreateCostCenter()
			window.location.reload()
		} catch (error) {
			toast.error("Tien osan luonnissa tapahtui virhe!", { role: "global" })
		}
	}

	const handleSearchRoad = (katu: string, kunta: string) => {
		setKatu(katu)
		setCostCenterName(katu)
		setKunta(kunta)
		handleNext()
	}

	const handleBack = () => {
		if (activeStep === 0) return
		setActiveStep((prevActiveStep) => prevActiveStep - 1)
	}

	const handleNext = async () => {
		if (activeStep === 3) {
			handleCreateCostCenter()
			setActiveStep((prveAvtiveStep) => prveAvtiveStep + 1)
		}
		setActiveStep((prveAvtiveStep) => prveAvtiveStep + 1)
	}

	const updateMapContent = async () => {
		if (activeStep === 1) {
			const map = (
				<MapComponent
					id="kustannuspaikkaMap"
					katunimi={katu}
					kuntanimi={kunta}
					selectRoadLinks={true}
					selectProperties={false}
					showSelectedProperties={true}
				/>
			)

			setMapContent(<SelectCostCenterRoadsView kustannuspaikkaMap={map} />)
		}

		if (activeStep === 2) {
			const map = (
				<MapComponent
					id="kustannuspaikkaMap"
					katunimi={katu}
					kuntanimi={kunta}
					showPropertiesBorder={true}
					selectRoadLinks={false}
					selectProperties={true}
					selectedRoadLinks={costCenterLinksData.data.costCenterLinksVar.roadLinks}
					showSelectedRoadLinks={true}
				/>
			)
			setMapContent(<SelectCostCenterPropertiesView map={map} />)
		}
	}

	useEffect(() => {
		updateMapContent()
	}, [activeStep])

	return (
		<>
			<Stepper activeStep={activeStep} alternativeLabel sx={{ height: "80px" }}>
				<Step>
					<StepLabel>Etsi tie</StepLabel>
				</Step>
				<Step>
					<StepLabel>Valitse tie</StepLabel>
				</Step>
				<Step>
					<StepLabel>Valitse kiinteistöt</StepLabel>
				</Step>
				<Step>
					<StepLabel>Nimeä ja tallenna</StepLabel>
				</Step>
			</Stepper>
			{showAlert ? <Alert severity="error"> Nimeä tie </Alert> : null}
			<Box
				sx={{
					height: "calc(100% - 80px - 60px)",
					maxHeight: "90vh",
				}}
			>
				{
					{
						0: <SearchRoadView handleSearchRoad={handleSearchRoad} />,
						1: (
							<SelectCostCenterRoadsView
								kustannuspaikkaMap={
									<MapComponent
										id="kustannuspaikkaMap"
										katunimi={katu}
										kuntanimi={kunta}
										selectRoadLinks={true}
										selectProperties={false}
										showSelectedProperties={true}
									/>
								}
							/>
						),
						2: (
							<SelectCostCenterPropertiesView
								map={
									<MapComponent
										id="kustannuspaikkaMap"
										katunimi={katu}
										kuntanimi={kunta}
										showPropertiesBorder={true}
										selectRoadLinks={false}
										selectProperties={true}
										selectedRoadLinks={costCenterLinksData.data.costCenterLinksVar.roadLinks}
										showSelectedRoadLinks={true}
									/>
								}
							/>
						),
						3: (
							<CostCenterWholeDataView
								kunta={kunta}
								katu={katu}
								setFinalPropertyList={setFinalPropertyList}
								setCostCenterName={setCostCenterName}
								setCostCenterLength={setCostCenterLength}
								costCenterName={costCenterName}
							/>
						),
					}[activeStep]
				}
				{activeStep != 0 ? (
					<Button sx={{ position: "absolute", bottom: "25px", left: "25px" }} color="primary" variant="outlined" onClick={handleBack}>
						Takaisin
					</Button>
				) : null}
				{activeStep === 0 ? null : (
					<Button color="primary" sx={{ position: "absolute", bottom: "25px", right: "25px" }} variant="contained" onClick={handleNext}>
						{activeStep >= 3 ? "Tallenna" : "Seuraava"}
					</Button>
				)}
			</Box>
		</>
	)
}

interface CostCenterWholeDataViewInterface {
	katu: string
	kunta: string
	setCostCenterLength: React.Dispatch<number>
	setFinalPropertyList: React.Dispatch<[]>
	setCostCenterName: React.Dispatch<React.SetStateAction<string>>
	costCenterName: string
}

const CostCenterWholeDataView = ({
	katu,
	kunta,
	setCostCenterLength,
	setFinalPropertyList,
	setCostCenterName,
	costCenterName,
}: CostCenterWholeDataViewInterface) => {
	const costCenterLinksData = useQuery(costCenterLinksQuery)
	const costCenterPropertyData = useQuery(costCenterPropertyQuery)

	const [selectedProperty, setSelectedProperty] = useState<Property[] | null>(costCenterPropertyData.data.costCenterPropertyVar.properties)
	const [allProperties, setAllProperties] = useState(costCenterPropertyData.data.costCenterPropertyVar.properties)

	const [length, setLength] = useState(
		costCenterLinksData.data.costCenterLinksVar.roadLinks.reduce((sum: number, link: RoadLink) => {
			if (link.linkLength) {
				return (sum = sum + link.linkLength)
			}
			return sum
		}, 0)
	)

	useEffect(() => {
		setCostCenterLength(length)
	}, [length])

	useEffect(() => {
		// intial finalList if there is no incoming changes in properties name
		setFinalPropertyList(allProperties)
		setAllProperties(allProperties)
	}, [])

	const handleChangeLength = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
		e.preventDefault()
		setLength(parseInt(e.target.value))
	}
	const currentRC = userProfileStorage({ type: "getActiveRoadCooperative" })

	const map = (
		<MapComponent
			id="kustannuspaikkaMap2"
			katunimi={katu}
			kuntanimi={kunta}
			mapClick={false}
			mapSearchBox={false}
			filterBox={false}
			showSelectedRoadLinks={true}
			selectedRoadLinks={costCenterLinksData.data.costCenterLinksVar.roadLinks}
			showPropertiesFromList={allProperties}
			showSelectedPropertiesFromList={selectedProperty}
			showPropertiesBorder={true}
			setCenterCoorinates={true}
		/>
	)

	const handleChangePropertyName = (property: Property, index: number, value: string) => {
		// update PropertyName and Update all changes in to finalList

		const tempProps = allProperties.map((property2: Property, index2: number) => {
			if (index2 !== index) {
				return property2
			} else return { ...property, propertyName: value }
		})

		setAllProperties(tempProps)
		setFinalPropertyList(tempProps)
	}

	const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (e.target.value.length > 44) {
			toast.error("Tien nimen maksimi pituus on 45 merkkiä", { toastId: "costCenterNameWarning" })
			return
		}
		setCostCenterName(e.target.value)
	}

	return (
		<>
			<Box sx={{ position: "absolute", zIndex: 1 }}>
				<Paper
					elevation={5}
					sx={{
						marginTop: "5px",
						background: "#fff",
						zIndex: 1,
						padding: "10px",
						width: "300px",
						maxHeight: "60vh",
						overflow: "auto",
					}}
				>
					<Typography sx={{ fontSize: "1.3rem", textAlign: "center" }}>Tien tiedot </Typography>

					<TextField
						inputProps={{
							maxLength: 45,
						}}
						variant="filled"
						sx={{ fontSize: "1.3rem" }}
						label="Tien nimi"
						onChange={handleNameChange}
						value={costCenterName ?? ""}
					/>

					<TextField
						disabled
						label="Tien pituus metreinä"
						sx={{ marginTop: "20px" }}
						value={Math.round(length) ?? 0}
						onChange={(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => handleChangeLength(e)}
					/>
					<Typography sx={{ fontSize: "1.2rem", textAlign: "center", paddingBottom: "15px", paddingTop: "20px" }}>Nimeä tilat</Typography>
					{allProperties.map((property: Property, index: number) => {
						return (
							<Stack
								direction="row"
								spacing={2}
								key={index}
								sx={{
									justifyContent: "center",
									alignItems: "center",
									paddingBottom: "10px",
								}}
							>
								<TextField
									label={property.mmlPropertyId}
									value={property?.propertyName ?? ""}
									onChange={(e: { target: { value: string } }) => handleChangePropertyName(property, index, e.target.value)}
								/>
								<Divider></Divider>
							</Stack>
						)
					})}
				</Paper>
			</Box>

			<Box sx={{ height: "100%", maxWidth: "none" }}>{map}</Box>
		</>
	)
}

interface SelectCostCenterPropertiesViewInterface {
	map: any
}

const SelectCostCenterPropertiesView = ({ map }: SelectCostCenterPropertiesViewInterface) => {
	return (
		<>
			<Box sx={{ height: "100%", maxWidth: "none" }}>
				<CustomNotification
					CollapseProps={{ sx: { zIndex: 1 } }}
					severity="info"
					sx={{ zIndex: 1, position: "relative", marginBottom: "-52px", top: "4px " }}
				>
					{" "}
					Valitse tiekuntaasi kuuluvat kiinteistöt kartasta klikkaamalla.
				</CustomNotification>
				{map}
			</Box>
		</>
	)
}

const SelectCostCenterRoadsView = (props: any) => {
	return (
		<>
			<Box sx={{ height: "100%", maxWidth: "none", marginTop: "0px" }}>
				<CustomNotification
					CollapseProps={{ sx: { zIndex: 1 } }}
					severity="info"
					sx={{ zIndex: 1, position: "relative", marginBottom: "-52px", top: "4px " }}
				>
					{" "}
					Valitse tiesi kartasta klikkaamalla sitä.
				</CustomNotification>
				{props.kustannuspaikkaMap}
			</Box>
		</>
	)
}

const SearchRoadView = (props: any) => {
	const [road, setRoad] = useState<string>("")
	const [city, setCity] = useState<string>("")

	const [showAlert, setShowAlert] = useState(false)

	const handleConfirmTextFields = async () => {
		if (road === "" || city === "") {
			setShowAlert(true)
			setTimeout(() => {
				setShowAlert(false)
			}, 5000)
			return
		}

		try {
			const tempRoad = road.replace(/\d.*$/, "")
			const trimmedRoad = tempRoad.trim()
			const kuntakoodi = getKuntakoodiFromName(city)
			if (kuntakoodi == undefined && kuntakoodi == null) {
				toast.error("Kuntaa ei löytynyt", { role: "global" })
				throw 403
			}
			const coordinatePoint = await vaylaDataService.selectRoadByKatunimiAndKuntakoodi(kuntakoodi, trimmedRoad)
			// virhe tie uittosalmentie mikkeli
			if (coordinatePoint.features[0].properties.virheet) {
				toast.error(coordinatePoint.features[0].properties.virheet, { role: "global" })
				throw 403
			}

			props.handleSearchRoad(trimmedRoad, city)
		} catch (error) {
			console.error(error)
		}
	}

	return (
		<Stack direction="column" sx={{ alignItems: "center" }} spacing={2}>
			<Typography sx={{ textAlign: "center", maxWidth: "600px", padding: "20px" }}>
				Tämän työkalun avulla voit lisätä kartalta tiekuntasi tien ja kiinteistöt.
			</Typography>
			<Typography sx={{ textAlign: "center", maxWidth: "1000px", paddingBottom: "10px", fontWeight: "500" }}>
				HUOM! Tievahdissa on mahdollista jakaa tiekunta hallinnollisesti eri osiin, eli kustannuspaikkoihin. Tällöin hallinnoitavat tiekokonaisuudet ja
				niille kuuluvat kiinteistöt lisätään yksi kustannuspaikka kerrallaan hyödyntäen tätä samaa työkalua.
			</Typography>
			<Typography sx={{ textAlign: "center", maxWidth: "1000px", paddingBottom: "10px", fontWeight: "500" }}>
				Mikäli haluat hallinnoida tiekuntaasi siten, että kaikki kiinteistöt ja tiet kuuluvat samaan hallinnolliseen yksikköön, lisää kaikki tiekunnan
				tiet ja kiinteistöt yhdellä kerralla. Tällöin kustannuspaikkajaottelua eri tien osien välillä ei luoda. Jos olet epävarma siitä, kumpaa tapaa
				sinun tulisi käyttää, suosittelemme tekemään näin. Tämä on yleisin tapa hoitaa tiekunnan hallintoa.
			</Typography>
			<Typography sx={{ textAlign: "center", maxWidth: "600px", padding: "10px" }}>
				Aloita tien lisääminen syöttämällä tien nimi ja kunta alla oleviin kenttiin.
			</Typography>
			{showAlert ? <Alert severity="error">Täytä molemmat kentät!</Alert> : null}
			<TextField
				inputProps={{
					maxLength: 100,
				}}
				fullWidth
				type="text"
				id="katu"
				label="Katu"
				value={road ?? ""}
				sx={{ maxWidth: "400px", padding: 0 }}
				onChange={(e: any) => setRoad(e.target.value)}
				onKeyPress={(ev: React.KeyboardEvent) => {
					if (ev.key === "Enter") {
						handleConfirmTextFields()
						ev.preventDefault()
					}
				}}
			/>
			<TextField
				inputProps={{
					maxLength: 30,
				}}
				fullWidth
				type="text"
				id="kunta"
				label="Kunta"
				value={city}
				sx={{ maxWidth: "400px", padding: 0 }}
				onChange={(e: any) => setCity(e.target.value)}
				onKeyPress={(ev: React.KeyboardEvent) => {
					if (ev.key === "Enter") {
						handleConfirmTextFields()
						ev.preventDefault()
					}
				}}
			/>
			<Button
				sx={{ maxWidth: "400px", height: "60px" }}
				variant="contained"
				fullWidth
				onClick={handleConfirmTextFields}
				onKeyPress={(ev: React.KeyboardEvent) => {
					if (ev.key === "Enter") {
						handleConfirmTextFields()
						ev.preventDefault()
					}
				}}
			>
				Hae tie
			</Button>
		</Stack>
	)
}

export default CreateNewCostCenterModal
