import React, { useEffect, useState } from "react"
import "./Map.css"
import { Paper, Grid, Fab, Box, Typography, IconButton, DialogContent } from "@mui/material"
import { gql, useQuery, useMutation } from "@apollo/client"
import MessageIcon from "@mui/icons-material/Message"
import ChatPage from "../ChatPage/ChatPage"
import Draggable from "react-draggable"
import { Tooltip } from "@mui/material"
import ZoomOutMapIcon from "@mui/icons-material/ZoomOutMap"
import ZoomInMapIcon from "@mui/icons-material/ZoomInMap"
import CloseIcon from "@mui/icons-material/Close"
import DialogCloseButtonToDraggable from "../reusables/DialogCloseButtonToDraggable"

export const GET_RC_CHATS = gql`
	query chat {
		roadCooperativeWithJWT {
			chatsByRoadCooperativeId {
				totalCount
				nodes {
					chatUser
					userEmail
					content
					createdOn
					id
					title
					roadCooperativeId
					chatCommentsByChatId {
						totalCount
						nodes {
							chatId
							chatUser
							userEmail
							content
							createdOn
							id
						}
					}
				}
			}
		}
	}
`

const UPDATE_READ_STATUS = gql`
	mutation UpdateReadStatus($chatsAndCommentsCount: Int = 10) {
		updateUserReadStatusExtended(input: { userReadStatusPatch: { chatsAndCommentsCount: $chatsAndCommentsCount } }) {
			chatsAndCommentsCount
		}
	}
`

const FIND_READ_STATUS_TABLE = gql`
	query findReadStatusTable($email: String = "") {
		roadCooperativeWithJWT {
			userReadStatusesByRoadCooperativeId(condition: { email: $email }) {
				nodes {
					chatsAndCommentsCount
					id
				}
			}
		}
	}
`

// Määrittele tyyppi yhdelle chat-kohteelle
export interface ChatProps {
	id?: number
	roadCooperativeId: string
	chatUser: string
	userEmail: string
	title: string
	content: string
}

// Määrittele tyyppi profiilitiedoille
export interface ProfileInfo {
	name: string
	email: string
}

export interface RoadCooperativeInfo {
	id: string
}
export interface CommentProps {
	id?: number
	chatId?: number
	chatUser?: string
	userEmail?: string
	content?: string
}

const ChatPopUp = () => {
	const [chats, setChats] = useState<ChatProps[]>([])
	const [profileInfo, setProfileInfo] = useState<ProfileInfo | null>(null)
	const [rcInfo, setRCInfo] = useState<RoadCooperativeInfo>({ id: "" })
	const [readCount, setReadCount] = useState<number | null>(null)
	const [unreadCount, setUnreadCount] = useState<number | null>(null)
	const [totalChatsAndComments, setTotalChatsAndComments] = useState<number | null>(null)
	const [readStatusId, setReadStatusId] = useState<number | null>(null)
	const [lockChat, setLockChat] = useState(true)
	const [firstRender, setFirstRender] = useState(true)

	const {
		data: Chatdata,
		loading: loadingChatData,
		error: chatDataError,
		refetch: refetchChatData,
	} = useQuery(GET_RC_CHATS, {
		fetchPolicy: "network-only",
		onCompleted(data) {
			const allChats = data.roadCooperativeWithJWT.chatsByRoadCooperativeId.nodes
			const usersOwnChatsLength = data.roadCooperativeWithJWT.chatsByRoadCooperativeId.nodes.filter(
				(chat: ChatProps) => chat.userEmail === profileInfo?.email
			).length
			const usersOwnCommentsLength = data.roadCooperativeWithJWT.chatsByRoadCooperativeId.nodes.reduce(
				(acc: number, chat: any) =>
					acc + chat.chatCommentsByChatId.nodes.filter((comment: CommentProps) => comment.userEmail === profileInfo?.email).length,
				0
			)

			const totalChats = parseInt(data.roadCooperativeWithJWT.chatsByRoadCooperativeId.totalCount) - usersOwnChatsLength
			const totalComments = parseInt(allChats.reduce((acc: number, chat: any) => acc + chat.chatCommentsByChatId.totalCount, 0)) - usersOwnCommentsLength

			setTotalChatsAndComments(totalChats + totalComments)
		},
	})

	const {
		data: FoundReadStatusdata,
		loading: loadingReadStatus,
		error: readStatusError,
		refetch: refetchReadStatus,
	} = useQuery(FIND_READ_STATUS_TABLE, {
		variables: { email: profileInfo?.email },
		skip: !profileInfo?.email,
		fetchPolicy: "network-only",
		onCompleted(data) {
			const readCount = data.roadCooperativeWithJWT.userReadStatusesByRoadCooperativeId.nodes[0]?.chatsAndCommentsCount || 0
			setReadStatusId(data.roadCooperativeWithJWT.userReadStatusesByRoadCooperativeId.nodes[0]?.id || 0)
			setReadCount(readCount)
		},
	})

	const [updateReadStatus] = useMutation(UPDATE_READ_STATUS)

	const handleChatOpen = () => {
		// Kutsu mutaatiota ja välitä muuttujat
		updateReadStatus({
			variables: {
				chatsAndCommentsCount: totalChatsAndComments,
			},
		})
			.then((response) => {
				setUnreadCount(0)
				refetchReadStatus() // Varmista, että tämä funktio on määritelty
			})
			.catch((error) => {
				console.error("Virhe lukutilan päivittämisessä:", error)
			})
	}

	useEffect(() => {
		const profileInfoString = localStorage.getItem("userProfileInfo")
		if (profileInfoString) {
			const userProfile: ProfileInfo = JSON.parse(profileInfoString)
			setProfileInfo(userProfile)
		}

		const rcInfoString = localStorage.getItem("userActiveRoadCooperative")
		if (rcInfoString) {
			const roadCooperativeInfo: RoadCooperativeInfo = JSON.parse(rcInfoString)
			setRCInfo(roadCooperativeInfo)
		}
	}, [])

	useEffect(() => {
		if (totalChatsAndComments !== null && readCount !== null) {
			const unreadChatsAndComments = totalChatsAndComments - readCount
			setUnreadCount(unreadChatsAndComments)
		}
	}, [totalChatsAndComments, readCount])

	const handleToggleChatPopup = (event: React.MouseEvent<HTMLButtonElement>) => {
		const chatBox = document.getElementById("chatBox")

		if (chatBox) {
			if (chatBox.style.visibility === "visible") {
				chatBox.style.opacity = "0"
				chatBox.style.visibility = "hidden"
			} else {
				chatBox.style.opacity = "1"
				chatBox.style.visibility = "visible"
				handleChatOpen()
				setFirstRender(false)
			}
		}
	}
	const toggleChatPopUp = () => {
		if (!firstRender) {
			const chatBox = document.getElementById("chatBox")
			if (chatBox) {
				if (chatBox.style.visibility === "visible") {
					chatBox.style.opacity = "0"
					chatBox.style.visibility = "hidden"
				} else {
					chatBox.style.opacity = "1"
					chatBox.style.visibility = "visible"
				}
			}
		}
	}

	const toggleLock = () => {
		setLockChat(!lockChat)
	}

	useEffect(() => {
		if (Chatdata && Chatdata.roadCooperativeWithJWT?.chatsByRoadCooperativeId?.nodes) {
			setChats(Chatdata.roadCooperativeWithJWT?.chatsByRoadCooperativeId?.nodes)
		}
	}, [Chatdata])

	if (loadingChatData || loadingReadStatus) {
		return null
	}
	if (chatDataError) {
		console.log("Error in loading chat data")
		return null
	}
	if (readStatusError) {
		console.log("Error in loading read_status_data")
		return null
	}

	return (
		<>
			<Fab
				onClick={handleToggleChatPopup}
				color={"primary"}
				sx={{ padding: "10px", maxWidth: "400px", position: "absolute", bottom: `5px`, right: "20px", zIndex: 3000 }}
			>
				<MessageIcon />
			</Fab>
			{lockChat ? (
				<Paper
					id={"chatBox"}
					sx={{
						"@media screen and (max-width: 800px)": {
							height: "calc(100% - 100px)",
						},
						padding: "10px",
						paddingTop: "0px",
						maxWidth: "400px",
						position: "absolute",
						zIndex: 2000,
						opacity: "1",
						transition: "visibility 0.5s, opacity 0.1s linear",
						visibility: "hidden",
						maxHeight: "670px",
						height: "670px",
						width: "fit-content",
						flexGrow: 1,
						display: "flex",
						flexDirection: "column",
						bottom: `70px`,
						right: "4px",
					}}
					className="chat-popoup-handler"
				>
					<Box justifyContent="space-between" display="flex" alignItems="center" sx={{ width: "100%", paddingTop: "3px", paddingBottom: "3px" }}>
						<Tooltip title={lockChat ? "Aukaise omaan ikkunaan" : "Sulje oma ikkuna"} sx={{ position: "absolute", alignItems: "start" }}>
							<IconButton variant="contained" onClick={toggleLock} sx={{ fontWeight: 600 }}>
								{!lockChat ? <ZoomInMapIcon /> : <ZoomOutMapIcon />}
							</IconButton>
						</Tooltip>
						<Tooltip title={"Sulje chat"} sx={{ position: "absolute", alignItems: "center" }}>
							<IconButton variant="contained" onClick={toggleChatPopUp} sx={{ fontWeight: 600, marginRight: "2px" }}>
								<CloseIcon />
							</IconButton>
						</Tooltip>
					</Box>

					<ChatPage
						profileInfo={profileInfo}
						rcInfo={rcInfo}
						setChats={setChats}
						chats={chats}
						refetchChatData={refetchChatData}
						setLockChat={setLockChat}
						lockChat={lockChat}
						toggleChatPopUp={toggleChatPopUp}
					/>
				</Paper>
			) : (
				<Draggable handle=".chat-popoup-handler" bounds="parent" defaultPosition={{ x: window.innerWidth - 850, y: window.innerHeight - 800 }}>
					<Paper
						id={"chatBox"}
						sx={{
							"@media screen and (max-width: 800px)": {
								height: "calc(100% - 100px)",
							},
							padding: "10px",
							paddingTop: "0px",
							position: "absolute",
							zIndex: 1000,
							opacity: "1",
							transition: "visibility 0.5s, opacity 0.1s linear",
							visibility: "hidden",
							height: "700px",
							width: "700px",
							minWidth: "370px",
							minHeight: "650px",
							maxWidth: "70%",
							maxHeight: "90%",

							overflow: "hidden",
							flexGrow: 1,
							display: "flex",
							flexDirection: "column",

							resize: "both",
						}}
					>
						<Box
							className="chat-popoup-handler"
							justifyContent="space-between"
							display="flex"
							alignItems="center"
							sx={{ padding: "10px", paddingTop: "30px", paddingBottom: "20px", width: "100%" }}
						>
							<Tooltip title={lockChat ? "Aukaise omaan ikkunaan" : "Sulje oma ikkuna"} sx={{ position: "absolute", alignItems: "center" }}>
								<IconButton
									onTouchEnd={toggleLock}
									onClick={toggleLock}
									// onClick={toggleLock}
									sx={{ fontWeight: 600, position: "absolute", top: 5, left: 5 }}
								>
									{!lockChat ? <ZoomInMapIcon /> : <ZoomOutMapIcon />}
								</IconButton>
							</Tooltip>
							<Tooltip title={"Sulje chat"} sx={{ position: "absolute", alignItems: "center" }}>
								{/* <IconButton color="primary" variant="contained" onClick={toggleChatPopUp} sx={{ fontWeight: 600, marginRight: "5px" }}>
									<CloseIcon />
								</IconButton> */}
								<DialogCloseButtonToDraggable closeFunction={toggleChatPopUp} />
							</Tooltip>
						</Box>
						<ChatPage
							profileInfo={profileInfo}
							rcInfo={rcInfo}
							setChats={setChats}
							chats={chats}
							refetchChatData={refetchChatData}
							setLockChat={setLockChat}
							lockChat={lockChat}
							toggleChatPopUp={toggleChatPopUp}
						/>
					</Paper>
				</Draggable>
			)}

			{unreadCount === null || unreadCount === 0 ? null : (
				<Box
					alignItems="center"
					sx={{
						position: "fixed",
						bottom: 50,
						right: 12,
						width: 25,
						height: 25,
						borderRadius: "50%",
						backgroundColor: "#e74c3c;",
						display: "flex",

						justifyContent: "center",
						color: "black",
						zIndex: 600,
					}}
				>
					{unreadCount}
				</Box>
			)}
		</>
	)
}
export default ChatPopUp
