import React, { useEffect, useState } from "react"
import { Box, Stack, Paper, DialogContent, Tab, Tabs } from "@mui/material"
import CustomDialogTitle from "../reusables/CustomDialogTitle"
import Draggable from "react-draggable"
import AllNotes from "./AllNotes"
import AllTasks from "./AllTasks"
import DialogCloseButtonToDraggable from "../reusables/DialogCloseButtonToDraggable"

const NotesPage = ({
	setOpenNotesDialog,
	openNotesDialog,
}: {
	openNotesDialog: boolean
	setOpenNotesDialog: React.Dispatch<React.SetStateAction<boolean>>
}) => {
	const [selectedView, setSelectedView] = useState(1)

	const handleChange = (event: React.ChangeEvent<HTMLFormElement>, newValue: number) => {
		setSelectedView(newValue)
	}

	if (!openNotesDialog) return <></>

	return (
		<>
			<div id="notsPage" style={{ position: "relative", zIndex: "1490", height: "0px" }}>
				<Draggable handle=".notesPage-table-popoup-handler" defaultPosition={{ x: 250, y: 10 }}>
					<Paper
						sx={{
							height: "auto",
							width: "fit-content",

							"@media screen and (max-width: 700px)": {
								margin: "0px",
								padding: "0px",
								maxWidth: "400px",
								position: "absolute",
								left: -240,
								top: 0,
							},
						}}
						elevation={5}
					>
						<Box
							sx={{
								display: "flex",
								justifyContent: "center",
							}}
							className="notesPage-table-popoup-handler"
						>
							<DialogCloseButtonToDraggable closeFunction={() => setOpenNotesDialog(false)} />
							<CustomDialogTitle>Muistio</CustomDialogTitle>
						</Box>
						<Stack sx={{ marginLeft: "2px", borderBottom: "1px solid #e0e0e0", marginRight: "2px", paddingBottom: "2px" }}>
							<Tabs value={selectedView} onChange={handleChange} sx={{ paddingBottom: "1px" }} variant="scrollable">
								<Tab label="Tehtävät" value={1} sx={{ fontWeight: "700", fontSize: "1rem", color: "#505050" }} />
								<Tab label="Muistiinpanot" value={2} sx={{ fontWeight: "700", fontSize: "1rem", color: "#505050" }} />
							</Tabs>
						</Stack>
						<DialogContent
							sx={{
								minWidth: "400px",
								resize: "both",
								width: "fit-content",
								overflow: "hidden",
								padding: "10px",
								height: "500px",
								"@media screen and (max-width: 700px)": {
									margin: "0px",
									padding: "0px",
									maxWidth: "400px",
								},
							}}
						>
							{
								{
									1: <AllTasks />,
									2: <AllNotes />,
								}[selectedView ?? 1]
							}
						</DialogContent>
					</Paper>
				</Draggable>
			</div>
		</>
	)
}
export default NotesPage
