import React, { useState } from "react"
import { Box, Stack, Paper, DialogContent, Tab, Tabs } from "@mui/material"
import Draggable from "react-draggable"
import CustomDialogTitle from "../../reusables/CustomDialogTitle"
import PartnersPopup from "./components/PartnersPopup"
import OtherContacts from "./components/OtherContacts"
import DialogCloseButtonToDraggable from "../../reusables/DialogCloseButtonToDraggable"

const RCContactPage = ({
	setOpenRCContactsDialog,
	openRCContactsDialog,
}: {
	openRCContactsDialog: boolean
	setOpenRCContactsDialog: React.Dispatch<React.SetStateAction<boolean>>
}) => {
	const [selectedView, setSelectedView] = useState(1)
	const [overflowhidden, setOverflowHidden] = useState("auto")

	const handleChange = (event: React.ChangeEvent<HTMLFormElement>, newValue: number) => {
		setSelectedView(newValue)
		if (newValue === 3) {
			setOverflowHidden("hidden")
		} else {
			setOverflowHidden("auto")
		}
	}

	if (!openRCContactsDialog) return <></>

	return (
		<>
			<div id="RCContactPage" style={{ position: "relative", zIndex: "1301", height: "0px" }}>
				<Draggable handle=".notesPage-table-popoup-handler" defaultPosition={{ x: 300, y: 10 }}>
					<Paper
						sx={{
							height: "auto",
							width: "fit-content",
							"@media screen and (max-width: 1024px)": {
								margin: "0px",
								padding: "0px",
								maxWidth: "400px",
								position: "absolute",
								left: -240,
								top: 0,
							},
							"@media screen and (max-width: 1124px)": {
								maxWidth: "400px",
							},
						}}
						elevation={5}
					>
						<Box
							sx={{
								display: "flex",
								justifyContent: "center",
							}}
							className="notesPage-table-popoup-handler"
						>
							<DialogCloseButtonToDraggable closeFunction={() => setOpenRCContactsDialog(false)} />
							<CustomDialogTitle>Yhteystiedot</CustomDialogTitle>
						</Box>
						<Stack sx={{ marginLeft: "2px", borderBottom: "1px solid #e0e0e0", marginRight: "2px", paddingBottom: "2px" }}>
							<Tabs value={selectedView} onChange={handleChange} sx={{ paddingBottom: "1px" }} variant="scrollable">
								<Tab label="Osakkaat" value={1} />
								<Tab label="Kontaktit" value={2} />
							</Tabs>
						</Stack>
						<DialogContent
							sx={{
								// minWidth: "450px",
								resize: "both",
								minWidth: "350px",
								height: "500px",
								padding: "10px",
								paddingTop: "0px",
								overflowY: overflowhidden,
								"@media screen and (max-width: 1024px)": {
									margin: "0px",
									padding: "0px",
									maxWidth: "400px",
								},
							}}
						>
							{
								{
									1: <PartnersPopup />,
									2: <OtherContacts />,
								}[selectedView ?? 1]
							}
						</DialogContent>
					</Paper>
				</Draggable>
			</div>
		</>
	)
}
export default RCContactPage
