import React, { useState } from "react"
import { Link } from "react-router-dom"
import "./SideNavBar.css"
import widelogo from "./download.svg"
import { useTranslation } from "react-i18next"
import ChatBot from "./ChatBot"
import NotesPage from "../NotesPage/NotesPage"

// Icon imports here
import { ReactComponent as HomeIcon } from "../../resources/icons/home.svg"
import { ReactComponent as FinancesIcon } from "../../resources/icons/finances.svg"
import { ReactComponent as UnravelIcon } from "../../resources/icons/unravel.svg"
import { ReactComponent as ChatIcon } from "../../resources/icons/chat.svg"
import { ReactComponent as MapIcon } from "../../resources/icons/map.svg"
import { ReactComponent as MeetingsIcon } from "../../resources/icons/meetings.svg"
import { ReactComponent as RoadCooperativeIcon } from "../../resources/icons/roadcooperative.svg"
import { ReactComponent as ProfileIcon } from "../../resources/icons/profile.svg"
import { ReactComponent as SettingsIcon } from "../../resources/icons/settings.svg"
import { ReactComponent as EnglishFlag } from "../../resources/icons/gb.svg"
import { ReactComponent as SwedishFlag } from "../../resources/icons/se.svg"
import { ReactComponent as FinnishFlag } from "../../resources/icons/fi.svg"
import ArchitectureIcon from "@mui/icons-material/Architecture"
import FactCheckIcon from "@mui/icons-material/FactCheck"
import HelpOutlineIcon from "@mui/icons-material/HelpOutline"
import GroupsIcon from "@mui/icons-material/Groups"
import FolderOpenIcon from "@mui/icons-material/FolderOpen"
import RoadCooperativeContacts from "../HomePage/RoadCooperativeContactsPage/RoadCooperativeContacts"
import PermContactCalendarOutlinedIcon from "@mui/icons-material/PermContactCalendarOutlined"
import authToken from "../../utils/authToken"
// Icon imports end

const SideNavBar = ({ permission }: any) => {
	const { t, i18n } = useTranslation()

	type LanguageOption = {
		[key: string]: {
			nativeName: string
		}
	}

	const lngs: LanguageOption = {
		en: { nativeName: "English" },
		fi: { nativeName: "Suomi" },
		sv: { nativeName: "Svenska" },
	}

	type MenuLinkProps = {
		name: string
		path: string
	}
	// TODO: figure out if you can import SVGs directly with URL, then this resolver would be useless
	const IconResolver = (name: string) => {
		switch (name) {
			case "home":
				return <HomeIcon />
			case "finances":
				return <FinancesIcon />
			case "partnersInfo":
				return <GroupsIcon fontSize="large" sx={{ width: 40, height: 40 }} /> // TODO: update the icon at some point
			case "unravel":
				return <UnravelIcon />
			case "chat":
				return <ChatIcon />
			case "map":
				return <MapIcon />
			case "meetings":
				return <MeetingsIcon />
			case "document":
				return <FolderOpenIcon fontSize="large" sx={{ width: 40, height: 40 }} /> // TODO: update the icon at some point
			case "roadcooperative":
				return <RoadCooperativeIcon />
			case "profile":
				return <ProfileIcon />
			case "Chat":
				return <HelpOutlineIcon />
			case "settings":
				return <SettingsIcon />
			case "notes":
				return <FactCheckIcon fontSize="large" sx={{ width: 40, height: 40 }} /> ////////// TODO
			case "contacts":
				return <FactCheckIcon fontSize="large" sx={{ width: 40, height: 40 }} />
			case "unitizationTool":
				return <ArchitectureIcon fontSize="large" sx={{ width: 40, height: 40 }} />
		}
	}

	const MenuLink = (props: MenuLinkProps) => {
		return (
			<Link to={props.path} className="nav_link" id={props.name} style={{ textDecoration: "none" }}>
				{IconResolver(props.name)}
				<span className="nav_name">{t(`menu.${props.name}`)}</span>
			</Link>
		)
	}

	type flagProps = {
		language: string
	}

	const Flag = (props: flagProps) => {
		switch (props.language) {
			case "en":
				return <EnglishFlag />
			case "sv":
				return <SwedishFlag />
			case "fi":
				return <FinnishFlag />
			default:
				return null
		}
	}

	document.addEventListener("DOMContentLoaded", function () {
		const hover = document.getElementById("nav-bar")

		// console.log(hover)

		/*===== LINK ACTIVE =====*/
		const linkColor = document.querySelectorAll(".nav_link")

		linkColor.forEach((l) =>
			l.addEventListener("click", () => {
				linkColor.forEach((item) => item.classList.remove("active"))
				l.classList.add("active")
				// console.log(linkColor);
				window.localStorage.setItem("linksColor", l.id)

				// console.log(window.localStorage.getItem('linksColor'))
				// console.log(active);
			})
		)

		if (window.localStorage.getItem("linksColor")) {
			const item: string = window.localStorage.getItem("linksColor") ?? ""
			document.getElementById(item)
			// console.log(item, "  -------------- item ----------");

			return
		}

		// Your code to run since DOM is loaded and ready
	})
	///
	const [openChatDialog, setOpenChatDialog] = useState(false)
	const [openNotesDialog, setOpenNotesDialog] = useState(false)
	const [openRCContactsDialog, setOpenRCContactsDialog] = useState(false)

	///
	return (
		<div>
			<div className="l-navbar" id="nav-bar">
				<div className="nav_header">
					<a className="nav_logo nav_grid_item1 ">
						<img src={widelogo} height="85vh" />
						<span className="nav_logo-name"></span>
					</a>

					{/* <div className="nav_link" style={{ display: "none" }}>
						<FormControl>
							<Select
								labelId="lang_dropdown"
								id="lang_dropdown"
								value={i18n.resolvedLanguage}
								label="Kieli"
								onChange={(e: React.ChangeEvent<HTMLSelectElement>) => i18n.changeLanguage(e.target.value)}
								renderSuffix={() => <Flag language={i18n.resolvedLanguage} />}
							>
								{Object.keys(lngs).map((lng: string, i) => (
									<MenuItem key={i} value={lng}>
										{lngs[lng].nativeName}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</div> */}
				</div>
				<nav className="nav">
					<div className="nav_list">
						<MenuLink name="home" path="/" />
						{(permission === "edit" || permission === "municipality") && <MenuLink name="finances" path="/finances" />}
						{/* <MenuLink  name="partnersInfo" path="/partners_info" /> */}
						{/* <MenuLink  name="chat" path="/chat" /> */}
						{/* <MenuLink  name="map" path="/map" /> */}
						{permission !== "municipality" && <MenuLink name="meetings" path="/calendar" />}
						<MenuLink name="document" path="/documents" />
						{/* <MenuLink  name="roadcooperative" path="/roadcooperative" /> */}
						<MenuLink name="settings" path="/options" />
						{authToken.getDecodedToken()?.email.includes("@tievahti.fi") && permission === "edit" && (
							<MenuLink name="unitizationTool" path="/unitization-tool" />
						)}

						<div className="nav_link_contacts">
							<div className="contacts" onClick={() => setOpenRCContactsDialog(!openRCContactsDialog)}>
								<PermContactCalendarOutlinedIcon sx={{ fontSize: "45px", marginBottom: "-14px" }} />
								<div className="contact_text">Yhteystiedot</div>
							</div>
						</div>
					</div>

					<div className="nav_bottom_links">
						{permission !== "municipality" && (
							<>
								<div className="nav_link_chat_and_notes">
									<div className="help_and_support" onClick={() => setOpenChatDialog(!openChatDialog)}>
										<HelpOutlineIcon sx={{ fontSize: "45px", marginBottom: "-14px" }} />
										<div className="helpAndSupportText">Tuki</div>
									</div>
								</div>
								<div className="nav_link_chat_and_notes">
									<div className="help_and_support" onClick={() => setOpenNotesDialog(!openNotesDialog)}>
										<FactCheckIcon sx={{ fontSize: "45px", marginBottom: "-14px" }} />
										<div className="helpAndSupportText">Muistio</div>
									</div>
								</div>
							</>
						)}
						<MenuLink name="profile" path="/profile" />
					</div>
				</nav>
			</div>

			{openChatDialog ? <ChatBot setOpenChatDialog={setOpenChatDialog} openChatDialog={openChatDialog} /> : null}
			{openNotesDialog ? <NotesPage openNotesDialog={openNotesDialog} setOpenNotesDialog={setOpenNotesDialog} /> : null}
			{openRCContactsDialog ? (
				<RoadCooperativeContacts openRCContactsDialog={openRCContactsDialog} setOpenRCContactsDialog={setOpenRCContactsDialog} />
			) : null}
		</div>
	)
}

export default SideNavBar
