import React, { useRef, useState } from "react"
import { TextField, IconButton, Tooltip, InputAdornment } from "@mui/material"
import ContentCopyIcon from "@mui/icons-material/ContentCopy"
import { toast } from "react-toastify"

interface CopyableTextFieldProps {
	label?: string
	value?: string
	onChange: (value: string) => void
	maxLength?: number
	disabled?: boolean
	fullWidth?: boolean
	sx?: any
}

const CopyableTextField: React.FC<CopyableTextFieldProps> = ({ label, value, onChange, maxLength = 100, disabled = false, fullWidth = false, sx }) => {
	const inputRef = useRef<HTMLInputElement>(null)
	const [isCopying, setIsCopying] = useState(false)

	const copyToClipboard = () => {
		if (inputRef.current) {
			navigator.clipboard.writeText(inputRef.current.value).catch(() => {
				toast.error("Kopiointi epäonnistui.")
			})
		}
		setIsCopying(true)
		setTimeout(() => setIsCopying(false), 150)
	}

	return (
		<TextField
			inputProps={{
				maxLength: maxLength,
			}}
			disabled={disabled}
			fullWidth={fullWidth}
			onChange={(e: { target: { value: string } }) => onChange(e.target.value)}
			label={label}
			value={value}
			inputRef={inputRef}
			sx={{
				"& .MuiInputBase-root": {
					"&:hover .MuiInputAdornment-root": {
						opacity: 1,
					},
				},
				...sx,
			}}
			InputProps={{
				endAdornment: value ? (
					<InputAdornment
						position="end"
						sx={{
							opacity: 0,
							transition: "opacity 0.15s",
							"&:hover": {
								opacity: 1,
							},
						}}
					>
						<Tooltip title="Kopioi teksti">
							<IconButton
								onClick={copyToClipboard}
								disabled={disabled}
								sx={{
									color: isCopying ? "#006904" : "#7FC646",
									transition: "color 0.15s",
								}}
							>
								<ContentCopyIcon />
							</IconButton>
						</Tooltip>
					</InputAdornment>
				) : null,
			}}
		/>
	)
}

export default CopyableTextField
