import React, { useEffect, useState } from "react"
import { Select, MenuItem, TextField, Grid } from "@mui/material"
import { Autocomplete } from "@mui/material"
import { Paper } from "@mui/material"

interface Municipality {
	name: string
	code: string
}

const allMunicipalities = [
	{ name: "Akaa", code: "178" },
	{ name: "Alajärvi", code: "005" },
	{ name: "Alavieska", code: "009" },
	{ name: "Alavus", code: "010" },
	{ name: "Askola ", code: "018" },
	{ name: "Aura", code: "019" },
	{ name: "Brändö", code: "035" },
	{ name: "Enonkoski", code: "046" },
	{ name: "Enontekiö", code: "047" },
	{ name: "Espoo", code: "049" },
	{ name: "Eura", code: "050" },
	{ name: "Eurajoki", code: "051" },
	{ name: "Evijärvi", code: "052" },
	{ name: "Finström", code: "060" },
	{ name: "Forssa", code: "061" },
	{ name: "Föglö", code: "062" },
	{ name: "Geta", code: "065" },
	{ name: "Haapajärvi", code: "069" },
	{ name: "Haapavesi", code: "071" },
	{ name: "Hailuoto", code: "072" },
	{ name: "Halsua", code: "074" },
	{ name: "Hamina", code: "075" },
	{ name: "Hammarland", code: "076" },
	{ name: "Hankasalmi", code: "077" },
	{ name: "Hanko", code: "078" },
	{ name: "Harjavalta", code: "079" },
	{ name: "Hartola", code: "081" },
	{ name: "Hattula", code: "082" },
	{ name: "Hauho", code: "083" },
	{ name: "Haukivuori", code: "085" },
	{ name: "Hausjärvi", code: "086" },
	{ name: "Heinola", code: "111" },
	{ name: "Helsinki", code: "091" },
	{ name: "Hirvensalmi", code: "097" },
	{ name: "Hollola", code: "098" },
	{ name: "Honkajoki", code: "099" },
	{ name: "Houtskari", code: "101" },
	{ name: "Huittinen", code: "102" },
	{ name: "Humppila", code: "103" },
	{ name: "Hyrynsalmi", code: "105" },
	{ name: "Hyvinkää", code: "106" },
	{ name: "Hämeenkyrö", code: "108" },
	{ name: "Hämeenlinna", code: "109" },
	{ name: "Ii", code: "139" },
	{ name: "Iisalmi", code: "140" },
	{ name: "Iitti", code: "142" },
	{ name: "Ikaalinen", code: "143" },
	{ name: "Ilmajoki", code: "145" },
	{ name: "Ilomantsi", code: "146" },
	{ name: "Imatra", code: "153" },
	{ name: "Inari", code: "148" },
	{ name: "Iniö", code: "150" },
	{ name: "Inkoo", code: "149" },
	{ name: "Isojoki", code: "151" },
	{ name: "Isokyrö", code: "152" },
	{ name: "Janakkala", code: "165" },
	{ name: "Joensuu", code: "167" },
	{ name: "Jokioinen", code: "169" },
	{ name: "Jomala", code: "170" },
	{ name: "Joroinen", code: "171" },
	{ name: "Joutsa", code: "172" },
	{ name: "Joutseno", code: "173" },
	{ name: "Juuka", code: "176" },
	{ name: "Juupajoki", code: "177" },
	{ name: "Juva", code: "178" },
	{ name: "Jyväskylä", code: "179" },
	{ name: "Jämijärvi", code: "181" },
	{ name: "Jämsä", code: "182" },
	{ name: "Jäppilä", code: "184" },
	{ name: "Järvenpää", code: "186" },
	{ name: "Kaarina", code: "202" },
	{ name: "Kaavi", code: "204" },
	{ name: "Kajaani", code: "205" },
	{ name: "Kalajoki", code: "208" },
	{ name: "Kalvola", code: "210" },
	{ name: "Kangasala", code: "211" },
	{ name: "Kangasniemi", code: "213" },
	{ name: "Kankaanpää", code: "214" },
	{ name: "Kannonkoski", code: "216" },
	{ name: "Kannus", code: "217" },
	{ name: "Karijoki", code: "218" },
	{ name: "Karinainen", code: "219" },
	{ name: "Karkkila", code: "224" },
	{ name: "Karstula", code: "226" },
	{ name: "Karttula", code: "227" },
	{ name: "Karvia", code: "230" },
	{ name: "Kauhajoki", code: "232" },
	{ name: "Kauhava", code: "233" },
	{ name: "Kauniainen", code: "235" },
	{ name: "Kaustinen", code: "236" },
	{ name: "Keitele", code: "239" },
	{ name: "Kemi", code: "240" },
	{ name: "Kemijärvi", code: "320" },
	{ name: "Keminmaa", code: "241" },
	{ name: "Kemiö", code: "243" },
	{ name: "Kempele", code: "244" },
	{ name: "Kerava", code: "245" },
	{ name: "Kerimäki", code: "246" },
	{ name: "Kesälahti", code: "248" },
	{ name: "Keuruu", code: "249" },
	{ name: "Kihniö", code: "250" },
	{ name: "Kiihtelysvaara", code: "251" },
	{ name: "Kinnula", code: "256" },
	{ name: "Kirkkonummi", code: "257" },
	{ name: "Kisko", code: "259" },
	{ name: "Kitee", code: "260" },
	{ name: "Kittilä", code: "261" },
	{ name: "Kiukainen", code: "262" },
	{ name: "Kiuruvesi", code: "263" },
	{ name: "Kivijärvi", code: "265" },
	{ name: "Kokemäki", code: "271" },
	{ name: "Kokkola", code: "272" },
	{ name: "Kolari", code: "273" },
	{ name: "Konnevesi", code: "275" },
	{ name: "Kontiolahti", code: "276" },
	{ name: "Korsnäs", code: "280" },
	{ name: "Kortesjärvi", code: "281" },
	{ name: "Koski Tl", code: "284" },
	{ name: "Kotka", code: "285" },
	{ name: "Kouvola", code: "286" },
	{ name: "Kristiinankaupunki", code: "287" },
	{ name: "Kruunupyy", code: "288" },
	{ name: "Kuhmo", code: "290" },
	{ name: "Kuhmoinen", code: "291" },
	{ name: "Kuivaniemi", code: "292" },
	{ name: "Kumlinge", code: "295" },
	{ name: "Kuopio", code: "297" },
	{ name: "Kuortane", code: "300" },
	{ name: "Kurikka", code: "301" },
	{ name: "Kuru", code: "303" },
	{ name: "Kustavi", code: "304" },
	{ name: "Kuusamo", code: "305" },
	{ name: "Kälviä", code: "315" },
	{ name: "Kärkölä", code: "316" },
	{ name: "Kärsämäki", code: "317" },
	{ name: "Kökar", code: "318" },
	{ name: "Köyliö", code: "319" },
	{ name: "Lahti", code: "398" },
	{ name: "Laihia", code: "399" },
	{ name: "Laitila", code: "400" },
	{ name: "Lammi", code: "401" },
	{ name: "Lapinjärvi", code: "407" },
	{ name: "Lapinlahti", code: "402" },
	{ name: "Lappajärvi", code: "403" },
	{ name: "Lappeenranta", code: "405" },
	{ name: "Lappi", code: "406" },
	{ name: "Lapua", code: "408" },
	{ name: "Laukaa", code: "410" },
	{ name: "Lavia", code: "413" },
	{ name: "Leivonmäki", code: "415" },
	{ name: "Lemi", code: "416" },
	{ name: "Lemland", code: "417" },
	{ name: "Lempäälä", code: "418" },
	{ name: "Lemu", code: "419" },
	{ name: "Leppävirta", code: "420" },
	{ name: "Lestijärvi", code: "421" },
	{ name: "Lieksa", code: "422" },
	{ name: "Lieto", code: "423" },
	{ name: "Liminka", code: "425" },
	{ name: "Liperi", code: "426" },
	{ name: "Lohja", code: "444" },
	{ name: "Lohtaja", code: "429" },
	{ name: "Loimaa", code: "430" },
	{ name: "Loppi", code: "433" },
	{ name: "Loviisa", code: "434" },
	{ name: "Luhanka", code: "435" },
	{ name: "Lumijoki", code: "436" },
	{ name: "Lumparland", code: "438" },
	{ name: "Luopioinen", code: "439" },
	{ name: "Luoto", code: "440" },
	{ name: "Luumäki", code: "441" },
	{ name: "Luvia", code: "442" },
	{ name: "Längelmäki", code: "443" },
	{ name: "Maalahti", code: "475" },
	{ name: "Maaninka", code: "476" },
	{ name: "Maarianhamina", code: "478" },
	{ name: "Marttila", code: "480" },
	{ name: "Masku", code: "481" },
	{ name: "Merijärvi", code: "483" },
	{ name: "Merikarvia", code: "484" },
	{ name: "Miehikkälä", code: "489" },
	{ name: "Mikkeli", code: "491" },
	{ name: "Muhos", code: "494" },
	{ name: "Multia", code: "495" },
	{ name: "Muonio", code: "498" },
	{ name: "Mustasaari", code: "499" },
	{ name: "Mynämäki", code: "503" },
	{ name: "Myrskylä", code: "504" },
	{ name: "Mäntsälä", code: "505" },
	{ name: "Mänttä", code: "506" },
	{ name: "Mäntyharju", code: "507" },
	{ name: "Naantali", code: "529" },
	{ name: "Nakkila", code: "531" },
	{ name: "Nauvo", code: "533" },
	{ name: "Nivala", code: "535" },
	{ name: "Nokia", code: "536" },
	{ name: "Nousiainen", code: "538" },
	{ name: "Nurmes", code: "541" },
	{ name: "Nurmijärvi", code: "543" },
	{ name: "Närpiö", code: "545" },
	{ name: "Orimattila", code: "560" },
	{ name: "Oripää", code: "561" },
	{ name: "Orivesi", code: "562" },
	{ name: "Oulainen", code: "563" },
	{ name: "Oulu", code: "564" },
	{ name: "Oulunsalo", code: "567" },
	{ name: "Outokumpu", code: "309" },
	{ name: "Padasjoki", code: "576" },
	{ name: "Paimio", code: "577" },
	{ name: "Paltamo", code: "578" },
	{ name: "Parainen", code: "445" },
	{ name: "Parikkala", code: "580" },
	{ name: "Parkano", code: "581" },
	{ name: "Pattijoki", code: "582" },
	{ name: "Pedersöre", code: "599" },
	{ name: "Pelkosenniemi", code: "583" },
	{ name: "Pello", code: "854" },
	{ name: "Perho", code: "584" },
	{ name: "Perniö", code: "586" },
	{ name: "Pertunmaa", code: "588" },
	{ name: "Peräseinäjoki", code: "589" },
	{ name: "Petäjävesi", code: "592" },
	{ name: "Pieksämäki", code: "593" },
	{ name: "Pielavesi", code: "595" },
	{ name: "Pietarsaari", code: "598" },
	{ name: "Pihtipudas", code: "601" },
	{ name: "Pirkkala", code: "604" },
	{ name: "Polvijärvi", code: "607" },
	{ name: "Pomarkku", code: "608" },
	{ name: "Pori", code: "609" },
	{ name: "Posio", code: "614" },
	{ name: "Pudasjärvi", code: "615" },
	{ name: "Pukkila", code: "616" },
	{ name: "Punkaharju", code: "618" },
	{ name: "Punkalaidun", code: "619" },
	{ name: "Puolanka", code: "620" },
	{ name: "Puumala", code: "623" },
	{ name: "Pyhtää", code: "624" },
	{ name: "Pyhäjoki", code: "625" },
	{ name: "Pyhäjärvi", code: "626" },
	{ name: "Pyhäntä", code: "630" },
	{ name: "Pyhäranta", code: "631" },
	{ name: "Pyhäselkä", code: "632" },
	{ name: "Pylkönmäki", code: "633" },
	{ name: "Pöytyä", code: "636" },
	{ name: "Raahe", code: "678" },
	{ name: "Raasepori", code: "710" },
	{ name: "Raisio", code: "680" },
	{ name: "Rantasalmi", code: "681" },
	{ name: "Rantsila", code: "682" },
	{ name: "Ranua", code: "683" },
	{ name: "Rauma", code: "684" },
	{ name: "Rautalampi", code: "686" },
	{ name: "Rautavaara", code: "687" },
	{ name: "Rautjärvi", code: "689" },
	{ name: "Reisjärvi", code: "691" },
	{ name: "Riihimäki", code: "694" },
	{ name: "Ristiina", code: "696" },
	{ name: "Ristijärvi", code: "697" },
	{ name: "Rovaniemi", code: "698" },
	{ name: "Ruokolahti", code: "700" },
	{ name: "Ruovesi", code: "702" },
	{ name: "Rusko", code: "704" },
	{ name: "Ruukki", code: "708" },
	{ name: "Rymättylä", code: "705" },
	{ name: "Rääkkylä", code: "707" },
	{ name: "Saari", code: "728" },
	{ name: "Saarijärvi", code: "729" },
	{ name: "Sahalahti", code: "730" },
	{ name: "Salla", code: "732" },
	{ name: "Salo", code: "734" },
	{ name: "Sammatti", code: "737" },
	{ name: "Sastamala", code: "790" },
	{ name: "Sauvo", code: "738" },
	{ name: "Savitaipale", code: "739" },
	{ name: "Savonlinna", code: "740" },
	{ name: "Savonranta", code: "741" },
	{ name: "Savukoski", code: "742" },
	{ name: "Seinäjoki", code: "743" },
	{ name: "Sievi", code: "746" },
	{ name: "Siikainen", code: "747" },
	{ name: "Siikajoki", code: "748" },
	{ name: "Siikalatva", code: "791" },
	{ name: "Siilinjärvi", code: "749" },
	{ name: "Simo", code: "751" },
	{ name: "Sipoo", code: "753" },
	{ name: "Siuntio", code: "755" },
	{ name: "Sodankylä", code: "758" },
	{ name: "Soini", code: "759" },
	{ name: "Somero", code: "761" },
	{ name: "Sonkajärvi", code: "762" },
	{ name: "Sotkamo", code: "765" },
	{ name: "Sottunga", code: "766" },
	{ name: "Sulkava", code: "768" },
	{ name: "Sumiainen", code: "770" },
	{ name: "Sund", code: "771" },
	{ name: "Suodenniemi", code: "772" },
	{ name: "Suomussalmi", code: "777" },
	{ name: "Suonenjoki", code: "778" },
	{ name: "Sysmä", code: "781" },
	{ name: "Säkylä", code: "783" },
	{ name: "Taivalkoski", code: "832" },
	{ name: "Taivassalo", code: "833" },
	{ name: "Tampere", code: "837" },
	{ name: "Tammela", code: "834" },
	{ name: "Taipalsaari", code: "831" },
	{ name: "Tarvasjoki", code: "838" },
	{ name: "Tervo", code: "844" },
	{ name: "Tervola", code: "845" },
	{ name: "Teuva", code: "846" },
	{ name: "Tohmajärvi", code: "848" },
	{ name: "Toholampi", code: "849" },
	{ name: "Toivakka", code: "850" },
	{ name: "Tornio", code: "851" },
	{ name: "Turku", code: "853" },
	{ name: "Tuupovaara", code: "856" },
	{ name: "Tuusniemi", code: "857" },
	{ name: "Tuusula", code: "858" },
	{ name: "Tyrnävä", code: "859" },
	{ name: "Töysä", code: "863" },
	{ name: "Ulvila", code: "886" },
	{ name: "Urjala", code: "887" },
	{ name: "Utajärvi", code: "889" },
	{ name: "Utsjoki", code: "890" },
	{ name: "Uukuniemi", code: "891" },
	{ name: "Uurainen", code: "892" },
	{ name: "Uusikaarlepyy", code: "893" },
	{ name: "Uusikaupunki", code: "895" },
	{ name: "Vaala", code: "785" },
	{ name: "Vaasa", code: "905" },
	{ name: "Vampula", code: "913" },
	{ name: "Varkaus", code: "915" },
	{ name: "Vehmaa", code: "918" },
	{ name: "Vesanto", code: "921" },
	{ name: "Vesilahti", code: "922" },
	{ name: "Veteli", code: "924" },
	{ name: "Vieremä", code: "925" },
	{ name: "Vihanti", code: "926" },
	{ name: "Vihti", code: "927" },
	{ name: "Viitasaari", code: "931" },
	{ name: "Viljakkala", code: "932" },
	{ name: "Vilppula", code: "933" },
	{ name: "Vimpeli", code: "934" },
	{ name: "Virolahti", code: "935" },
	{ name: "Virrat", code: "936" },
	{ name: "Vähäkyrö", code: "942" },
	{ name: "Värtsilä", code: "943" },
	{ name: "Vårdö", code: "941" },
	{ name: "Vöyri", code: "944" },
	{ name: "Yli-Ii", code: "972" },
	{ name: "Ylihärmä", code: "971" },
	{ name: "Ylistaro", code: "975" },
	{ name: "Ylitornio", code: "976" },
	{ name: "Ylivieska", code: "977" },
	{ name: "Yläne", code: "979" },
	{ name: "Ylöjärvi", code: "980" },
	{ name: "Ypäjä", code: "981" },
	{ name: "Ähtäri", code: "989" },
	{ name: "Äänekoski", code: "992" },
]

const MunicipalitySelect: React.FC<{
	onMunicipalityChange: (value: string) => void
	initialValue: string | null
	variant?: string
}> = ({ onMunicipalityChange, initialValue, variant }) => {
	const [selectedMunicipality, setSelectedMunicipality] = useState<Municipality | null>(null)

	useEffect(() => {
		const newInitialMunicipality = allMunicipalities.find((m) => `${m.name} - ${m.code}` === initialValue) || null
		setSelectedMunicipality(newInitialMunicipality)
	}, [initialValue])

	const handleMunicipalityChange = (event: any, value: Municipality | null) => {
		const isValidMunicipality = allMunicipalities.some((m) => m === value)
		if (isValidMunicipality && value) {
			setSelectedMunicipality(value)
			onMunicipalityChange(`${value.name} - ${value.code}`)
		} else {
			setSelectedMunicipality(null)
			onMunicipalityChange("")
		}
	}

	return (
		<Grid item>
			<Autocomplete
				options={allMunicipalities}
				disableClearable
				freeSolo={false}
				autoSelect
				getOptionLabel={(option: any) => `${option.name} - ${option.code}`}
				filterOptions={(options: any, state: any) =>
					options.filter((option: any) => `${option.name} - ${option.code}`.toLowerCase().includes(state.inputValue.toLowerCase()))
				}
				value={selectedMunicipality}
				onChange={handleMunicipalityChange}
				fullWidth
				ListboxProps={{ style: { maxHeight: "200px" }, autoComplete: "list" }}
				renderInput={(params: any) => <TextField {...params} label="Kunta *" variant={variant} />}
			/>
		</Grid>
	)
}

export default MunicipalitySelect
