import { DialogContent } from "@mui/material"
import { Button } from "@mui/material"
import { Typography } from "@mui/material"
import { Dialog } from "@mui/material"
import React, { SetStateAction } from "react"
import CustomDialogActions from "./CustomDialogActions"
import CustomDialogTitle from "./CustomDialogTitle"
import DialogCloseButton from "./DialogCloseButton"

type NoticationDialogProps = {
	dialogTitle: string
	executefunction?: (functionParams?: any) => void
	functionParams?: any
	setOpenState: React.Dispatch<SetStateAction<boolean>>
	openState: boolean
	executeButtonText?: string
	children?: React.ReactNode
}

const NotificationDialog = ({
	dialogTitle,
	children,
	executefunction = () => null,
	functionParams,
	setOpenState,
	openState,
	executeButtonText,
}: NoticationDialogProps) => {
	const handleFunction = () => {
		try {
			if (functionParams) {
				executefunction(functionParams)
			} else {
				executefunction()
			}
		} catch (error) {
			console.error("Error in NotificationDialog executeFunction")
		}
		setOpenState(false)
	}

	return (
		<Dialog open={openState}>
			<DialogCloseButton closeFunction={() => setOpenState(false)} />
			<CustomDialogTitle>{dialogTitle}</CustomDialogTitle>
			<DialogContent>
				<Typography sx={{ fontWeight: "500" }}>{children}</Typography>
			</DialogContent>
			<CustomDialogActions>
				<Button
					variant="outlined"
					sx={{
						color: "#505050",
						borderColor: "#3d3c3c",
						"&:hover": {
							borderColor: "#000000",
							color: "#000000",
						},
					}}
					onClick={() => setOpenState((prevValue) => !prevValue)}
				>
					{" "}
					Peruuta{" "}
				</Button>
				<Button
					variant="contained"
					sx={{
						backgroundColor: "#eb1118",
						"&:hover": {
							backgroundColor: "#d60e14",
						},
					}}
					onClick={handleFunction}
				>
					{" "}
					{executeButtonText ? executeButtonText : <>Jatka</>}{" "}
				</Button>
			</CustomDialogActions>
		</Dialog>
	)
}

export default NotificationDialog
