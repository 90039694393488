import React from "react"

import FileCopyIcon from "@mui/icons-material/FileCopy"

interface CopyableTextProps {
	text: any
}

const copyableTextBaseStyle: React.CSSProperties = {
	position: "relative",
	cursor: "pointer",
	transition: "color 0.15s",
	color: "#505050",
	display: "flex",
	alignItems: "center",
}

const copyableTextHoverStyle: React.CSSProperties = {
	color: "#7fc646",
}

const copyableTextCopiedStyle: React.CSSProperties = {
	color: "#006904", // Change color to indicate successful copy
}

const iconStyle: React.CSSProperties = {
	height: "15px",
	marginLeft: "8px",
	opacity: 0,
	visibility: "hidden",
	transition: "opacity 0.15s, visibility 0.15s",
}

const iconHoverStyle: React.CSSProperties = {
	opacity: 1,
	visibility: "visible",
}

const CopyableText: React.FC<CopyableTextProps> = ({ text }) => {
	const [isHovered, setIsHovered] = React.useState(false)
	const [isCopied, setIsCopied] = React.useState(false)

	const handleClick = () => {
		if (text) {
			navigator.clipboard.writeText(text)
			setIsCopied(true)

			setTimeout(() => setIsCopied(false), 100)
		}
	}

	if (!text) {
		return null
	}

	return (
		<div
			style={{
				...copyableTextBaseStyle,
				...(isHovered ? copyableTextHoverStyle : {}),
				...(isCopied ? copyableTextCopiedStyle : {}),
			}}
			onClick={handleClick}
			onMouseEnter={() => setIsHovered(true)}
			onMouseLeave={() => setIsHovered(false)}
		>
			{text || "No text available"}
			<FileCopyIcon
				sx={{ height: "15px", marginLeft: "8px" }} // Use marginLeft for spacing
				style={{
					...iconStyle,
					...(isHovered ? iconHoverStyle : {}),
				}}
			/>
		</div>
	)
}

export default CopyableText
