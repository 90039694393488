import React, { MutableRefObject, useEffect, useRef, useState } from "react"
import maplibregl, { LngLatBoundsLike, LngLatLike } from "maplibre-gl"
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css"

import "../../Map/Map.css"
import "react-toastify/dist/ReactToastify.css"
import "./UnitsImportMap.css"

import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client"

import { FeatureCollection, Position } from "geojson"
import { MapLayerType } from "../../../utils/providers/mapProvider/mapProvider"
import loadKipaLayers from "../../Map/mapUtils/loadKipaLayers"
import config from "../../../utils/config"
import userProfileStorage from "../../../utils/providers/userProfileProvider/userProfileProvider"
import { Fab, TableContainer, TableHead, TableCell } from "@mui/material"
import { Paper, Table, TableRow, TableBody } from "@mui/material"
import { Typography } from "@mui/material"

import MenuIcon from "@mui/icons-material/Menu"
import InfoIcon from "@mui/icons-material/Info"
import SearchIcon from "@mui/icons-material/Search"

import { toast } from "react-toastify"
import { Tooltip } from "@mui/material"
import { Stack } from "@mui/material"
import CustomNotification from "../../reusables/CustomNotification"
import { TextField } from "@mui/material"
import { Autocomplete } from "@mui/material"
import { Box } from "@mui/material"
import { Divider } from "@mui/material"
import axios from "axios"
import * as turf from "@turf/turf"
import { Collapse } from "@mui/material"
import { Alert } from "@mui/material"

const GET_COST_CENTERS_ALL_GEO_JSON_DATA = gql`
	query GetCostCentersAllData {
		roadCooperativeWithJWT {
			costCentersByRoadCooperativeId {
				nodes {
					name
					idColor
					id
					roadLinksByCostCenterId {
						nodes {
							linkId
							linkLength
							coordinates {
								lat
								lng
							}
							id
						}
					}
					propertiesByCostCenterId {
						nodes {
							mmlPropertyId
							plotId
							propertyName
							id
							propertyTag
						}
					}
				}
			}
		}
	}
`

const GET_MEMBER_PROPERTIES = gql`
	query GetMemberProperties($id: Int = 10) {
		roadCooperativeMemberById(id: $id) {
			establishmentsByRoadCooperativeMemberId {
				nodes {
					roadUnits
					id
					propertyByPropertyId {
						plotId
						mmlPropertyId
						propertyName
					}
					costCenterByCostCenterId {
						name
					}
				}
			}
		}
	}
`

const CREATE_ESTABLISHMENT = gql`
	mutation CreateEstablishment(
		$costCenterId: IdType = ""
		$propertyId: IdType = ""
		$roadCooperativeMemberId: IdType = ""
		$roadUsageUnitId: IdType = ""
		$amount: Float = 1
	) {
		createEstablishment(
			input: {
				establishment: {
					additionalInformation: ""
					amount: $amount
					costCenterId: $costCenterId
					discretionalyCorrectionMultiplier: 1
					distanceCorrectionMultiplier: 1
					lateralDirection: 1
					lateralDirectionCorrectionMultiplier: 1
					operatingDistance: 1
					propertyId: $propertyId
					roadCooperativeMemberId: $roadCooperativeMemberId
					roadUsageUnitId: $roadUsageUnitId
					weightCorrectionMultiplier: 1
				}
			}
		) {
			establishment {
				id
			}
		}
	}
`

const DELETE_ESTABLISHMENT = gql`
	mutation DeleteEstablishment($id: Int = 10) {
		deleteEstablishmentById(input: { id: $id }) {
			propertyByPropertyId {
				plotId
			}
		}
	}
`

const CREATE_ROAD_USAGEUNIT = gql`
	mutation CreateRoadUsageUnit($roadCooperativeId: UUID!, $roadUsageUnit: String = "", $roadUsageUnitType: String = "") {
		createRoadUsageUnit(
			input: { roadUsageUnit: { roadCooperativeId: $roadCooperativeId, roadUsageUnit: $roadUsageUnit, roadUsageUnitType: $roadUsageUnitType, weight: 1 } }
		) {
			roadUsageUnit {
				id
			}
		}
	}
`

const GET_ROAD_USAGE_UNITS = gql`
	query GetAllRoadUsageUnits {
		roadCooperativeWithJWT {
			roadUsageUnitsByRoadCooperativeId {
				nodes {
					id
					roadUsageUnit
					roadUsageUnitType
				}
			}
		}
	}
`

const GET_ALL_MEMBERS = gql`
	query GetAllMembers {
		roadCooperativeWithJWT {
			roadCooperativeMembersByRoadCooperativeId {
				nodes {
					name
					id
					establishmentsByRoadCooperativeMemberId {
						nodes {
							roadUnits
							id
							propertyByPropertyId {
								mmlPropertyId
								propertyName
								plotId
							}
						}
					}
				}
			}
		}
	}
`

const GET_TOTAL_UNITS = gql`
	query GetTotalUnits {
		roadCooperativeWithJWT {
			totalUnits
		}
	}
`

const mapMaxBounds: LngLatBoundsLike | undefined = [
	[11.5, 56.5], // Vasemman alakulman koordinaatit
	[38.5, 71], // Oikean yläkulman koordinaatit
]

type UnitsImportMapProps = {
	activeMember: RoadCooperativeMemberType
}

const UnitsImportMap = () => {
	const [initialDataLoaded, setInitialDataLoaded] = useState(false)
	const [allPropertiesList, setAllPropertiesList] = useState<any>([])
	const [_defaultUsageUnitId, _setDefaultUsageUnitId] = useState()
	const [allMembers, setAllMembers] = useState<RoadCooperativeMemberType[]>([])
	const [activeMember, setActiveMember] = useState<any>()
	const [totalUnits, setTotalUnits] = useState(0)
	const [searchedProperties, setSearchedProperties] = useState<Property[]>([])
	const [selectedSearchProperty, setSelectedSearchProperty] = useState<Property>()
	const [openInfoBox, setOpenInfoBox] = useState(true)

	const [_currentMember, _setCurrentMember] = useState()
	const [_selectedPropertiesList, _setSelectedPropertiesList] = useState<any>([])

	const selectedPropertiesList = useRef<any>(_selectedPropertiesList)
	const setSelectedPropertiesList = (x: any) => {
		selectedPropertiesList.current = x
		_setSelectedPropertiesList(x)
	}

	const currentMember = useRef<any>(_currentMember)
	const setCurrentMember = (x: any) => {
		currentMember.current = x
		_setCurrentMember(x)
	}

	const defaultUsageUnitId = useRef(_defaultUsageUnitId)
	const setDefaultUsageUnitId = (x: any) => {
		defaultUsageUnitId.current = x
		_setDefaultUsageUnitId(x)
	}

	const [getMemberEstablishments] = useLazyQuery(GET_MEMBER_PROPERTIES, {
		fetchPolicy: "cache-and-network",
		onCompleted(data) {
			console.log(data, " ---- DATA IN ON COMPLETE ---- ")

			setSelectedPropertiesList(data.roadCooperativeMemberById.establishmentsByRoadCooperativeMemberId.nodes)
		},
	})

	const { data } = useQuery(GET_ROAD_USAGE_UNITS)
	const [createEstablishment] = useMutation(CREATE_ESTABLISHMENT)
	const [deleteEstablishment] = useMutation(DELETE_ESTABLISHMENT)
	const [createRoadUsageUnit] = useMutation(CREATE_ROAD_USAGEUNIT)

	const [getAllGeoJson] = useLazyQuery(GET_COST_CENTERS_ALL_GEO_JSON_DATA, {
		onCompleted(data) {
			// setInitialDataLoaded(true)
		},
	})
	const { data: allMembersData, refetch } = useQuery(GET_ALL_MEMBERS)

	const mapContainerRef: any = useRef(null)
	const map: MapInstance = useRef(null)

	const { data: totalUnitsData, refetch: refetchTotalUnits } = useQuery(GET_TOTAL_UNITS)

	useEffect(() => {
		if (!totalUnitsData) return
		if (!totalUnitsData?.roadCooperativeWithJWT?.totalUnits) {
			setTotalUnits(0)
			return
		}
		setTotalUnits(totalUnitsData.roadCooperativeWithJWT.totalUnits)
	}, [totalUnitsData])

	useEffect(() => {
		if (!allMembersData) return
		const members = [...allMembersData.roadCooperativeWithJWT.roadCooperativeMembersByRoadCooperativeId.nodes]
		const sortedMembers = members.sort((a: any, b: any) => {
			const nameA = a.name.toUpperCase()
			const nameB = b.name.toUpperCase()
			if (nameA < nameB) return -1
			if (nameA > nameB) return 1
			return 0
		})
		setAllMembers(sortedMembers)
	}, [allMembersData])

	useEffect(() => {
		if (!activeMember?.id) return
		if (currentMember.current) {
			if (activeMember.id != currentMember.current.id) {
				selectedPropertiesList.current.forEach((property: any) => {
					map.current.setFeatureState(
						{ source: "kipa", sourceLayer: "PalstanSijaintitiedot", id: property.propertyByPropertyId.plotId },
						{ selected: false }
					)
				})
			}
		}
		setCurrentMember(activeMember)
		const getMemberProperties = async () => {
			const { data: memberPropertyData } = await getMemberEstablishments({ variables: { id: activeMember.id } })
			const memberEstablishments = memberPropertyData.roadCooperativeMemberById.establishmentsByRoadCooperativeMemberId.nodes
			setSelectedPropertiesList(memberEstablishments)
			for (let i = 0, len = memberEstablishments.length; len > i; i++) {
				map.current.setFeatureState(
					{ source: "kipa", sourceLayer: "PalstanSijaintitiedot", id: memberEstablishments[i].propertyByPropertyId.plotId },
					{ selected: true }
				)
			}
		}
		getMemberProperties()
	}, [activeMember])

	useEffect(() => {
		// Luodaan liikennelaji jonka nimi on oletus ja lyhenne o ja painoluku 1, mikäli sellaista ei löydy tiekunnalta
		// ja asetetaan tämä liikennelaki defaultUsageUnit stateen ja sitä kaytetään kun luodaan establishmenttejä el klikataan kiinteistöjä kartalta
		if (!data) return
		const usageUnits = data.roadCooperativeWithJWT.roadUsageUnitsByRoadCooperativeId?.nodes
		const oletusUsageUnit = usageUnits.find((usageUnit: any) => usageUnit.roadUsageUnitType == "oletus")
		if (oletusUsageUnit) {
			setDefaultUsageUnitId(oletusUsageUnit.id)
		} else {
			const RC = userProfileStorage({ type: "getActiveRoadCooperative" })
			createRoadUsageUnit({
				variables: { roadCooperativeId: RC.id, roadUsageUnit: "o", roadUsageUnitType: "oletus" },
				onCompleted(data, clientOptions) {
					const usageUnitId = data.createRoadUsageUnit.roadUsageUnit.id
					setDefaultUsageUnitId(usageUnitId)
				},
			})
		}
	}, [data])

	const handlePopoverClick = () => {
		const el = document.getElementById("member-property-popover")
		if (el) {
			if (el.style.visibility == "hidden") {
				el.style.visibility = "visible"
				el.style.opacity = "1"
			} else {
				el.style.visibility = "hidden"
				el.style.opacity = "0"
			}
		}
	}

	const handleSearchPopoverClick = () => {
		const el = document.getElementById("property-search-popover")
		if (el) {
			if (el.style.visibility == "hidden") {
				el.style.visibility = "visible"
				el.style.opacity = "1"
			} else {
				el.style.visibility = "hidden"
				el.style.opacity = "0"
				if (selectedSearchProperty) {
					map.current.setFeatureState(
						{ source: "kipa", sourceLayer: "PalstanSijaintitiedot", id: selectedSearchProperty.plotId },
						{ searched: false }
					)
					setSelectedSearchProperty(undefined)
				}
			}
		}
	}

	const handleMemberRowClick = (member: RoadCooperativeMemberType, index: number) => {
		console.log(member, index)
		setActiveMember(member)
	}

	const propertySelectClickFunktion = async (e: any, tempAllPropertyList: any) => {
		if (!currentMember.current) {
			toast.warning("Valitse ensin osakas vasemmalla olevasta listasta jonka jälkeen voit valita osakkaalle kiinteistöt kartalta!")
			return
		}
		let tempPropertyList

		console.log(e.features, " --------------- tämä on palstan dataa ---------------- ", allPropertiesList)
		if (!e.features[0].state.hover) {
			return
		}
		if (e.features.length > 0) {
			console.log(" --- properties 1 --- ", e.features)
			const plotId = e.features[0].id
			const kiinteistotunnuksenEsitysmuoto = e.features[0].properties.kiinteistotunnuksenEsitysmuoto

			const index = selectedPropertiesList.current.findIndex((p: any) => p.propertyByPropertyId.plotId === plotId)
			if (index !== -1) {
				let allSameProperties: any[] = []
				let allSamePropertiesString = ""

				for (let i = 0, len = selectedPropertiesList.current.length; len > i; i++) {
					const est: any = selectedPropertiesList.current[i]
					if (est.propertyByPropertyId.plotId == plotId) {
						console.log(est)

						allSameProperties = allSameProperties.concat(est)
						allSamePropertiesString += `
						${est.costCenterByCostCenterId.name}
						
						<p>Yksiköt: ${est.roadUnits} </p>
					
						`
					}
				}

				const currentEstablishment = selectedPropertiesList.current[index]
				const popupHtmlElement = `
				<div class='map-property-info-popup-div' id='map-property-info-popup' >
				<p class='map-property-info-popup-title'  >${kiinteistotunnuksenEsitysmuoto}  </p>
				  <div class='map-property-info-popup-content' >
				  ${allSamePropertiesString}
				  </div>
				  <button id=property-delete-button-${plotId} class="map-property-info-popup-button" >Poista kiinteistö</button>
				</div>`

				const latLng: any = [e.lngLat.lng, e.lngLat.lat]

				const popup = new maplibregl.Popup({ className: "map-property-info-popup" }).setLngLat(latLng).setHTML(popupHtmlElement).addTo(map.current)

				const handleRemoveProperty = async (selectedPropertiesList: any) => {
					console.log(" --- properties 2 --- ")
					tempPropertyList = selectedPropertiesList.current.filter((p: any) => p.propertyByPropertyId.plotId !== plotId)
					map.current.setFeatureState({ source: "kipa", sourceLayer: "PalstanSijaintitiedot", id: plotId }, { selected: false })
					console.log(selectedPropertiesList.current, " --- MEMBER EST ----")

					for (let i = 0, len = selectedPropertiesList.current.length; len > i; i++) {
						if (plotId == selectedPropertiesList.current[i].propertyByPropertyId.plotId) {
							console.log(plotId, " ---- TÄMÄ TELETÖIDÄÄN ---- ", selectedPropertiesList.current[i])
							await deleteEstablishment({ variables: { id: selectedPropertiesList.current[i].id } })
							refetch()
						}
					}
					setSelectedPropertiesList(tempPropertyList)
					popup.remove()
				}

				document.getElementById(`property-delete-button-${plotId}`)?.addEventListener("click", () => handleRemoveProperty(selectedPropertiesList))
			} else {
				// Kun valitaan kiinteistö niin luodaan uusi establishment
				// Täytyy käydä kaikki tiekunnan kiinteistöt läpi

				// TODO: pitää voida lisätä yksikkö kustannuspaikalle
				// const RCProperties = tempAllPropertyList.findAll((p: any) => p.plotId === plotId)
				// console.log(RCProperties, " --- ALL ---")
				let allSameProperties: any[] = []
				let allSamePropertiesString = ""

				for (let i = 0, len = tempAllPropertyList.length; len > i; i++) {
					if (tempAllPropertyList[i].plotId == plotId) {
						allSameProperties = allSameProperties.concat(tempAllPropertyList[i])
						allSamePropertiesString += `
						${tempAllPropertyList[i].costCenterName}
						<div class='map-units-input-box' >
				  		<p>Yksiköt:</p><input class='map-units-input-field' id="input-plot-units-${plotId}-${tempAllPropertyList[i].costCenterId}" type="number" placeholder="kpl" label="Yksiköt" /> 
					</div> 
						`
					}
				}

				console.log(allSameProperties, " --- ALL SAME ---")

				const popupHtmlElement = `
				<div class='map-property-info-popup-div' id='map-property-info-popup' >
				<p class='map-property-info-popup-title deafult-cursor'  >${kiinteistotunnuksenEsitysmuoto}  </p>
				  <div class='map-property-info-popup-content' >
				 ${allSamePropertiesString}
				  </div>
				  <button id=property-add-button-${plotId} class="map-property-info-popup-button" >Lisää kiinteistö</button>
				</div>`

				const latLng: any = [e.lngLat.lng, e.lngLat.lat]

				const popup = new maplibregl.Popup({ className: "map-property-info-popup" }).setLngLat(latLng).setHTML(popupHtmlElement).addTo(map.current)

				const handleAddProperty = async (selectedPropertiesList: any) => {
					console.log(allSameProperties, " --- ALL SAME IN CREATE ----")
					for (let i = 0, len = allSameProperties.length; len > i; i++) {
						const RCProperty = allSameProperties[i]
						const inputValue: any = document.getElementById(`input-plot-units-${plotId}-${RCProperty.costCenterId}`)
						console.log(inputValue, inputValue?.value, " ----- INPUT VALUE ----- ")
						if (inputValue?.value == "" || inputValue?.value == undefined) {
							continue
						}

						console.log(" --- properties 3 --- ", RCProperty.costCenterName)
						await createEstablishment({
							variables: {
								roadCooperativeMemberId: currentMember.current.id,
								amount: Number(inputValue?.value) ?? 0,
								costCenterId: RCProperty.costCenterId,
								propertyId: RCProperty.id,
								roadUsageUnitId: defaultUsageUnitId.current,
							},
							onCompleted(data, clientOptions) {
								const estId = data.createEstablishment.establishment.id
								const newPropertyObj: any = {
									roadUnits: Number(inputValue?.value) ?? 0,
									id: estId,
									costCenterByCostCenterId: {
										name: RCProperty.costCenterName,
									},
									propertyByPropertyId: {
										mmlPropertyId: kiinteistotunnuksenEsitysmuoto,
										plotId: plotId,
										propertyName: RCProperty.propertyName,
									},
								}
								// tempPropertyList = selectedPropertiesList.current.concat(newPropertyObj)
								setSelectedPropertiesList(selectedPropertiesList.current.concat(newPropertyObj))
								refetch()
							},
						})
					}
					map.current.setFeatureState({ source: "kipa", sourceLayer: "PalstanSijaintitiedot", id: plotId }, { selected: true })
					popup.remove()
				}

				document.getElementById(`property-add-button-${plotId}`)?.addEventListener("click", () => handleAddProperty(selectedPropertiesList))
			}
			// setSelectedPropertiesList(tempPropertyList ?? [])
		}
	}

	useEffect(() => {
		// MAP INITIAL LOADING EFFECT
		const func = async () => {
			const { data: geoJsonData } = await getAllGeoJson()
			map.current = new maplibregl.Map({
				container: mapContainerRef.current ?? "map",
				style: `https://avoin-karttakuva.maanmittauslaitos.fi/vectortiles/stylejson/v20/backgroundmap.json?TileMatrixSet=WGS84_Pseudo-Mercator&api-key=${config.MML_API_KEY}`,
				center: [26.645, 64.101],
				zoom: 5,
				maxBounds: mapMaxBounds,
			})

			map.current.doubleClickZoom.disable()
			map.current.on("load", () => {
				loadKipaLayers(map) // loading MML kiinteistöpalvelu layer

				map.current.setLayoutProperty("KiinteistotunnuksenSijaintitiedot", "visibility", "visible")
				map.current.setLayoutProperty("KiinteistorajanSijaintitiedot", "visibility", "visible")
				map.current.setLayoutProperty("PalstanSijaintitiedot", "visibility", "visible")
				if (geoJsonData?.roadCooperativeWithJWT.costCentersByRoadCooperativeId.nodes.length > 0) {
					addAllCostCentersRoadLinksSourceLayer(map, geoJsonData)
					const tempAllPropertyList = showAllRoadCooperativeProperties(map, geoJsonData)
					setAllPropertiesList(tempAllPropertyList)
					setSearchedProperties(tempAllPropertyList)
					map.current.on("click", "PalstanSijaintitiedot", (e: any) => propertySelectClickFunktion(e, tempAllPropertyList))
				}
			})
			setInitialDataLoaded(true)
		}
		func()
		// clean up on unmount
		return () => map.current.remove()
	}, [])

	window.addEventListener("mousedown", function (e) {
		// Funktio estää käyttäjää painamasta hiiren keskimmäistä painiketta.
		// Tarkistetaan, onko painettu nappi keskinäppäin (2)
		if (e.which === 2) {
			e.preventDefault() // Estetään oletustoiminto
		}
	})

	const handlePropertySearch = (value: string) => {
		console.log(value)
		const tempProperties: Property[] = []
		for (let i = 0, len = allPropertiesList.length; len > i; i++) {
			const property = allPropertiesList[i]
			const name = property?.propertyName ?? ""
			console.log(property)

			if (property.mmlPropertyId.includes(value) || name.includes(value)) {
				tempProperties.push(property)
			}
		}
		console.log(tempProperties)
		setSearchedProperties(tempProperties)
		null
	}

	const handleSearchedPropertyClick = async (property: Property) => {
		console.log(property)
		if (selectedSearchProperty) {
			if (selectedSearchProperty.plotId == property.plotId) {
				map.current.setFeatureState({ source: "kipa", sourceLayer: "PalstanSijaintitiedot", id: selectedSearchProperty.plotId }, { searched: false })
				setSelectedSearchProperty(undefined)
				return
			}
			map.current.setFeatureState({ source: "kipa", sourceLayer: "PalstanSijaintitiedot", id: selectedSearchProperty.plotId }, { searched: false })
		}

		try {
			const propertyDataFromMML = await axios({
				url: `https://avoin-paikkatieto.maanmittauslaitos.fi/kiinteisto-avoin/simple-features/v3/collections/PalstanSijaintitiedot/items/${property.plotId}?api-key=${config.MML_API_KEY}`,
				method: "GET",
			})
			const center = turf.center(turf.points(propertyDataFromMML.data.geometry.coordinates[0]))

			map.current.flyTo({
				center: center.geometry.coordinates,
				zoom: 15, // Voit säätää zoom-tason tarpeesi mukaan
			})
		} catch (error) {
			console.error(error)
		}

		map.current.setFeatureState({ source: "kipa", sourceLayer: "PalstanSijaintitiedot", id: property.plotId }, { searched: true })
		setSelectedSearchProperty(property)
	}

	const hadleCloseInfoBox = () => {
		const infoBoxEl = document.getElementById("simple-import-map-container-box")
		console.log(infoBoxEl?.style)
		setOpenInfoBox(false)
	}

	return (
		<>
			<Collapse in={openInfoBox} id={"simple-import-map-info-box"}>
				<Alert onClose={() => hadleCloseInfoBox()} severity={"info"}>
					<Typography sx={{ fontWeight: "500" }}>
						{" "}
						Luo yksiköitä valitsemalla ensin osakas listasta jonka jälkeen klikkaa haluamiasi kiinteistöjä kartalta ja lisää niille yksiköt. Voit
						lisätä saman kiinteistön eri osakkaille omilla yksikkö määrillä.
					</Typography>
				</Alert>
			</Collapse>

			<Box id="simple-import-map-container-box" sx={{ height: "100%" }}>
				<div
					className="map-container"
					id="simple-import-map-container"
					onAuxClick={(e) => {
						e.preventDefault()
					}}
					onClick={void 0}
					style={{ overflow: "hidden" }}
				>
					<Fab sx={{ position: "absolute", margin: "5px", right: "10px" }} size={"small"} color="primary" onClick={handleSearchPopoverClick}>
						<SearchIcon />
					</Fab>
					<Paper
						id="property-search-popover"
						sx={{
							padding: "10px",
							zIndex: "500",
							position: "absolute",
							right: "30px",
							marginTop: "20px",
							display: "flex",
							flexDirection: "column",
							opacity: 1,
							transition: "visibility 0.5s, opacity 0.1s linear",
							visibility: "visible",
						}}
					>
						<Stack direction="row" sx={{ justifyContent: "center", alignItems: "center" }}>
							<TextField
								onChange={(e: { target: { value: string } }) => handlePropertySearch(e.target.value)}
								// sx={{ width: "150px" }}
								label={"Kiinteistöhaku"}
							></TextField>
							<Tooltip title="Voit etsiä kiinteistön kartalta, klikkaamalla kiinteistöä alla olevasta listasta">
								<InfoIcon color="info" sx={{ paddingLeft: "5px" }} />
							</Tooltip>
						</Stack>

						<Box sx={{ maxHeight: "200px", overflowY: "scroll" }}>
							{searchedProperties.map((property: Property) => {
								console.log(selectedPropertiesList.current)
								let propertyName = property.propertyName ?? ""
								if (propertyName?.length > 20) propertyName = propertyName.slice(0, 15) + "..."
								let color = ""
								if (selectedSearchProperty?.plotId == property.plotId) color = "#0009fc4f"
								if (
									selectedSearchProperty?.plotId == property.plotId &&
									selectedPropertiesList.current.some(
										(property2: Establishment) => property2.propertyByPropertyId.plotId == selectedSearchProperty?.plotId
									)
								)
									color = "#00ff2b69"
								return (
									<Box
										onClick={() => handleSearchedPropertyClick(property)}
										key={property.id}
										sx={{ cursor: "pointer", background: color, padding: "5px", borderRadius: "5px" }}
									>
										<Stack direction="row">
											<Typography sx={{ fontSize: "0.9rem", fontWeight: "400" }}>
												{property.mmlPropertyId} {propertyName}
											</Typography>
										</Stack>
										<Divider></Divider>
									</Box>
								)
							})}
						</Box>
					</Paper>
					<Fab sx={{ position: "absolute", margin: "5px" }} size={"small"} color="primary" onClick={handlePopoverClick}>
						<MenuIcon />
					</Fab>
					<div
						id="member-property-popover"
						style={{
							margin: "20px",
							height: "auto",
							maxWidth: "550px",
							position: "absolute",
							zIndex: 1,
							opacity: 1,
							transition: "visibility 0.5s, opacity 0.1s linear",
							visibility: "visible",
						}}
					>
						<TableContainer
							component={Paper}
							sx={{ maxHeight: "57vh" }}
							PaperProps={{ sx: { zIndex: 2, margin: "5px", padding: "10px", left: "10px" } }}
						>
							<Table stickyHeader={true}>
								<TableHead>
									<TableRow>
										<TableCell>Osakas</TableCell>
										<TableCell>Kiinteistö</TableCell>
										<TableCell>Yksiköt</TableCell>
										<TableCell>
											<Stack direction="row">
												%
												<Tooltip title="Yksiköiden prosenttiosuus tiekunnan kokonaisyksiköistä">
													<InfoIcon color="info" fontSize="small" sx={{ paddingLeft: "5px" }} />
												</Tooltip>
											</Stack>
										</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{allMembers.map((member, index) => {
										return (
											<React.Fragment key={member.id}>
												<MemberRow
													handleMemberRowClick={handleMemberRowClick}
													index={index}
													member={member}
													activeMember={activeMember}
													totalUnits={totalUnits}
												/>
											</React.Fragment>
										)
									})}
								</TableBody>
							</Table>
						</TableContainer>
					</div>
					<div className="map" ref={mapContainerRef} id="map" />
				</div>
			</Box>
		</>
	)
}

type MemberRowProps = {
	handleMemberRowClick: (member: RoadCooperativeMemberType, index: number) => void
	member: RoadCooperativeMemberType
	activeMember: RoadCooperativeMemberType
	index: number
	totalUnits: number
}

const MemberRow = ({ handleMemberRowClick, member, index, activeMember, totalUnits }: MemberRowProps) => {
	const [memberUnits, setMemberUnits] = useState(0)
	const [precentageFromTotalUnits, setPrecentageFromTotalUnits] = useState(0)

	useEffect(() => {
		if (member.establishmentsByRoadCooperativeMemberId?.nodes) {
			let unitsSum = 0
			for (let i = 0, len = member.establishmentsByRoadCooperativeMemberId?.nodes.length; len > i; i++) {
				unitsSum = unitsSum + member.establishmentsByRoadCooperativeMemberId?.nodes[i].roadUnits
			}
			setMemberUnits(unitsSum)
		}
	}, [member.establishmentsByRoadCooperativeMemberId?.nodes])

	useEffect(() => {
		// Laske prosenttiosuus kokonaisyksiköistä
		console.log(" ----- TOTAL UNITS ----- ")

		if (!totalUnits) {
			setPrecentageFromTotalUnits(0)
			return
		}
		const precentage = (memberUnits / totalUnits) * 100
		setPrecentageFromTotalUnits(Number(precentage.toFixed(2)))
	}, [totalUnits, memberUnits])

	return (
		<>
			<TableRow
				sx={{ background: activeMember?.id == member.id ? "#ffe0afb5" : "#fff", cursor: "pointer" }}
				onClick={() => handleMemberRowClick(member, index)}
			>
				<TableCell>{member.name}</TableCell>
				<TableCell>
					{member.establishmentsByRoadCooperativeMemberId?.nodes.map((est: Establishment, index: number) => {
						return (
							<React.Fragment key={est.id}>
								<Typography sx={{ fontSize: "0.8rem", fontWeight: "400" }}>
									{est.propertyByPropertyId.mmlPropertyId}, {est.propertyByPropertyId.propertyName}
								</Typography>
							</React.Fragment>
						)
					})}
				</TableCell>
				<TableCell>{memberUnits}</TableCell>
				<TableCell>{precentageFromTotalUnits}%</TableCell>
			</TableRow>
		</>
	)
}

const showAllRoadCooperativeProperties = (map: MapInstance, RcGeoJsonData: RoadCooperative) => {
	const tempPropertyList = []
	if (RcGeoJsonData) {
		map.current.removeFeatureState({
			source: "kipa",
			sourceLayer: "PalstanSijaintitiedot",
		})
		for (let i = 0, len = RcGeoJsonData.roadCooperativeWithJWT.costCentersByRoadCooperativeId.nodes.length; len > i; i++) {
			const cc = RcGeoJsonData.roadCooperativeWithJWT.costCentersByRoadCooperativeId.nodes[i]

			for (let i2 = 0, len = cc.propertiesByCostCenterId?.nodes.length; len > i2; i2++) {
				map.current.setFeatureState(
					{ source: "kipa", sourceLayer: "PalstanSijaintitiedot", id: cc.propertiesByCostCenterId?.nodes[i2].plotId },
					{ hover: true }
				)
				tempPropertyList.push({ ...cc.propertiesByCostCenterId?.nodes[i2], costCenterId: cc.id, costCenterName: cc.name, costCenterColor: cc.idColor })
			}
		}
		console.log(tempPropertyList, " --- TEMP LIST --")
	}
	return tempPropertyList
}

const addAllCostCentersRoadLinksSourceLayer = (map: any, RCGeoJson: RoadCooperative) => {
	if (RCGeoJson === undefined) return

	const allCostCenters = []
	let bounds = null

	const cc = RCGeoJson.roadCooperativeWithJWT.costCentersByRoadCooperativeId.nodes[0]
	bounds = new maplibregl.LngLatBounds(
		{ lat: cc.roadLinksByCostCenterId.nodes[0].coordinates[0].lng, lng: cc.roadLinksByCostCenterId.nodes[0].coordinates[0].lat },
		{ lat: cc.roadLinksByCostCenterId.nodes[0].coordinates[0].lng, lng: cc.roadLinksByCostCenterId.nodes[0].coordinates[0].lat }
	)

	for (let i = 0, len = RCGeoJson.roadCooperativeWithJWT.costCentersByRoadCooperativeId.nodes.length; len > i; i++) {
		const cc = RCGeoJson.roadCooperativeWithJWT.costCentersByRoadCooperativeId.nodes[i]

		const costCenter = {
			...cc,
			allCoordinatePoints: [],
		}
		for (let i2 = 0, len = cc.roadLinksByCostCenterId?.nodes.length; len > i2; i2++) {
			const link = cc.roadLinksByCostCenterId?.nodes[i2]
			const linkCoordinateArray = []
			for (let i3 = 0, len = link.coordinates.length; len > i3; i3++) {
				const coordinates = link.coordinates[i3]
				bounds?.extend([coordinates.lat, coordinates.lng])
				linkCoordinateArray.push([coordinates.lat, coordinates.lng])
			}
			costCenter.allCoordinatePoints.push(linkCoordinateArray)
		}
		allCostCenters.push(costCenter)
	}

	for (let i = 0, len = allCostCenters.length; len > i; i++) {
		const costCenter = allCostCenters[i]

		const geoJsonData: FeatureCollection = {
			type: "FeatureCollection",
			features: [
				{
					properties: {},
					type: "Feature",
					geometry: {
						type: "MultiLineString",
						coordinates: costCenter.allCoordinatePoints,
					},
				},
			],
		}

		map.current.addSource(`costCenterRoadLinks${costCenter.name + i}`, {
			type: "geojson",
			data: geoJsonData,
		})

		map.current.addLayer({
			id: `costCenterRoadLinks${costCenter.name + i}`,
			type: "line",
			source: `costCenterRoadLinks${costCenter.name + i}`,
			layout: {
				"line-join": "round",
				"line-cap": "round",
				visibility: "visible",
			},
			paint: {
				"line-color": costCenter.idColor ?? "#505050",
				"line-width": 5,
			},
		})
	}

	map.current.fitBounds(bounds, {
		padding: 50,
	})
}

export { UnitsImportMap }
