import React from "react"
import { Chip, IconButton, Button } from "@mui/material"
import userProfileStorage from "../../utils/providers/userProfileProvider/userProfileProvider"
import { Fab } from "@mui/material"
import isMobile from "ismobilejs"
import DeleteIcon from "@mui/icons-material/Delete"

// Näitä käytetään joko suoraan mutaation teossa tai napissa, mistä päästään tekemään mutaatioita.
// Tarkoitus että käyttäjillä joilla ei ole oikeutta mutaatioiden tekemiseen eivät näe nappeja ollenkaan.
// HUOMAA ETTÄ OSA MUTAATIOISTA HYVÄKSYTÄÄN KAIKILLA OIKEUSILLA.

export const MutationButton = ({ children, ...props }: any) => {
	const permission = userProfileStorage({ type: "getActiveRoadCooperative" }).permission
	return <>{permission === "edit" && <Button {...props}>{children}</Button>}</>
}

export const MutationIconButton = ({ icon, children, ...props }: any) => {
	const permission = userProfileStorage({ type: "getActiveRoadCooperative" }).permission
	return (
		<>
			{permission === "edit" && (
				<IconButton {...props}>
					{icon}
					{children}
				</IconButton>
			)}
		</>
	)
}

export const MutationChip = ({ icon, label, ...props }: any) => {
	const permission = userProfileStorage({ type: "getActiveRoadCooperative" }).permission
	return <>{permission === "edit" && <Chip icon={icon} label={label} {...props} />}</>
}

export const MutationFab = ({ icon, label, ...props }: any) => {
	const permission = userProfileStorage({ type: "getActiveRoadCooperative" }).permission
	return <>{permission === "edit" && <Fab icon={icon} label={label} {...props} />}</>
}

type DeleteChipProps = {
	deleteFunction: (functionParams?: any) => void
	functionParams?: any
}

export const MutationDeleteChip = ({ deleteFunction, functionParams }: DeleteChipProps) => {
	const handleDeleteFunction = () => {
		try {
			if (functionParams) {
				deleteFunction(functionParams)
			} else {
				deleteFunction()
			}
		} catch (error) {
			console.error("Error in Delete Function")
			console.error("Function with error: ", deleteFunction())
			console.error("Function parameters: ", functionParams)
		}
	}

	// Tarkistetaan käyttäjän permission
	const permission = userProfileStorage({ type: "getActiveRoadCooperative" }).permission

	// Ei näytetä mitään, jos permission ei ole "edit"
	if (permission !== "edit") return null

	return (
		<React.Fragment>
			{isMobile().any ? (
				<IconButton
					onClick={handleDeleteFunction}
					icon={<DeleteIcon />}
					sx={{
						position: "absolute",
						top: "5px",
						left: "5px",
					}}
				>
					<DeleteIcon />
				</IconButton>
			) : (
				<Chip
					label="Poista"
					variant="contained"
					onClick={handleDeleteFunction}
					icon={<DeleteIcon />}
					sx={{
						position: "absolute",
						top: "10px",
						left: "10px",
					}}
				/>
			)}
		</React.Fragment>
	)
}
