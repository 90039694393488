import { Box, Collapse, Dialog, DialogContent, IconButton, Table, TableCell, TableHead, TableRow } from "@mui/material"
import { useEffect, useState } from "react"
import React from "react"

import EditIcon from "@mui/icons-material/Edit"

import { ApolloQueryResult, gql } from "@apollo/client"
import { TableBody } from "@mui/material"

import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos"
import CustomDialogActions from "../../../../reusables/CustomDialogActions"
import CustomDialogTitle from "../../../../reusables/CustomDialogTitle"
import DialogCloseButton from "../../../../reusables/DialogCloseButton"
import PartnersEstablishmentRow from "./PartnerEstablishmentRow"
import PartnerEditDialog from "./PartnerEditDialog"
import { Typography } from "@mui/material"
import { TableContainer } from "@mui/material"
import { Paper } from "@mui/material"
import { tableCellHeaderStyles, tableCellHeaderTypographyStyles, tableCellStyles, tableCellTypographyStyles } from "../customStyles"
import CopyableText from "../../../../reusables/CopyableText"
import { MutationIconButton } from "../../../../reusables/MutationButtonChipFab"

type PartnersTableRowProps = {
	refetch: (
		variables?:
			| Partial<{
					id: number | null
			  }>
			| undefined
	) => Promise<ApolloQueryResult<any>>
	partner: any
	unitsViewType: UnitsViewType
}
type SortedPropertyData = {
	mmlPropertyId: string
	plotId: number
	propertyName: string
	costCenterId: number
	costCenterColor: string
	establishments: Establishment[]
}

const PartnersTableRow = ({ partner, refetch, unitsViewType }: PartnersTableRowProps) => {
	const [open, setOpen] = useState(false)
	const [openEditDialog, setOpenEditDialog] = useState(false)
	const [allProperties, setAllproperties] = useState<SortedPropertyData[]>([])
	const [allRoadUnits, setAllRoadUnits] = useState(0)
	const [allCostCenters, setAllCostCenters] = useState<CostCenter[]>([])

	useEffect(() => {
		if (partner.establishments) {
			const partnersRoadUnits = partner.establishments.reduce((acc: number, est: Establishment) => {
				return (acc = acc + est.roadUnits)
			}, 0)
			setAllRoadUnits(partnersRoadUnits)
			const allPropertiesList = partner.establishments.reduce((acc: SortedPropertyData[], establishment: Establishment) => {
				if (
					!establishment ||
					establishment == undefined ||
					establishment == null ||
					establishment.propertyByPropertyId == undefined ||
					establishment.propertyByPropertyId.mmlPropertyId == undefined
				)
					return acc
				const newProperty = {
					costCenterColor: establishment.costCenterByCostCenterId.idColor,
					costCenterId: establishment.propertyByPropertyId.costCenterId,
					propertyName: establishment.propertyByPropertyId.propertyName,
					plotId: establishment.propertyByPropertyId.plotId,
					mmlPropertyId: establishment.propertyByPropertyId.mmlPropertyId,
					establishments: [establishment],
				}
				if (acc)
					if (
						acc.some((property) => property.plotId === newProperty.plotId) &&
						acc.some((property) => property.costCenterId === newProperty.costCenterId)
					) {
						const oldProperty = acc.filter((property) => property.plotId === newProperty.plotId)[0]
						const updatedOldProperty = { ...newProperty, establishments: [...oldProperty.establishments, establishment] }
						const updatedAcc = []
						for (let i = 0; acc.length > i; i++) {
							if (acc[i].plotId === updatedOldProperty.plotId) {
								updatedAcc.push(updatedOldProperty)
							} else {
								updatedAcc.push(acc[i])
							}
						}
						return updatedAcc
					} else {
						acc.push(newProperty)
						return acc
					}
			}, [])
			setAllproperties(allPropertiesList)
		}

		if (partner.costCenters) {
			const ccList: CostCenter[] = []
			for (let i = 0; partner.costCenters.length > i; i++) {
				if (ccList.some((cc) => cc.id === partner.costCenters[i].id)) {
					continue
				} else {
					ccList.push(partner.costCenters[i])
				}
			}
			setAllCostCenters(ccList)
		}
	}, [partner])

	return (
		<>
			<Dialog open={openEditDialog} sx={{ zIndex: "1302" }}>
				<CustomDialogTitle>Osakastietojen muokkaaminen</CustomDialogTitle>
				<DialogCloseButton closeFunction={() => setOpenEditDialog(false)} />

				<DialogContent>
					<PartnerEditDialog refetch={refetch} partner={partner} setOpenEditDialog={setOpenEditDialog} />
				</DialogContent>
				<CustomDialogActions></CustomDialogActions>
			</Dialog>

			<TableRow>
				<TableCell sx={{ paddingBottom: "25px", paddingTop: "25px" }}>
					{allCostCenters.map((cc: CostCenter, index: number) => {
						return (
							<Box
								key={index}
								sx={{
									width: "12px",
									height: "12px",
									background: cc,
									borderRadius: "8px",
									marginRight: "15px",
									marginLeft: "10px",
								}}
							/>
						)
					})}
				</TableCell>
				<TableCell>
					{open ? (
						<IconButton
							// color='primary'
							// sx={{ background: property.costCenterByCostCenterId.idColor }}
							size="small"
							onClick={() => setOpen(!open)}
						>
							<ArrowForwardIosIcon fontSize="small" sx={{ transform: "rotate(90deg) translateY(3px) " }} />
						</IconButton>
					) : (
						<IconButton
							// color='primary'
							// sx={{ background: property.costCenterByCostCenterId.idColor }}
							size="small"
							onClick={() => setOpen(!open)}
						>
							<ArrowForwardIosIcon fontSize="small" />
						</IconButton>
					)}
				</TableCell>

				<TableCell align="start" sx={{ maxWidth: "150px" }}>
					<CopyableText text={partner.name}></CopyableText>
				</TableCell>
				<TableCell align="center">{allRoadUnits}</TableCell>
				<TableCell sx={{ textAlign: "end", width: "20px" }}>
					<MutationIconButton size="small" onClick={() => setOpenEditDialog(true)}>
						<EditIcon sx={{ width: "20px", color: "rgba(0, 0, 0, 0.54)" }} />
					</MutationIconButton>
				</TableCell>
			</TableRow>
			<TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
				<TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
					<Collapse in={open} timeout="auto" unmountOnExit>
						<TableContainer component={Paper}>
							<Typography sx={{ fontWeight: "500", fontStyle: "italic", fontSize: "0.8rem", marginLeft: "10px" }}>
								Osakkaan {partner.name} yksikkölistaus
							</Typography>
							<Table size={"small"}>
								<TableHead>
									<TableCell sx={tableCellHeaderStyles}></TableCell>
									{/* <TableCell sx={{ fontSize: "1rem" }}></TableCell> */}
									<TableCell sx={{ ...tableCellHeaderTypographyStyles, minWidth: "100px" }}>Kiinteistö</TableCell>
									{unitsViewType == "professional" && (
										<TableCell sx={tableCellHeaderStyles}>
											<Typography sx={tableCellHeaderTypographyStyles}>Liikennelaji</Typography>
										</TableCell>
									)}
									<TableCell sx={tableCellHeaderStyles}>
										<Typography sx={tableCellHeaderTypographyStyles}>Lisätieto</Typography>
									</TableCell>
									<TableCell sx={tableCellHeaderStyles}>
										<Typography sx={tableCellHeaderTypographyStyles}>Yksiköt</Typography>
									</TableCell>
									<TableCell sx={tableCellHeaderStyles}></TableCell>
								</TableHead>
								<TableBody>
									{partner.establishments.length > 0 ? (
										partner.establishments.map((unit: SortedPropertyData, index: number) => {
											return (
												<>
													<PartnersEstablishmentRow refetch={refetch} key={partner.id} unit={unit} unitsViewType={unitsViewType} />
												</>
											)
										})
									) : (
										<TableRow>
											<TableCell colSpan={5} sx={{ textAlign: "center" }}>
												Osakkaalla ei ole yksiköitä.
											</TableCell>
										</TableRow>
									)}
								</TableBody>
							</Table>
						</TableContainer>
					</Collapse>
				</TableCell>
			</TableRow>
		</>
	)
}

export default PartnersTableRow
