import React, { useEffect, useState } from "react"
import "./Homepage.css"
import { Typography, Divider, Grid, Stack, Button, FormControl, FormControlLabel, Checkbox } from "@mui/material"
import DialogCloseButton from "../reusables/DialogCloseButton"
import { useQuery, gql, useMutation } from "@apollo/client"

const GET_ROAD_COOPERATIVE_DATA = gql`
	query GetRoadCooperativeData {
		roadCooperativeWithJWT {
			id
			disableHelp
			askedHelp
			costCentersByRoadCooperativeId {
				nodes {
					id
					establishmentsByCostCenterId {
						nodes {
							id
						}
						totalCount
					}
					propertiesByCostCenterId {
						nodes {
							id
						}
						totalCount
					}
				}
			}
			roadCooperativeMembersByRoadCooperativeId {
				nodes {
					id
				}
				totalCount
			}
			totalUnits
			bankAccountsByRoadCooperativeId {
				nodes {
					id
				}
				totalCount
			}
		}
	}
`

const DISABLE_RC_HELP = gql`
	mutation DisableRoadCooperativeHelp($id: UUID!, $disableHelp: Boolean = true) {
		updateRoadCooperativeById(input: { roadCooperativePatch: { disableHelp: $disableHelp }, id: $id }) {
			roadCooperative {
				disableHelp
				id
			}
		}
	}
`

interface StatusData {
	roadCooperativeWithJWT: {
		costCentersByRoadCooperativeId: {
			nodes: {
				establishmentsByCostCenterId: {
					totalCount: number
				}
				propertiesByCostCenterId: {
					totalCount: number
				}
			}[]
		}
		roadCooperativeMembersByRoadCooperativeId: {
			totalCount: number
		}
		totalUnits: number
		bankAccountsByRoadCooperativeId: {
			totalCount: number
		}
	}
}

interface HelperBoxProps {
	title: string
	pointerDirection: "left" | "right" | "bottom" | "top" | "none"
	subclass: string
	children: React.ReactChild
	isLast?: boolean
	closeFunction: (disableOnboarding?: boolean) => void
}

const HelperBox = ({ title, pointerDirection, subclass, children, isLast, closeFunction }: HelperBoxProps) => {
	const [disableOnboarding, setDisableOnboarding] = useState<boolean>(false)

	const handleClose = () => {
		const doDisable = isLast ? true : disableOnboarding
		closeFunction(doDisable)
	}
	const handleCloseButtonClose = () => {
		closeFunction(false)
	}
	const handleCheckBox = (e: React.ChangeEvent<HTMLInputElement>) => {
		setDisableOnboarding(e.target.checked)
	}

	return (
		<div className={`onboarding-helper ${pointerDirection == "none" ? null : "onboarding-helper-arrow"} ${pointerDirection} ${subclass}`}>
			<DialogCloseButton closeFunction={handleCloseButtonClose} />
			<Typography textAlign="center" sx={{ fontWeight: "900", fontSize: "20px", height: "30px" }}>
				{title}
			</Typography>
			<Divider />
			<Grid container direction="row" alignContent="space-between" height="calc(100% - 45px)">
				<Grid item xs={12} sx={{ paddingY: "10px" }}>
					{children}
				</Grid>
				<Grid item xs={12}>
					<Stack direction="row" justifyContent="flex-end">
						{isLast ? null : (
							<FormControl sx={{ marginRight: "20px" }}>
								<FormControlLabel
									label={<Typography variant="body2">Älä näytä jatkossa ohjeita</Typography>}
									labelPlacement="start"
									control={<Checkbox id="dont-show-again-checkbox" onChange={handleCheckBox} color="primary" size="medium" />}
									sx={{ alignSelf: "center" }}
								/>
							</FormControl>
						)}
						<Button variant="contained" onClick={handleClose}>
							{isLast ? "Valmis" : "Selvä"}
						</Button>
					</Stack>
				</Grid>
			</Grid>
		</div>
	)
}

const StartHelper = ({ closeFunction }: { closeFunction: (disableOnboarding?: boolean) => void }) => {
	return (
		<HelperBox closeFunction={closeFunction} title="Tervetuloa Tievahtiin!" pointerDirection="left" subclass="ob-start">
			<>
				<Typography fontWeight="normal" sx={{ paddingY: "15px" }} textAlign="center">
					Aloita lisäämällä tiekuntasi tiet ja kiinteistöt.
				</Typography>
				<Typography fontWeight="normal" sx={{ paddingY: "15px" }} textAlign="center">
					Klikkaamalla sinistä info-ikonia pääset tarkastelemaan ohjeita tarkemmin ja oranssia ikonia klikatessasi teemme käyttöönoton puolestasi.
				</Typography>
			</>
		</HelperBox>
	)
}
const AfterMembersHelper = ({ closeFunction }: { closeFunction: (disableOnboarding?: boolean) => void }) => {
	return (
		<HelperBox closeFunction={closeFunction} title="Tie ja kiinteistöt on nyt lisätty!" pointerDirection="left" subclass="ob-after-members">
			<>
				<Typography fontWeight="normal">
					Voit tarkastella lisäämiäsi tietoja kartalta, tai oikean ylänurkan <b>Avaa</b>-painikkeesta avautuvasta näkymästä.
				</Typography>
				<br />
				<Typography fontWeight="normal">Lisää seuraavaksi tiekuntanne osakkaat.</Typography>
			</>
		</HelperBox>
	)
}
const AfterPropertiesHelper = ({ closeFunction }: { closeFunction: (disableOnboarding?: boolean) => void }) => {
	return (
		<HelperBox closeFunction={closeFunction} title="Osakkaat lisätty onnistuneesti!" pointerDirection="left" subclass="ob-after-properties">
			<>
				<Typography fontWeight="normal">
					Voit tarkastella osakkaiden tietoja oikean ylänurkan <b>Avaa</b>-painikkeesta avautuvasta näkymästä.
				</Typography>
				<br />
				<Typography fontWeight="normal">Lisää seuraavaksi tiekuntanne yksiköt.</Typography>
			</>
		</HelperBox>
	)
}
const AfterAllHelper = ({ closeFunction }: { closeFunction: (disableOnboarding?: boolean) => void }) => {
	return (
		<HelperBox closeFunction={closeFunction} title="Tiekunnan tiedot on nyt lisätty, hienoa!" pointerDirection="none" subclass="ob-after-all">
			<>
				<Typography fontWeight="normal">
					Näet tiekunnan tiet ja kiinteistöt kartalla. Kiinteistöä klikkaamalla näet myös siihen liitetyt osakkaat ja yksiköt.
				</Typography>
				<br />
				<Typography fontWeight="normal">
					Tiekunnan tietoja voit tarkastella myös listamuodossa oikean ylänurkan <b>Avaa</b>-painikkeesta avautuvasta näkymästä.
				</Typography>
				<br />
				<Typography fontWeight="normal">
					Voit vielä viimeistellä käyttöönoton ottamalla Talous-osion käyttöön. Talous-osion käyttöönoton voit aloittaa valitsemalla vasemman reunan
					navigointipalkista <b>Talous</b>, tai vaihtoehtoisesti klikkaamalla oikealla ylhäällä sijaitsevaa <b>Rahapino</b>-symbolia.
				</Typography>
				<br />
				<Typography fontWeight="normal">
					Mikäli kuntasi on rekisteröitynyt palveluumme. Voit tarkastella ja tehdä hakemuksia klikkaamalla oikealla ylhäällä sijaitsevaa{" "}
					<b>vaakuna</b>
					-symbolia.
				</Typography>
			</>
		</HelperBox>
	)
}

interface OnboardingInputStatus {
	status: {
		createRoad: boolean
		createMembers: boolean
		createEstablishments: boolean
	}
}
const OnboardingHelper = () => {
	const { data, error, loading } = useQuery(GET_ROAD_COOPERATIVE_DATA, { fetchPolicy: "cache-first" })
	const [step, setStep] = useState<null | number>(null)
	const [disableRcHelp] = useMutation(DISABLE_RC_HELP)

	const closeFunction = (disableOnboarding = false) => {
		if (disableOnboarding) {
			disableRcHelp({ variables: { id: data.roadCooperativeWithJWT.id, disableHelp: true } })
		}
		setStep(null)
	}
	const getStatusesFromData = (data: StatusData) => {
		const tempStatus = {
			road: false,
			members: false,
			establishments: false,
			bankAccounts: false,
		}
		if (
			data.roadCooperativeWithJWT.costCentersByRoadCooperativeId.nodes.length &&
			data.roadCooperativeWithJWT.costCentersByRoadCooperativeId.nodes[0].propertiesByCostCenterId.totalCount > 0
		)
			tempStatus.road = true
		if (data.roadCooperativeWithJWT.roadCooperativeMembersByRoadCooperativeId.totalCount > 1) tempStatus.members = true
		if (data.roadCooperativeWithJWT.totalUnits > 0) tempStatus.establishments = true
		if (data.roadCooperativeWithJWT.bankAccountsByRoadCooperativeId.totalCount > 0) tempStatus.bankAccounts = true
		return tempStatus
	}

	useEffect(() => {
		if (!data || loading) return
		const isDisabled = data.roadCooperativeWithJWT.disableHelp
		const viewWidth = window.visualViewport ? window.visualViewport.width : 1121
		if (isDisabled || viewWidth < 1120) {
			setStep(null)
			return
		}
		const status = getStatusesFromData(data)
		if (!status.road && !status.members && !status.establishments) setStep(0)
		else if (status.road && !status.members && !status.establishments) setStep(1)
		else if (status.road && status.members && !status.establishments) setStep(2)
		else if (status.road && status.members && status.establishments && !status.bankAccounts) setStep(3)
		else setStep(null)
	}, [data])

	switch (step) {
		case 0:
			return <StartHelper closeFunction={closeFunction} />
		case 1:
			return <AfterMembersHelper closeFunction={closeFunction} />
		case 2:
			return <AfterPropertiesHelper closeFunction={closeFunction} />
		case 3:
			return <AfterAllHelper closeFunction={closeFunction} />
		default:
			return null
	}
}

export default OnboardingHelper
