import React, { ReactElement, useEffect, useState } from "react"
import { gql, useMutation } from "@apollo/client"
import userProfileStorage from "../../../utils/providers/userProfileProvider/userProfileProvider"
import DialogCloseButton from "../../reusables/DialogCloseButton"
import { Box, Container, Stack, TextField, Button, Grid, ToggleButtonGroup, ToggleButton, MenuItem, FormControl, InputLabel, Select } from "@mui/material"
import BusinessIcon from "@mui/icons-material/Business"
import PersonIcon from "@mui/icons-material/Person"
import CustomDialogActions from "../../reusables/CustomDialogActions"
import { getAllCountries } from "../../../network/dataServices/generalDataService"

const UPDATE_INVOICE_CONTACT = gql`
	mutation updateInvoiceContact($id: Int!, $invoiceContactPatch: InvoiceContactPatch!) {
		updateInvoiceContactById(input: { invoiceContactPatch: $invoiceContactPatch, id: $id }) {
			clientMutationId
			invoiceContact {
				id
				contactType
			}
		}
	}
`

enum ContactEnumType {
	USAGE_FEE_PAYER = "USAGE_FEE_PAYER",
	CONTRACTOR = "CONTRACTOR",
	OTHER = "OTHER",
}
interface contactEditDialogProps {
	refetchContacts: () => void
	setOpenEditDialog: React.Dispatch<React.SetStateAction<boolean>>
	setContactPerson: React.Dispatch<React.SetStateAction<Contact>>
	selectedContact: Contact | undefined
}

const ContactEditDialog = ({ refetchContacts, setOpenEditDialog, setContactPerson, selectedContact }: contactEditDialogProps) => {
	const [updateInvoiceContact] = useMutation(UPDATE_INVOICE_CONTACT)
	const [allCountries, setAllCountries] = useState<Country[]>([])

	const handleChangeMemberType = (v: string | null) => {
		if (!v) {
			return
		} else {
			setContactPersonEdit({ ...contactPersonEdit, memberType: v })
		}
	}

	const emptyInformation: Contact = {
		billingAddress: "",
		businessId: "",
		city: "",
		contactType: ContactEnumType.OTHER,
		countryCode: "",
		eInvoiceAddress: "",
		eInvoiceOperator: "",
		email: "",
		name: "",
		postalCode: "",
		additionalInformation: "",
	}

	const handleAfterSave = () => {
		setContactPerson(emptyInformation)
	}

	const handleEditContact = async () => {
		try {
			const { data } = await updateInvoiceContact({
				variables: {
					id: selectedContact?.id,
					invoiceContactPatch: {
						billingAddress: contactPersonEdit.billingAddress || "",
						businessId: contactPersonEdit.businessId || "",
						city: contactPersonEdit.city || "",
						contactType: contactPersonEdit.contactType || ContactEnumType.OTHER,
						countryCode: contactPersonEdit.countryCode || "",
						eInvoiceAddress: contactPersonEdit.eInvoiceAddress || "",
						eInvoiceOperator: contactPersonEdit.eInvoiceOperator || "",
						email: contactPersonEdit.email || "",
						name: contactPersonEdit.name || "",
						postalCode: contactPersonEdit.postalCode || "",
						additionalInformation: contactPersonEdit.additionalInformation || "",
					},
				},
			})
			setOpenEditDialog(false)
			refetchContacts()
			handleAfterSave()
		} catch (error) {
			console.error("Error editing new contact:", error)
		}
	}

	const getContactEnumType = (value: string): ContactEnumType => {
		switch (value) {
			case "Urakoitsija":
				return ContactEnumType.CONTRACTOR
			case "Käyttömaksun maksaja":
				return ContactEnumType.USAGE_FEE_PAYER
			case "Muu":
				return ContactEnumType.OTHER
			default:
				return ContactEnumType.OTHER
		}
	}

	const rcId = userProfileStorage({ type: "getActiveRoadCooperative" }).id
	const [contactPersonEdit, setContactPersonEdit] = useState<Contact>({
		name: selectedContact?.name || "",
		contactType: selectedContact ? selectedContact.contactType : ContactEnumType.OTHER,
		memberType: "yksityishenkilö",
		email: selectedContact?.email || "",
		billingAddress: selectedContact?.billingAddress || "",
		city: selectedContact?.city || "",
		postalCode: selectedContact?.postalCode || "",
		countryCode: selectedContact?.countryCode || "",
		phoneNumber: selectedContact?.phoneNumber || "",
		eInvoiceAddress: selectedContact?.eInvoiceAddress || "",
		eInvoiceOperator: selectedContact?.eInvoiceOperator || "",
		businessId: selectedContact?.businessId || "",
		additionalInformation: selectedContact?.additionalInformation || "",
	})

	useEffect(() => {
		getAllCountries().then((data) => {
			if (data) setAllCountries(data)
		})
		const user = userProfileStorage({ type: "getUserProfile" })
		console.log(user)
		if (selectedContact && selectedContact.businessId) {
			setContactPersonEdit({
				...contactPersonEdit,
				memberType: "yritys",
			})
		} else {
			setContactPersonEdit({
				...contactPersonEdit,
				memberType: "yksityishenkilö",
			})
		}
	}, [])
	return (
		<>
			<DialogCloseButton closeFunction={() => setOpenEditDialog(false)} />
			<Container
				width="md"
				sx={{
					overflow: "auto",
					justifyContent: "center",
					marginTop: "0.5vh",
					height: "100%",
					padding: "10px",
					maxWidth: "md",
					textAlign: "center",
					display: "flex",
				}}
				elevation={10}
			>
				<Stack direction="column" width="100%">
					<Stack direction="column" justifyContent="center" alignItems="center" spacing={2}>
						<FormControl sx={{ marginBottom: "15px", width: "38%" }}>
							<InputLabel></InputLabel>
							<Select
								inputProps={{ sx: { zIndex: "1303" } }}
								SelectDisplayProps={{ sx: { zIndex: "1303" } }}
								MenuProps={{ sx: { zIndex: "1303" } }}
								sx={{ overflow: "hidden", zIndex: "1303", borderRadius: "10px" }}
								value={
									contactPersonEdit.contactType === "USAGE_FEE_PAYER"
										? "Käyttömaksun maksaja"
										: contactPersonEdit.contactType === "CONTRACTOR"
										? "Urakoitsija"
										: "Muu"
								}
								defaultValue={
									contactPersonEdit.contactType === "USAGE_FEE_PAYER"
										? "Käyttömaksun maksaja"
										: contactPersonEdit.contactType === "CONTRACTOR"
										? "Urakoitsija"
										: "Muu"
								}
								onChange={(e: { target: { value: string } }) =>
									setContactPersonEdit({
										...contactPersonEdit,
										contactType: getContactEnumType(e.target.value),
									})
								}
							>
								<MenuItem value={"Urakoitsija"}>Urakoitsija</MenuItem>
								<MenuItem value={"Käyttömaksun maksaja"}>Käyttömaksun maksaja</MenuItem>
								<MenuItem value={"Muu"}>Muu</MenuItem>
							</Select>
						</FormControl>

						<ToggleButtonGroup
							value={contactPersonEdit.memberType}
							color="primary"
							onChange={(e: ReactElement, v: string | null) => handleChangeMemberType(v)}
							exclusive
							size="large"
							aria-label="recipient type"
						>
							<ToggleButton value="yritys" fullWidth sx={{ alignItems: "space-between", maxWidth: 300 }}>
								Yritys
								<BusinessIcon sx={{ marginLeft: 2, fontSize: 32, width: 40 }} />
							</ToggleButton>
							<ToggleButton value="yksityishenkilö" fullWidth sx={{ alignItems: "space-between", maxWidth: 300 }}>
								<PersonIcon sx={{ marginRight: 2, fontSize: 32, width: 40 }} />
								Henkilö
							</ToggleButton>
						</ToggleButtonGroup>
						<Grid>
							{contactPersonEdit.memberType === "yritys" ? (
								<div>
									<Box sx={{ textAlign: "center" }}>
										<Grid container xs={12}>
											<Grid item xs={12} sm={6}>
												<br />
												<TextField
													label="Yrityksen nimi"
													multiline
													variant="standard"
													onChange={(e: any) => setContactPersonEdit({ ...contactPersonEdit, name: e.target.value })}
													value={contactPersonEdit.name}
												/>
												<br />
												<TextField
													label="Lisätietoja"
													multiline
													variant="standard"
													onChange={(e: any) => setContactPersonEdit({ ...contactPersonEdit, additionalInformation: e.target.value })}
													value={contactPersonEdit.additionalInformation}
												/>
												<br />
												<TextField
													label="Sähköposti"
													multiline
													variant="standard"
													onChange={(e: any) => setContactPersonEdit({ ...contactPersonEdit, email: e.target.value })}
													value={contactPersonEdit.email}
												/>{" "}
												<br />
												<TextField
													label="Puhelinnumero"
													multiline
													variant="standard"
													onChange={(e: any) => setContactPersonEdit({ ...contactPersonEdit, phoneNumber: e.target.value })}
													value={contactPersonEdit.phoneNumber}
												/>{" "}
												<br />
												<TextField
													label="Y-tunnus"
													multiline
													variant="standard"
													onChange={(e: any) => setContactPersonEdit({ ...contactPersonEdit, businessId: e.target.value })}
													value={contactPersonEdit.businessId}
												/>
											</Grid>

											<Grid item xs={12} sm={6}>
												<br />
												<TextField
													label="Postiosoite"
													multiline
													variant="standard"
													onChange={(e: any) => setContactPersonEdit({ ...contactPersonEdit, billingAddress: e.target.value })}
													value={contactPersonEdit.billingAddress}
												/>
												<br />
												<TextField
													label="Postinumero"
													multiline
													variant="standard"
													onChange={(e: any) => setContactPersonEdit({ ...contactPersonEdit, postalCode: e.target.value })}
													value={contactPersonEdit.postalCode}
												/>
												<br />
												<TextField
													label="Postitoimipaikka"
													multiline
													variant="standard"
													onChange={(e: any) => setContactPersonEdit({ ...contactPersonEdit, city: e.target.value })}
													value={contactPersonEdit.city}
												/>
												<br />

												<TextField
													label="Verkkolaskutusosoite"
													multiline
													variant="standard"
													onChange={(e: any) => setContactPersonEdit({ ...contactPersonEdit, eInvoiceAddress: e.target.value })}
													value={contactPersonEdit.eInvoiceAddress}
												/>
												<br />
												<TextField
													label="Operaattoritunnus"
													multiline
													variant="standard"
													onChange={(e: any) => setContactPersonEdit({ ...contactPersonEdit, eInvoiceOperator: e.target.value })}
													value={contactPersonEdit.eInvoiceOperator}
												/>

												<br />
											</Grid>
											<Grid item xs={12} sm={12}>
												<TextField
													label="Maa"
													onChange={(e: any) => setContactPersonEdit({ ...contactPersonEdit, countryCode: e.target.value })}
													value={contactPersonEdit.countryCode}
													variant="standard"
													multiline
													select
													SelectProps={{
														MenuProps: {
															style: {
																zIndex: 1305,
															},
														},
													}}
													fullWidth
													sx={{ maxWidth: "165px" }}
												>
													{allCountries.map((country: Country) => {
														return (
															<MenuItem key={country.countryCode.toString()} value={country.countryCode}>
																{country.country} - {country.countryCode}
															</MenuItem>
														)
													})}
												</TextField>
											</Grid>
										</Grid>
									</Box>
								</div>
							) : (
								<div>
									<Box sx={{ textAlign: "center" }}>
										<Grid container xs={12}>
											<Grid item xs={12} sm={6}>
												<br />
												<TextField
													label="Nimi"
													multiline
													variant="standard"
													onChange={(e: { target: { value: string } }) =>
														setContactPersonEdit({ ...contactPersonEdit, name: e.target.value })
													}
													value={contactPersonEdit.name}
												/>
												<br />
												<TextField
													label="Lisätieto"
													multiline
													variant="standard"
													onChange={(e: { target: { value: string } }) =>
														setContactPersonEdit({ ...contactPersonEdit, additionalInformation: e.target.value })
													}
													value={contactPersonEdit.additionalInformation}
												/>
												<br />
												<TextField
													label="Sähköposti"
													multiline
													variant="standard"
													onChange={(e: { target: { value: string } }) =>
														setContactPersonEdit({ ...contactPersonEdit, email: e.target.value })
													}
													value={contactPersonEdit.email}
												/>{" "}
												<br />
												<TextField
													label="Puhelinnumero"
													multiline
													variant="standard"
													onChange={(e: { target: { value: string } }) =>
														setContactPersonEdit({ ...contactPersonEdit, phoneNumber: e.target.value })
													}
													value={contactPersonEdit.phoneNumber}
												/>
											</Grid>

											<Grid item xs={12} sm={6}>
												<br />
												<TextField
													label="Postiosoite"
													multiline
													variant="standard"
													onChange={(e: { target: { value: string } }) =>
														setContactPersonEdit({ ...contactPersonEdit, billingAddress: e.target.value })
													}
													value={contactPersonEdit.billingAddress}
												/>
												<br />
												<TextField
													label="Postinumero"
													multiline
													variant="standard"
													onChange={(e: { target: { value: string } }) =>
														setContactPersonEdit({ ...contactPersonEdit, postalCode: e.target.value })
													}
													value={contactPersonEdit.postalCode}
												/>
												<br />
												<TextField
													label="Postitoimipaikka"
													multiline
													variant="standard"
													onChange={(e: any) => setContactPersonEdit({ ...contactPersonEdit, city: e.target.value })}
													value={contactPersonEdit.city}
												/>

												<br />
												<TextField
													select
													variant="standard"
													SelectProps={{
														MenuProps: {
															style: {
																zIndex: 1305,
															},
														},
													}}
													fullWidth
													multiline
													label="Maa"
													onChange={(e: any) => setContactPersonEdit({ ...contactPersonEdit, countryCode: e.target.value })}
													value={contactPersonEdit.countryCode}
												>
													{allCountries.map((country: Country) => {
														return (
															<MenuItem key={country.countryCode.toString()} value={country.countryCode}>
																{country.country} - {country.countryCode}
															</MenuItem>
														)
													})}
												</TextField>
											</Grid>
											<Grid item xs={12} sm={12}></Grid>
										</Grid>
									</Box>
								</div>
							)}
						</Grid>
					</Stack>
				</Stack>
			</Container>
			<CustomDialogActions>
				<Button variant="outlined" color="cancel" onClick={() => setOpenEditDialog(false)}>
					Sulje
				</Button>
				<Button variant="contained" onClick={handleEditContact}>
					Tallenna muutokset
				</Button>
			</CustomDialogActions>
		</>
	)
}
export default ContactEditDialog
