import React, { MutableRefObject, useEffect, useRef, useState } from "react"
import maplibregl, { LngLatBoundsLike, LngLatLike } from "maplibre-gl"
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css"

import "./Map.css"
import KipaLayersFilterBox from "./mapComponents/KipaLayersFilterBox"
import selectPropertiesFromMap from "./mapUtils/selectPropertiesFromMap"

import MapSearchBox from "./mapComponents/MapSearchBox"
import "react-toastify/dist/ReactToastify.css"
import mapClickFunction from "./mapUtils/getRoadByClick"
import loadKipaLayers from "./mapUtils/loadKipaLayers"
import getRoadLinkByClick from "./mapUtils/getRoadLinkByClick"
import searchPlaceByName from "./mapUtils/searchPlaceByName"
import { gql, useLazyQuery, useQuery } from "@apollo/client"
import { costCenterLinksVar, costCenterPropertyVar } from "../../network/apolloClient/apolloClientMakeVars"
import MapboxDraw from "@mapbox/mapbox-gl-draw"
import selectedRoadLinksValues from "./mapUtils/selectedRoadLinksValues"
import waitUntilMapLoaded from "./mapUtils/waitUntilMapLoaded"

import * as turf from "@turf/turf"
import { Typography } from "@mui/material"
import { Paper } from "@mui/material"
import selectedCostCentersLinks from "./mapUtils/selectedCostCentersLinks"
import { Dialog } from "@mui/material"
import { Button } from "@mui/material"
import { DialogActions } from "@mui/material"
import { DialogContent } from "@mui/material"
import { TextField } from "@mui/material"
import showPropertyInfoByClickFunction from "./mapUtils/showPropertyInfoByClickFunction"
import CustomDialogTitle from "../reusables/CustomDialogTitle"
import CustomDialogActions from "../reusables/CustomDialogActions"

import { IconButton } from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"
import MapPropertyInfoPopup from "./mapComponents/MapPropertyInfoPopoup"
import config from "../../utils/config"

import { MapLayerType, mapProviderStateType, MapStateProvider, useMapState } from "../../utils/providers/mapProvider/mapProvider"
import { MapComponent } from "./MapComponent"
import { FeatureCollection, Position } from "geojson"
import { kipaMapDefaultLayers } from "./mapUtils/kipaDefaultLayers"
import CreativeCommosForMap from "../reusables/CreativeCommonsForMap"
import addSearchPropertyLayer from "./mapUtils/addSearchPropertyLayer"

const costCenterLinksQuery = gql`
	query getCostCenterLinks {
		costCenterLinksVar @client
	}
`

const costCenterPropertyQuery = gql`
	query getCostCenterProperty {
		costCenterPropertyVar @client
	}
`

const GET_COST_CENTERS_ALL_GEO_JSON_DATA = gql`
	query GetCostCentersAllData {
		roadCooperativeWithJWT {
			costCentersByRoadCooperativeId {
				nodes {
					name
					idColor
					id
					roadLinksByCostCenterId {
						nodes {
							linkId
							linkLength
							coordinates {
								lat
								lng
							}
							id
						}
					}
					propertiesByCostCenterId {
						nodes {
							mmlPropertyId
							plotId
							propertyName
							id
							propertyTag
						}
					}
				}
			}
		}
	}
`

const mapMaxBounds: LngLatBoundsLike | undefined = [
	[11.5, 56.5], // Vasemman alakulman koordinaatit
	[38.5, 71], // Oikean yläkulman koordinaatit
]

interface MapComponentType {
	mapCenterPositionSetting?: {
		zoom: number
		centerPoint: { lat: number; lng: number }
	}
	costCenterData?: CostCenter
	showPropertyInfoByClick?: boolean
	selectedCostCenterId?: number // costCenterId
	mapFeatures?: {
		showPropertyInfoByClick?: boolean
		showProperties?: boolean
		showRoadLinks?: boolean
		editCostCenterData?: boolean
		createCostCenter?: boolean
		draw?: boolean
	}
	onDataChange?: () => void
}

const MapContainer = ({
	mapCenterPositionSetting = { centerPoint: { lat: 26.645, lng: 64.101 }, zoom: 8 },
	costCenterData,
	selectedCostCenterId,
	mapFeatures,
	onDataChange,
}: MapComponentType) => {
	const [initialDataLoaded, setInitialDataLoaded] = useState(false)

	const mapContainerRef: any = useRef(null)
	const map: MapInstance = useRef(null)

	const [getAllGeoJson] = useLazyQuery(GET_COST_CENTERS_ALL_GEO_JSON_DATA, {
		onCompleted(data) {
			// setInitialDataLoaded(true)
		},
	})

	const onDataChangeFunc = async () => {
		// refetchRCGeoJson()
		if (!map.current) return
		if (onDataChange) onDataChange()
	}

	const initialProviderState: mapProviderStateType | undefined = {
		costCenterData: costCenterData ?? undefined,
		eventListeners: [],
		allProperties: [],
		mapCenterPositionSetting: mapCenterPositionSetting,
		mapSources: [],
		selectedCostCenterId: selectedCostCenterId,
		mapLayers: kipaMapDefaultLayers,
		mapFeatures: {
			createCostCenter: mapFeatures?.createCostCenter ?? false,
			editCostCenterData: mapFeatures?.editCostCenterData ?? false,
			showProperties: mapFeatures?.showProperties ?? false,
			showPropertyInfoByClick: mapFeatures?.showPropertyInfoByClick ?? false,
			showRoadLinks: mapFeatures?.showRoadLinks ?? false,
			draw: mapFeatures?.draw ?? false,
		},
	}

	useEffect(() => {
		// MAP INITIAL LOADING EFFECT
		const func = async () => {
			const { data: geoJsonData } = await getAllGeoJson()
			map.current = new maplibregl.Map({
				container: mapContainerRef.current ?? "map",
				style: `https://avoin-karttakuva.maanmittauslaitos.fi/vectortiles/stylejson/v20/backgroundmap.json?TileMatrixSet=WGS84_Pseudo-Mercator&api-key=${config.MML_API_KEY}`,
				center: [mapCenterPositionSetting.centerPoint.lat, mapCenterPositionSetting.centerPoint.lng],
				zoom: mapCenterPositionSetting.zoom,
				maxBounds: mapMaxBounds,
			})

			map.current.doubleClickZoom.disable()
			map.current.on("load", () => {
				addSearchPropertyLayer(map)
				loadKipaLayers(map) // loading MML kiinteistöpalvelu layer

				map.current.setLayoutProperty("KiinteistotunnuksenSijaintitiedot", "visibility", "visible")
				map.current.setLayoutProperty("KiinteistorajanSijaintitiedot", "visibility", "visible")
				map.current.setLayoutProperty("PalstanSijaintitiedot", "visibility", "visible")
				if (geoJsonData?.roadCooperativeWithJWT.costCentersByRoadCooperativeId.nodes.length > 0) {
					addAllCostCentersRoadLinksSourceLayer(map, geoJsonData, mapFeatures?.showRoadLinks, selectedCostCenterId)
					showAllRoadCooperativeProperties(map, geoJsonData, mapFeatures?.showProperties, selectedCostCenterId)
				}
			})
			setInitialDataLoaded(true)
		}
		func()
		// clean up on unmount
		return () => map.current.remove()
	}, [])

	// useEffect(() => {
	// 	if (!initialDataLoaded) return
	// 	map.current.setCenter([mapCenterPositionSetting.centerPoint.lat, mapCenterPositionSetting.centerPoint.lng])
	// 	map.current.setZoom(mapCenterPositionSetting.zoom)
	// }, [mapCenterPositionSetting, initialDataLoaded])

	window.addEventListener("mousedown", function (e) {
		// Funktio estää käyttäjää painamasta hiiren keskimmäistä painiketta.
		// Tarkistetaan, onko painettu nappi keskinäppäin (2)
		if (e.which === 2) {
			e.preventDefault() // Estetään oletustoiminto
		}
	})

	return (
		<MapStateProvider initialState={initialProviderState}>
			<div
				className="map-container"
				id="map-container"
				onAuxClick={(e) => {
					e.preventDefault()
				}}
				onClick={void 0}
				style={{ overflow: "hidden" }}
			>
				<MapComponent onDataChange={onDataChangeFunc} map={map} selectedCostCenterId={selectedCostCenterId} />
				<div className="map" ref={mapContainerRef} id="map" />
				<CreativeCommosForMap />
			</div>
		</MapStateProvider>
	)
}

const showAllRoadCooperativeProperties = (map: MapInstance, RcGeoJsonData: RoadCooperative, showProperties?: boolean, selectedCostCenterId?: number) => {
	if (!showProperties) return
	if (RcGeoJsonData) {
		map.current.removeFeatureState({
			source: "kipa",
			sourceLayer: "PalstanSijaintitiedot",
		})
		for (let i = 0, len = RcGeoJsonData.roadCooperativeWithJWT.costCentersByRoadCooperativeId.nodes.length; len > i; i++) {
			const cc = RcGeoJsonData.roadCooperativeWithJWT.costCentersByRoadCooperativeId.nodes[i]
			if (selectedCostCenterId == cc.id || selectedCostCenterId === undefined) {
				for (let i2 = 0, len = cc.propertiesByCostCenterId?.nodes.length; len > i2; i2++) {
					map.current.setFeatureState(
						{ source: "kipa", sourceLayer: "PalstanSijaintitiedot", id: cc.propertiesByCostCenterId?.nodes[i2].plotId },
						{ hover: true }
					)
				}
			}
		}
	}
}

const addAllCostCentersRoadLinksSourceLayer = (map: any, RCGeoJson: RoadCooperative, showRoadLinks: boolean | undefined, selectedCostCenterId?: number) => {
	if (RCGeoJson === undefined) return

	const allCostCenters = []
	let bounds = null
	if (selectedCostCenterId === undefined) {
		const cc = RCGeoJson.roadCooperativeWithJWT.costCentersByRoadCooperativeId.nodes[0]

		bounds = new maplibregl.LngLatBounds(
			{ lat: cc.roadLinksByCostCenterId.nodes[0].coordinates[0].lng, lng: cc.roadLinksByCostCenterId.nodes[0].coordinates[0].lat },
			{ lat: cc.roadLinksByCostCenterId.nodes[0].coordinates[0].lng, lng: cc.roadLinksByCostCenterId.nodes[0].coordinates[0].lat }
		)
	}
	for (let i = 0, len = RCGeoJson.roadCooperativeWithJWT.costCentersByRoadCooperativeId.nodes.length; len > i; i++) {
		const cc = RCGeoJson.roadCooperativeWithJWT.costCentersByRoadCooperativeId.nodes[i]
		if (selectedCostCenterId == cc.id || selectedCostCenterId === undefined) {
			const costCenter = {
				...cc,
				allCoordinatePoints: [],
			}
			for (let i2 = 0, len = cc.roadLinksByCostCenterId?.nodes.length; len > i2; i2++) {
				const link = cc.roadLinksByCostCenterId?.nodes[i2]
				const linkCoordinateArray = []
				for (let i3 = 0, len = link.coordinates.length; len > i3; i3++) {
					const coordinates = link.coordinates[i3]
					bounds?.extend([coordinates.lat, coordinates.lng])
					linkCoordinateArray.push([coordinates.lat, coordinates.lng])
				}
				costCenter.allCoordinatePoints.push(linkCoordinateArray)
			}
			allCostCenters.push(costCenter)
		}
	}

	for (let i = 0, len = allCostCenters.length; len > i; i++) {
		const costCenter = allCostCenters[i]

		const geoJsonData: FeatureCollection = {
			type: "FeatureCollection",
			features: [
				{
					properties: {},
					type: "Feature",
					geometry: {
						type: "MultiLineString",
						coordinates: costCenter.allCoordinatePoints,
					},
				},
			],
		}

		map.current.addSource(`costCenterRoadLinks${costCenter.name + i}`, {
			type: "geojson",
			data: geoJsonData,
		})

		map.current.addLayer({
			id: `costCenterRoadLinks${costCenter.name + i}`,
			type: "line",
			source: `costCenterRoadLinks${costCenter.name + i}`,
			layout: {
				"line-join": "round",
				"line-cap": "round",
				visibility: showRoadLinks ? "visible" : "none",
			},
			paint: {
				"line-color": costCenter.idColor ?? "#505050",
				"line-width": 5,
			},
		})
	}

	map.current.fitBounds(bounds, {
		padding: 50,
	})
}

export { MapContainer }
