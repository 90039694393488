import { FeatureCollection, Position } from "geojson"
import waitUntilMapLoaded from "./waitUntilMapLoaded"

interface SelectedRoadLinksType {
	coordinates: [number[]]
	linkId?: string
	linkLength?: number
}

interface selectedCostCenter {
	roadLinks: SelectedRoadLinksType[]
	costCenterIdColor: string
	costCenterData: any
	name: string
}

const selectedCostCenterLinks = (map: any, roadLinks: any, color: string, name: string) => {
	if (!map.current) return
	if (!roadLinks) return
	//   console.log(roadLinks , ' -----------------  SHOW SELECTED COST CENTER ROAD LINKS ----------- ');

	setTimeout(() => {
		// console.log(roadLinks, name, color);

		const func = () => {
			const geoJsonData: FeatureCollection = {
				type: "FeatureCollection",
				features: [
					{
						properties: {},
						type: "Feature",
						geometry: {
							type: "MultiLineString",
							coordinates: roadLinks.map((roadLink: { coordinates: Position }) => {
								return roadLink.coordinates
							}),
						},
					},
				],
			}

			if (map.current.getLayer(`costCenterRoadLinks${name}`)) {
				map.current.removeLayer(`costCenterRoadLinks${name}`)
			}

			if (map.current.getSource(`costCenterRoadLinks${name}`)) {
				map.current.removeSource(`costCenterRoadLinks${name}`)
			}

			map.current.addSource(`costCenterRoadLinks${name}`, {
				type: "geojson",
				data: geoJsonData,
			})

			map.current.addLayer({
				id: `costCenterRoadLinks${name}`,
				type: "line",
				source: `costCenterRoadLinks${name}`,
				layout: {
					"line-join": "round",
					"line-cap": "round",
				},
				paint: {
					"line-color": color,
					"line-width": 5,
				},
			})
		}

		waitUntilMapLoaded(map, func, null, 50)

		// })
	}, 100)
}

export default selectedCostCenterLinks
