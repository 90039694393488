import React, { createContext, useContext, useMemo, useReducer } from "react"

type Action =
	| { type: "setRoadCooperativeName"; payload: string }
	| { type: "setActiveStep"; payload: number }
	| { type: "setPlanStartDate"; payload: string }
	| { type: "setPlanEndDate"; payload: string }
	| { type: "setPlanContent"; payload: "financialEstimate" | "paymentCatalog" | "both" }
	| { type: "setComparisonStartDate"; payload: string }
	| { type: "setComparisonEndDate"; payload: string }
	| { type: "setEstimateMultiplier"; payload: number }
	| { type: "setInvoicingBatches"; payload: number }
	| { type: "setEstimatedExpenses"; payload: FLFinancialEstimate[] }
	| { type: "setComparisonExpensesTotal"; payload: number }
	| { type: "setCurrentExpensesTotal"; payload: number }
	| { type: "setEstimatedIncomes"; payload: FLFinancialEstimate[] }
	| { type: "setComparisonIncomesTotal"; payload: number }
	| { type: "setCurrentIncomesTotal"; payload: number }
	| { type: "setRoadFees"; payload: FLFinancialEstimate }
	| { type: "setBasicFee"; payload: number }
	| { type: "setBasicFeePerMember"; payload: number }
	| { type: "setBankAccountBalanceOnDate"; payload: number }
	| { type: "setTotalUnits"; payload: FLPaymentCatalogUnits }
	| { type: "setContactPerson"; payload: PaymentCatalogContactPerson }
	| { type: "setMembersWithEstablishments"; payload: FLMemberWithEstablishments[] }
	| { type: "setPaymentCatalogDescription"; payload: string }
	| { type: "setPaymentCatalogDueDate"; payload: string }
	| { type: "setDefaultsExceptBasicInformation"; payload: null }
	| { type: "setDefaults"; payload: null }

type Dispatch = (action: Action) => void

type State = {
	roadCooperativeName: string
	activeStep: number
	previousStep: number
	planStartDate: string
	planEndDate: string
	planContent: "financialEstimate" | "paymentCatalog" | "both"
	comparisonStartDate: string
	comparisonEndDate: string
	estimateMultiplier: number
	invoicingBatches: number
	estimatedExpenses: FLFinancialEstimate[]
	comparisonExpensesTotal: number
	currentExpensesTotal: number
	estimatedIncomes: FLFinancialEstimate[]
	comparisonIncomesTotal: number
	currentIncomesTotal: number
	roadFees: FLFinancialEstimate
	basicFee: number
	basicFeePerMember: number
	bankAccountBalanceOnDate: number
	totalUnits: FLPaymentCatalogUnits
	contactPerson: PaymentCatalogContactPerson
	membersWithEstablishments: FLMemberWithEstablishments[]
	paymentCatalogDescription: string
	paymentCatalogDueDate: string
}

const DEFAULT_STATE: State = {
	roadCooperativeName: "",
	activeStep: 0,
	previousStep: 0,
	planStartDate: "",
	planEndDate: "",
	planContent: "both",
	comparisonStartDate: "",
	comparisonEndDate: "",
	estimateMultiplier: 1,
	invoicingBatches: 1,
	estimatedExpenses: [],
	estimatedIncomes: [],
	comparisonExpensesTotal: 0,
	currentExpensesTotal: 0,
	comparisonIncomesTotal: 0,
	currentIncomesTotal: 0,
	roadFees: { tempId: 0, categoryName: "Tiemaksut", comparisonValue: 0, currentValue: 0 },
	basicFee: 0,
	basicFeePerMember: 0,
	bankAccountBalanceOnDate: 0,
	totalUnits: { totalUnits: 0, costByUnit: 0, totalUnitsCost: 0 },
	contactPerson: { name: "", email: "" },
	membersWithEstablishments: [],
	paymentCatalogDescription: "",
	paymentCatalogDueDate: "",
}

const DEFAULTS_FOR_OTHERS_THAN_BASIC_INFORMATION = {
	invoicingBatches: 1,
	estimatedExpenses: [],
	estimatedIncomes: [],
	comparisonExpensesTotal: 0,
	currentExpensesTotal: 0,
	comparisonIncomesTotal: 0,
	currentIncomesTotal: 0,
	roadFees: { tempId: 0, categoryName: "Tiemaksut", comparisonValue: 0, currentValue: 0 },
	basicFee: 0,
	basicFeePerMember: 0,
	bankAccountBalanceOnDate: 0,
	totalUnits: { totalUnits: 0, costByUnit: 0, totalUnitsCost: 0 },
	membersWithEstablishments: [],
	paymentCatalogDescription: "",
	paymentCatalogDueDate: "",
}

const createFLFinancesPlanningReducer = (state: State, action: Action): State => {
	switch (action.type) {
		case "setRoadCooperativeName":
			return { ...state, roadCooperativeName: action.payload }
		case "setActiveStep":
			return { ...state, previousStep: state.activeStep, activeStep: action.payload }
		case "setPlanStartDate":
			return { ...state, planStartDate: action.payload }
		case "setPlanEndDate":
			return { ...state, planEndDate: action.payload }
		case "setPlanContent":
			return { ...state, planContent: action.payload }
		case "setComparisonStartDate":
			return { ...state, comparisonStartDate: action.payload }
		case "setComparisonEndDate":
			return { ...state, comparisonEndDate: action.payload }
		case "setEstimateMultiplier":
			return { ...state, estimateMultiplier: action.payload }
		case "setInvoicingBatches":
			return { ...state, invoicingBatches: action.payload }
		case "setEstimatedExpenses":
			return { ...state, estimatedExpenses: action.payload }
		case "setComparisonExpensesTotal":
			return { ...state, comparisonExpensesTotal: action.payload }
		case "setCurrentExpensesTotal":
			return { ...state, currentExpensesTotal: action.payload }
		case "setEstimatedIncomes":
			return { ...state, estimatedIncomes: action.payload }
		case "setComparisonIncomesTotal":
			return { ...state, comparisonIncomesTotal: action.payload }
		case "setCurrentIncomesTotal":
			return { ...state, currentIncomesTotal: action.payload }
		case "setRoadFees":
			return { ...state, roadFees: action.payload }
		case "setBasicFee":
			return { ...state, basicFee: action.payload }
		case "setBasicFeePerMember":
			return { ...state, basicFeePerMember: action.payload }
		case "setBankAccountBalanceOnDate":
			return { ...state, bankAccountBalanceOnDate: action.payload }
		case "setTotalUnits":
			return { ...state, totalUnits: action.payload }
		case "setContactPerson":
			return { ...state, contactPerson: action.payload }
		case "setMembersWithEstablishments":
			return { ...state, membersWithEstablishments: action.payload }
		case "setPaymentCatalogDescription":
			return { ...state, paymentCatalogDescription: action.payload }
		case "setPaymentCatalogDueDate":
			return { ...state, paymentCatalogDueDate: action.payload }
		case "setDefaultsExceptBasicInformation":
			return { ...state, ...DEFAULTS_FOR_OTHERS_THAN_BASIC_INFORMATION }
		case "setDefaults":
			return { ...DEFAULT_STATE }
	}
}

type CreateFLFinancesPlanningProviderProps = { children: React.ReactNode }

const CreateFLFinancesPlanningStateContext = createContext<{ state: State; dispatch: Dispatch } | undefined>(undefined)

const CreateFLFinancesPlanningStateProvider = ({ children }: CreateFLFinancesPlanningProviderProps) => {
	const memoChildren = useMemo(() => children, [])
	const [state, dispatch] = useReducer(createFLFinancesPlanningReducer, DEFAULT_STATE)
	const value = { state, dispatch }
	return <CreateFLFinancesPlanningStateContext.Provider value={value}>{memoChildren}</CreateFLFinancesPlanningStateContext.Provider>
}

const useCreateFLFinancesPlanningState = () => {
	const context = useContext(CreateFLFinancesPlanningStateContext)
	if (context === undefined) {
		throw new Error("useCreateFLFinancesPlanningState hook must be used within a CreateFLFinancesPlanningStateProvider")
	}
	return context
}

export { CreateFLFinancesPlanningStateProvider, useCreateFLFinancesPlanningState }
export type { State, Dispatch }
