import isMobile from "ismobilejs"
import maplibregl, { MapTouchEvent } from "maplibre-gl"
import "../Map.css"
import { MapProviderDispatch } from "../../../utils/providers/mapProvider/mapProvider"
import * as turf from "@turf/turf"
import axios from "axios"
import config from "../../../utils/config"
import { toast } from "react-toastify"
import userProfileStorage from "../../../utils/providers/userProfileProvider/userProfileProvider"

const showPropertyInfoByClickFunction = (map: MapInstance, allPropertiesData: any, dispatch: MapProviderDispatch, ref: any, setOff: boolean) => {
	let allProperties: CustomPropertyType[] = []
	if (allPropertiesData?.roadCooperativeWithJWT) {
		for (let i = 0; allPropertiesData.roadCooperativeWithJWT.costCentersByRoadCooperativeId.nodes.length > i; i++) {
			allProperties = allProperties.concat(
				allPropertiesData.roadCooperativeWithJWT.costCentersByRoadCooperativeId.nodes[i].propertiesByCostCenterId.nodes
			)
		}
	}

	let latLng: [number, number] = [0, 0]
	let propertyPlotId: any = null
	let clickedPropertyData: CustomPropertyType[] = []
	let propertyDialogObj: ProperyDialogDataType

	const func = async (e: any) => {
		clickedPropertyData = []
		const mmlPropertyId = e.features[0].properties.kiinteistotunnuksenEsitysmuoto
		latLng = [e.lngLat.lng, e.lngLat.lat]
		const eventPlotId = e.features[0].id
		const eventFeature = e.features[0]

		propertyDialogObj = {
			open: true,
			data: clickedPropertyData,
			mmlPropertyId: mmlPropertyId,
			plotId: e.features[0].properties.id,
		}

		if (isMobile().any) {
			let timer: NodeJS.Timer

			map.current.on("touchstart", "PalstanSijaintitiedot", (e: MapTouchEvent) => {
				if (e.originalEvent.touches.length > 1) {
					return
				}

				timer = setTimeout(() => {
					// console.log(geoJsonData , " ----- geojson data ---- ");
				}, 500)
			})

			map.current.on("touchend", () => clearTimeout(timer))
			map.current.on("touchcancel", () => clearTimeout(timer))
			map.current.on("touchmove", () => clearTimeout(timer))
			map.current.on("pointerdrag", () => clearTimeout(timer))
			map.current.on("pointermove", () => clearTimeout(timer))
			map.current.on("moveend", () => clearTimeout(timer))
			map.current.on("gesturestart", () => clearTimeout(timer))
			map.current.on("gesturechange", () => clearTimeout(timer))
			map.current.on("gestureend", () => clearTimeout(timer))
		} else {
			if (propertyPlotId != null) {
				map.current.setFeatureState({ source: "kipa", sourceLayer: "PalstanSijaintitiedot", id: propertyPlotId }, { selected: false })
				propertyPlotId = null
				dispatch({ type: "setActivePropertyPlotId", payload: undefined })
				return
			}

			propertyPlotId = eventPlotId
			dispatch({ type: "setActivePropertyPlotId", payload: propertyPlotId })

			map.current.setFeatureState({ source: "kipa", sourceLayer: "PalstanSijaintitiedot", id: propertyPlotId }, { selected: true })
			for (let i = 0; allProperties.length > i; i++) {
				if (allProperties[i].plotId == eventFeature.properties.id) {
					let allRoadUnits = 0
					const allMembers: RoadCooperativeMemberType[] = []
					for (let i2 = 0; allProperties[i].establishmentsByPropertyId.nodes.length > i2; i2++) {
						allRoadUnits = allRoadUnits + allProperties[i].establishmentsByPropertyId.nodes[i2].roadUnits
						if (
							!allMembers.some(
								(member) =>
									member.name === allProperties[i].establishmentsByPropertyId.nodes[i2].roadCooperativeMemberByRoadCooperativeMemberId.name
							)
						) {
							allMembers.push(allProperties[i].establishmentsByPropertyId.nodes[i2].roadCooperativeMemberByRoadCooperativeMemberId)
						}
					}
					clickedPropertyData = clickedPropertyData.concat({ ...allProperties[i], allRoadUnits: allRoadUnits, allMembers: allMembers })
				}
			}

			propertyDialogObj.data = clickedPropertyData
			const popupHtmlElement: any = await getPopupHtmlElement(clickedPropertyData, map, e, propertyPlotId, mmlPropertyId, propertyDialogObj)

			// Lisätään popuppi kartalle
			const popup = new maplibregl.Popup({ className: "map-property-info-popup" }).setLngLat(latLng).setHTML(popupHtmlElement).addTo(map.current)
			dispatch({ type: "setCurrentPopup", payload: popup })

			const handleButton = () => {
				// Lisätään kiinteistön data mapProvideriin jota käytetään MapPropertyInfoPopup komponentissa
				dispatch({ type: "setPropertyDialogData", payload: propertyDialogObj })
			}

			const handlePropertyTitleClick = () => {
				navigator.clipboard.writeText(`${mmlPropertyId}`).then(
					() => {
						toast.success(`Kiinteistö tunnus "${mmlPropertyId}" kopioitu leikepöydälle`, { autoClose: 2000 })
					},
					() => {
						toast.error("", { role: "global" })
					}
				)
			}

			document.getElementById(`property-button${propertyPlotId}`)?.addEventListener("click", handleButton)
			document.getElementById(`property-popup-${mmlPropertyId}`)?.addEventListener("click", handlePropertyTitleClick)
			document.getElementsByClassName("maplibregl-popup-close-button")[0].addEventListener("click", () => {
				map.current.setFeatureState({ source: "kipa", sourceLayer: "PalstanSijaintitiedot", id: propertyPlotId }, { selected: false })
				propertyPlotId = null
			})
		}
	}

	if (setOff) {
		map.current.off("click", "PalstanSijaintitiedot", ref.current)
	} else {
		if (ref.current) {
			map.current.off("click", "PalstanSijaintitiedot", ref.current)
		}
		ref.current = func
		map.current.on("click", "PalstanSijaintitiedot", ref.current)
	}
}

const getPopupHtmlElement = async (
	clickedPropertyData: CustomPropertyType[],
	map: MapInstance,
	e: any,
	propertyPlotId: any,
	mmlPropertyId: any,
	propertyDialogObj: ProperyDialogDataType
) => {
	const [displayArea, unit]: any = await getPlotArea(map, e)
	const permission = userProfileStorage({ type: "getActiveRoadCooperative" }).permission

	let popupHtmlElement: any = "<h2>Kiinteistöstä ei ole dataa</h2>"

	if (clickedPropertyData.length != 0) {
		// Kun kiinteistö kuuluu tiekuntaan
		popupHtmlElement = `
			<div class='map-property-info-popup-div' id='map-property-info-popup' >
			<p class='map-property-info-popup-title' id='property-popup-${mmlPropertyId}' >${mmlPropertyId}  </p>
			<p class='small-font'>Pinta-ala: ${displayArea.toFixed(2)} ${unit} </p>
			  <div class='map-property-info-popup-content' >
				${clickedPropertyData.map((property, index: number) => {
					return `
					<div id=${index} class='bottom-border' >
					  <p class='map-property-info-popup-title' > ${property.costCenterByCostCenterId.name}</p>
					  <p>Yksiköt: ${property.allRoadUnits} kpl</p>
					  <p>Osakkaat: </p>
						<ul>
						${
							property.allMembers == undefined
								? 0
								: property.allMembers.map((member: any) => {
										return `<li>${member.name} </li>`
								  })
						}
						</ul>
					</div>
					`
				})}
			  </div>
			  <button id=property-button${propertyPlotId} class="map-property-info-popup-button" >Näytä lisää</button>
			</div>`
		popupHtmlElement.replace(",", "")
	} else {
		// Mikäli kiinteistä ei kuulu tiekuntaan
		propertyDialogObj.addProperty = true
		popupHtmlElement = `
			   <div class='map-property-info-popup-div text-center' id='map-property-info-popup' >
			  <p class='map-property-info-popup-title'>${mmlPropertyId}  </p>
			  <p class='small-font'>Pinta-ala: ${displayArea.toFixed(2)} ${unit} </p>

				${permission === "edit" ? `<button id=property-button${propertyPlotId} class="map-property-info-popup-button" >Lisää tiekuntaan</button>` : ""}
			  </div>
			`
	}

	return popupHtmlElement
}

const getPlotArea = async (map: MapInstance, e: any): Promise<any> => {
	// Kaikki featured yhdestä koordinaatti pisteestä
	const eventFeatures = map.current.queryRenderedFeatures(e.point)
	// Halutun featuren ID
	const targetId = eventFeatures.find((feature: any) => feature.layer.id == "PalstanSijaintitiedot").id

	let propertyMMLCoordinates = []
	try {
		const propertyDataFromMML = await axios({
			url: `https://avoin-paikkatieto.maanmittauslaitos.fi/kiinteisto-avoin/simple-features/v3/collections/PalstanSijaintitiedot/items/${targetId}?api-key=${config.MML_API_KEY}`,
			method: "GET",
		})
		propertyMMLCoordinates = propertyDataFromMML.data.geometry.coordinates
	} catch (error) {
		console.error(error)
	}

	let wholeArea = 0

	const polygon = turf.polygon(propertyMMLCoordinates)
	const area = turf.area(polygon)
	wholeArea = area

	// Muuta pinta-ala sopivaan yksikköön
	let unit = "m²"
	let displayArea = wholeArea
	if (wholeArea >= 1000 * 10000) {
		unit = "km²"
		displayArea = wholeArea / (1000 * 1000)
	} else if (wholeArea >= 1000) {
		unit = "ha"
		displayArea = wholeArea / 10000
	}

	return [displayArea, unit]
}

export default showPropertyInfoByClickFunction
