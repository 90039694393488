import { MapLayerType } from "../../../utils/providers/mapProvider/mapProvider"

export const kipaMapDefaultLayers: MapLayerType[] = [
	{
		type: "symbol",
		userCanToggle: true,
		allowOpacityChange: false,
		displayName: "Kiinteistötunnuksen sijaintitieto",
		name: "KiinteistotunnuksenSijaintitiedot",
		visibility: "visible",
		opacity: 1,
		featured: true,
	},
	{
		type: "line",
		userCanToggle: true,
		allowOpacityChange: true,
		displayName: "Kiinteistörajan sijaintitieto",
		name: "KiinteistorajanSijaintitiedot",
		visibility: "visible",
		opacity: 1,
		featured: true,
	},
	{
		type: "fill",
		userCanToggle: false,
		allowOpacityChange: false,
		displayName: "Palstan sijaintitieto",
		name: "PalstanSijaintitiedot",
		visibility: "visible",
		opacity: 1,
		featured: true,
	},
	{
		type: "circle",
		userCanToggle: true,
		allowOpacityChange: false,
		displayName: "Rajamerkin sijaintitieto",
		name: "RajamerkinSijaintitiedot",
		visibility: "none",
		opacity: 100,
		featured: true,
	},
	{
		type: "symbol",
		userCanToggle: true,
		allowOpacityChange: false,
		displayName: "Rajamerkin sijaintitieto tunnus",
		name: "RajamerkinSijaintitiedot_tunnus",
		visibility: "none",
		opacity: 1,
		featured: true,
	},
]
