export const mapBoxDrawStyles = [
	// ACTIVE (being drawn)
	// points
	{
		id: "gl-draw-point",
		type: "circle",
		filter: ["all", ["==", "meta", "feature"], ["==", "$type", "Point"], ["!=", "mode", "static"]],
		paint: {
			"circle-radius": 1,
			"circle-color": "#FFF",
			"circle-opacity": 0,
		},
	},
	// line stroke
	{
		id: "gl-draw-line",
		type: "line",
		filter: ["all", ["==", "$type", "LineString"], ["!=", "mode", "static"]],
		layout: {
			"line-cap": "round",
			"line-join": "round",
		},
		paint: {
			"line-color": "#07bc0c",
			// "line-dasharray": [0.2, 2],
			"line-width": 3,
		},
	},
	// polygon fill
	{
		id: "gl-draw-polygon-fill",
		type: "fill",
		filter: ["all", ["==", "$type", "Polygon"], ["!=", "mode", "static"]],
		paint: {
			"fill-color": "#07bc0c",
			"fill-outline-color": "#07bc0c",
			"fill-opacity": 0.1,
		},
	},
	// polygon mid points
	{
		id: "gl-draw-polygon-midpoint",
		type: "circle",
		filter: ["all", ["==", "$type", "Point"], ["==", "meta", "midpoint"]],
		paint: {
			"circle-radius": 8,
			"circle-color": "#fbb03b",
		},
	},
	// polygon outline stroke
	// This doesn't style the first edge of the polygon, which uses the line stroke styling instead
	{
		id: "gl-draw-polygon-stroke-active",
		type: "line",
		filter: ["all", ["==", "$type", "Polygon"], ["!=", "mode", "static"]],
		layout: {
			"line-cap": "round",
			"line-join": "round",
		},
		paint: {
			"line-color": "#07bc0c",
			// "line-dasharray": [0.2, 2],
			"line-width": 3,
		},
	},
	// vertex point halos
	{
		id: "gl-draw-polygon-and-line-vertex-halo-active",
		type: "circle",
		filter: ["all", ["==", "meta", "vertex"], ["==", "$type", "Point"], ["!=", "mode", "static"]],
		paint: {
			"circle-radius": 8,
			"circle-color": "#FFF",
		},
	},
	// vertex points
	{
		id: "gl-draw-polygon-and-line-vertex-active",
		type: "circle",
		filter: ["all", ["==", "meta", "vertex"], ["==", "$type", "Point"], ["!=", "mode", "static"]],
		paint: {
			"circle-radius": 6,
			"circle-color": "#D20C0C",
		},
	},

	// INACTIVE (static, already drawn)
	// line stroke
	{
		id: "gl-draw-line-static",
		type: "line",
		filter: ["all", ["==", "$type", "LineString"], ["==", "mode", "static"]],
		layout: {
			"line-cap": "round",
			"line-join": "round",
		},
		paint: {
			"line-color": "#fead2d",
			"line-width": 3,
		},
	},
	// polygon fill
	{
		id: "gl-draw-polygon-fill-static",
		type: "fill",
		filter: ["all", ["==", "$type", "Polygon"], ["==", "mode", "static"]],
		paint: {
			"fill-color": "#fead2d",
			"fill-outline-color": "#000",
			"fill-opacity": 0.1,
		},
	},
	// polygon outline
	{
		id: "gl-draw-polygon-stroke-static",
		type: "line",
		filter: ["all", ["==", "$type", "Polygon"], ["==", "mode", "static"]],
		layout: {
			"line-cap": "round",
			"line-join": "round",
		},
		paint: {
			"line-color": "#fead2d",
			"line-width": 3,
		},
	},
]
