import React from "react"
import { useEffect, useState } from "react"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp"
import { IconButton } from "@mui/material"
import { Checkbox } from "@mui/material"
import { TableCell } from "@mui/material"
import { TableRow } from "@mui/material"
import EditIcon from "@mui/icons-material/Edit"
import { Box } from "@mui/material"
import { Grid } from "@mui/material"
import { Collapse } from "@mui/material"

const TasksTableRow = ({ task, openUpdateTaskDialog, handleCheckBox }: any) => {
	const [open, setOpen] = useState(false)

	const cellStyles = {
		cellPadding: {
			paddingTop: "10px",
			paddingBottom: "5px",
			paddingRight: "5px",
			paddingLeft: "5px",
		},
	}

	return (
		<>
			<React.Fragment key={"tasks-" + task.id}>
				<TableRow>
					<TableCell
						sx={{
							width: "13%",
							borderBottom: 0,
							borderTop: 1,
							borderTopColor: "#e0e0e0",
							paddingTop: "0px",
							paddingBottom: "0px",
							paddingRight: "0px",
							paddingLeft: "5px",
						}}
					>
						<Checkbox onClick={() => handleCheckBox(task.id)} checked={task.completed} />
					</TableCell>
					<TableCell
						sx={{
							width: "1%",
							borderBottom: 0,
							borderTop: 1,
							borderTopColor: "#e0e0e0",
							paddingTop: "0px",
							paddingBottom: "0px",
							paddingRight: "5px",
							paddingLeft: "0px",
						}}
						open={TasksTableRow}
					>
						<IconButton size="small" onClick={() => setOpen(!open)}>
							{open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
						</IconButton>
					</TableCell>
					<TableCell sx={{ width: "36%", borderBottom: 0, borderTop: 1, borderTopColor: "#e0e0e0", ...cellStyles.cellPadding }}>
						{task.title}
					</TableCell>
					<TableCell sx={{ width: "25%", borderBottom: 0, borderTop: 1, borderTopColor: "#e0e0e0", ...cellStyles.cellPadding }}>
						{task.roadCooperativeName}
					</TableCell>
					<TableCell sx={{ width: "21%", borderBottom: 0, borderTop: 1, borderTopColor: "#e0e0e0", ...cellStyles.cellPadding }}>
						{task.dateTime == "" ? "" : new Date(task.dateTime).toLocaleString("fi-FI", { dateStyle: "short", timeStyle: "short" })}
					</TableCell>
					<TableCell sx={{ width: "4%", borderBottom: 0, borderTop: 1, borderTopColor: "#e0e0e0", ...cellStyles.cellPadding }}>
						<IconButton
							onClick={() => {
								openUpdateTaskDialog(task)
							}}
						>
							<EditIcon sx={{ width: "20px", color: "rgba(0, 0, 0, 0.54)" }} />
						</IconButton>
					</TableCell>
				</TableRow>

				<TableRow>
					<TableCell colSpan={6} sx={{ padding: 0, margin: 0, borderBottom: 0 }}>
						<Collapse in={open} unmountOnExit>
							{!task ? null : (
								<>
									<Grid container direction="row" justifyContent="center" alignItems="flex-start" padding="10px">
										<Grid item xs sx={{ minWidth: "130px", maxWidth: "130px", overflow: "hidden" }}>
											<Box component="div" sx={{}}>
												{task.content}
											</Box>
										</Grid>
									</Grid>
								</>
							)}
						</Collapse>
					</TableCell>
				</TableRow>
			</React.Fragment>
		</>
	)
}

export default TasksTableRow
