import React from "react"
import { Grid, List, ListItem, Paper } from "@mui/material"
import Comment from "./Comment"
import { CommentProps } from "../Map/ChatPopUp"

// Määrittele tyyppi komponentin propseille
interface AllCommentProps {
	comments: CommentProps[]

	userEmail: string
}

const CommentsInChat: React.FC<AllCommentProps> = ({ comments, userEmail }) => {
	return (
		<Grid container spacing={2} style={{ padding: "0px" }}>
			<Grid item xs={12}>
				<List style={{ paddingBottom: "3px" }}>
					{comments.map((comment: any, index: number) => (
						<ListItem
							key={index}
							sx={{
								"@media screen and (max-width: 800px)": {
									width: "80%",
								},
								width: "35%",
								minWidth: "250px",
								overflow: "hidden",
								fontSize: "1rem",
								lineHeight: "1.5",
								paddingTop: "2px",
								position: "relative",
								left: comment.userEmail !== userEmail ? 0 : "30px",
								right: comment.userEmail === userEmail ? 0 : "30px",
							}}
						>
							<Paper style={{ padding: "0px", width: "100%", height: "calc(100%-30px)", boxShadow: "5px 5px 3px rgba(0, 0, 0, 0.1)" }}>
								<Comment comment={comment} userEmailFromLocal={userEmail} />
							</Paper>
						</ListItem>
					))}
				</List>
			</Grid>
		</Grid>
	)
}

export default CommentsInChat
