export const checkRCParameters = (roadCooperative: any, setRCAlert: any) => {
	let alertText = ""
	if (roadCooperative.RCName == "") {
		alertText = alertText + "Tiekunnan nimi puuttuu. "
	}
	if (roadCooperative.RCRole == "") {
		alertText = alertText + "Roolisi tiekunnassa puuttuu. "
	}
	if (roadCooperative.municipality == "") {
		alertText = alertText + "Tiekunnan kunta tieto puuttuu. "
	}

	if (alertText != "") {
		setRCAlert(alertText)
		setTimeout(() => {
			setRCAlert("")
		}, 10000)
		return false
	}
	return true
}

export const checkMemberParameters = (roadCooperative: any, setAlert: any) => {
	let alertText = ""
	if (roadCooperative.memberType === "yksityishenkilö") {
		if (roadCooperative.name == "") {
			alertText = alertText + "Nimi puuttuu. "
		}
	} else {
		if (roadCooperative.name == "") {
			alertText = alertText + "Yrityksen nimi puuttuu. "
		}
		if (roadCooperative.businessId == "") {
			alertText = alertText + "Y-tunnus puuttuu. "
		}
		if (roadCooperative.invoiceSendingType == 3) {
			if (roadCooperative.eInvoiceAddress == "") {
				alertText = alertText + "Verkkolaskuosoite puuttuu. "
			}
			if (roadCooperative.eInvoiceOperator == "") {
				alertText = alertText + "Operaattoritunnus puuttuu. "
			}
		}
	}
	if (roadCooperative.address == "") {
		alertText = alertText + "Postiosoite puuttuu. "
	}
	if (roadCooperative.postalDistrict == "") {
		alertText = alertText + "Postitoimipaikka puuttuu. "
	}
	if (roadCooperative.postalCode == "") {
		alertText = alertText + "Postinumero puttuu. "
	}
	if (roadCooperative.countryCode == "") {
		alertText = alertText + "Maa puuttuu. "
	}
	// if (roadCooperative.languageCode == "") {
	// 	alertText = alertText + "Kieli puuttuu. "
	// }
	if (roadCooperative.invoiceSendingType == "") {
		alertText = alertText + "Laskun toimitustapa puuttuu. "
	}
	if (roadCooperative.meetingInviteType == "") {
		alertText = alertText + "Kokouskutsun toimitustapa puuttuu. "
	}
	if (alertText != "") {
		setAlert(alertText)
		setTimeout(() => {
			setAlert("")
		}, 10000)
		return false
	}
	return true
}
