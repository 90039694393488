import React, { useState, useEffect } from "react"
import { IconButton, Paper, Grid, TextField } from "@mui/material"
import { RoadCooperativeInfo, ChatProps } from "../Map/ChatPopUp"
import SendIcon from "@mui/icons-material/Send"
import { toast } from "react-toastify"

import { gql, useMutation } from "@apollo/client"
import { Tooltip } from "@mui/material"

const CREATE_NEW_RC_CHAT = gql`
	mutation addNewChat($chatUser: String = "", $content: String = "", $roadCooperativeId: UUID, $title: String = "", $userEmail: String = "") {
		createChat(input: { chat: { chatUser: $chatUser, content: $content, roadCooperativeId: $roadCooperativeId, title: $title, userEmail: $userEmail } }) {
			chat {
				chatUser
				content
				id
				createdOn
				roadCooperativeId
				title
				userEmail
			}
		}
	}
`

interface AllNewChatsProps {
	profileInfo: any
	roadCooperativeInfo: RoadCooperativeInfo
	setChats: React.Dispatch<React.SetStateAction<ChatProps[]>>
	chats: ChatProps[]
	refetch: () => void
	setLockChat: React.Dispatch<React.SetStateAction<boolean>>
	lockChat: boolean
	toggleChatPopUp: () => void
}

const NewChat: React.FC<AllNewChatsProps> = ({ profileInfo, roadCooperativeInfo, setChats, chats, refetch, setLockChat, lockChat, toggleChatPopUp }) => {
	const [newChat, setNewChat] = useState("")
	const [newTitle, setNewTitle] = useState("")
	const [roadCooperativeId, setRoadCooperativeId] = useState("")
	const [buttonColor, setButtonColor] = useState("gray")
	const [isText, setIsText] = useState(false)

	const [addNewChat, { data, loading, error }] = useMutation(CREATE_NEW_RC_CHAT, {
		onCompleted(data) {
			setChats((prevChats) => [...prevChats, data.createChat.chat])
		},
		onError(error) {
			console.error("Error adding chat:", error)
		},
	})

	const handleChatChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setNewChat(event.target.value)
	}
	const handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setNewTitle(event.target.value)
	}

	const setAllStatesEmpty = () => {
		setNewChat("")
		setNewTitle("")
	}

	// const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
	// 	if (event.key === "Enter") {
	// 		event.preventDefault()
	// 		setNewChat((prev) => prev + "\n")
	// 	}
	// }
	// const handleTitleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
	// 	if (event.key === "Enter") {
	// 		event.preventDefault()
	// 		setNewTitle((prev) => prev + "\n")
	// 	}
	// }

	const handleSaveChat = async (chatId: string) => {
		if (newChat !== "" && newChat && newTitle !== "" && newTitle) {
			const chatToSave: ChatProps = {
				chatUser: profileInfo.name,
				content: newChat,
				roadCooperativeId: roadCooperativeId,
				title: newTitle,
				userEmail: profileInfo.email,
			}
			await addNewChat({ variables: chatToSave })
			setAllStatesEmpty()
		} else {
			toast.warning("Täytä tekstikenttä")
		}
		refetch()
	}

	useEffect(() => {
		if (roadCooperativeInfo) {
			setRoadCooperativeId(roadCooperativeInfo.id)
		}
	}, [])

	useEffect(() => {
		if (newChat !== "" && newChat && newTitle !== "" && newTitle) {
			setIsText(true)
			setButtonColor("primary")
		} else {
			setIsText(false)
			setButtonColor("g ray")
		}
	}, [newChat, newTitle])

	useEffect(() => {
		toggleChatPopUp()
	}, [lockChat])

	return (
		<Grid sx={{ padding: "28px", paddingBottom: "0px", paddingTop: "0px", paddingLeft: "10px" }}>
			<Paper style={{ padding: "10px", width: "100%", left: "5px" }}>
				<TextField
					id="title"
					size="small"
					placeholder="Otsikko"
					value={newTitle}
					// onKeyPress={handleTitleKeyPress}
					onChange={handleTitleChange}
					maxRows={1}
					sx={{ width: "calc(90% - 40px)", maxWidth: "700px" }}
				/>
				<br></br>
				<TextField
					id="chat"
					size="small"
					placeholder="Kirjoita uusi chat"
					value={newChat}
					// onKeyPress={handleKeyPress}
					onChange={handleChatChange}
					multiline
					rows={3}
					sx={{ width: "calc(90% - 40px)", maxWidth: "700px" }}
				/>
				<Tooltip title="Lähetä" sx={{ alignItems: "center" }}>
					<IconButton disabled={!isText} color={buttonColor} variant="contained" onClick={handleSaveChat} sx={{ fontWeight: 600, top: "50px" }}>
						<SendIcon />
					</IconButton>
				</Tooltip>
			</Paper>
		</Grid>
	)
}

export default NewChat
