import {
	Typography,
	Grid,
	Box,
	Stack,
	TableContainer,
	Paper,
	Fab,
	TableRow,
	TableCell,
	Tooltip,
	Dialog,
	DialogContent,
	Container,
	TextField,
	TableHead,
	Table,
	TableBody,
	Collapse,
	TableSortLabel,
	CircularProgress,
	Checkbox,
	AccordionSummary,
	Accordion,
	AccordionDetails,
} from "@mui/material"
import React, { useEffect, useMemo, useState } from "react"
import { useNavigate } from "react-router-dom"
import AddIcon from "@mui/icons-material/Add"
import IconButton from "@mui/material/IconButton"
import Button from "@mui/material/Button"
import CreateTaskDialog from "./CreateTaskDialog"
import { toast } from "react-toastify"
import CustomDialogTitle from "../reusables/CustomDialogTitle"
import DialogCloseButton from "../reusables/DialogCloseButton"
import CustomDialogActions from "../reusables/CustomDialogActions"
import { createUserTask, getUserTasks, updateTask, deleteTask } from "../../network/dataServices/noteDataService"
import EditIcon from "@mui/icons-material/Edit"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { Dayjs } from "dayjs"
import fiLocale from "date-fns/locale/fi"
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers"
import DeleteChip from "../reusables/DeleteChip"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"
import TasksTableRow from "./TaskTableRow"

const AllTasks = ({ setOpenNotesDialog }: any) => {
	const [allTaskRoadCooperativeNames, setTaskAllRoadCooperativeNames] = useState<string[]>([])
	const [createNewTask, setCreateNewTask] = useState<boolean>(false)
	const [updateTaskTable, setUpdateTaskTable] = useState(false)
	const [updatedTask, setUpdatedTask] = useState<Task | undefined>()
	const [value, setValue] = useState(1)
	const [tasksRoadCooperativeSortDirection, setTasksRoadCooperativeSortDirection] = useState("asc")
	const [tasksTitleSortDirection, setTasksTitleSortDirection] = useState("asc")
	const [tasksDateTimeSortDirection, setTasksDateTimeSortDirection] = useState("asc")
	const [tasksStatusSortDirection, setTaskStatusSortDirection] = useState("asc")
	const [roadCooperatives, setRoadCooperatives] = useState<string[]>([])
	const [openDoneTask, setOpenDoneTask] = useState(false)
	const [isLoading, setIsLoading] = useState(false)
	const [loadingMessage, setLoadingMessage] = useState("")
	const [taskDone, setTaskDone] = useState(true)
	const [openDeleteTask, setOpenDeleteTask] = useState(false)
	const [tasks, setTasks] = useState<Task[]>([])

	const refetchTasks = async () => {
		const tasks = await getUserTasks()
		const temptaskrcnames: string[] = []
		let temptasks: Task[] = []
		if (tasks) {
			for (let i = 0, len = tasks.data.length; i < len; i++) {
				temptasks.push({
					id: tasks.data[i].id,
					roadCooperativeName: tasks.data[i].road_cooperative_name,
					title: tasks.data[i].title,
					content: tasks.data[i].content,
					completed: tasks.data[i].completed,
					dateTime: tasks.data[i].task_datetime,
					type: "task",
					open: false,
				})
				temptaskrcnames.push(tasks.data[i].road_cooperative_name)
			}
			if (temptasks) {
				temptasks = temptasks.sort((a: Task, b: Task) => {
					const aName = a.roadCooperativeName?.toUpperCase()
					const bName = b.roadCooperativeName?.toUpperCase()

					if (aName < bName) {
						return -1
					}
					if (aName > bName) {
						return 1
					}
					return 0
				})
			}
		}
		setTasks(temptasks)
		setTaskAllRoadCooperativeNames(temptaskrcnames)
	}

	const handleTaskDirectionRC = () => {
		if (tasksRoadCooperativeSortDirection === "asc") {
			setTasksRoadCooperativeSortDirection("desc")
			const temptasks = tasks.sort((a, b) => {
				const aName = a.roadCooperativeName.toUpperCase()
				const bName = b.roadCooperativeName.toUpperCase()
				if (aName < bName) {
					return -1
				}
				if (aName > bName) {
					return 1
				}
				return 0
			})
			setTasks(temptasks)
		} else {
			setTasksRoadCooperativeSortDirection("asc")
			const temptasks = tasks.sort((a, b) => {
				const aName = a.roadCooperativeName.toUpperCase()
				const bName = b.roadCooperativeName.toUpperCase()
				if (aName < bName) {
					return 1
				}
				if (aName > bName) {
					return -1
				}
				return 0
			})
			setTasks(temptasks)
		}
	}

	const handleTaskDirectionDateTime = () => {
		if (tasksDateTimeSortDirection === "desc") {
			setTasksDateTimeSortDirection("asc")
			const temptasks = tasks.sort((a, b) => {
				const aName = a.dateTime.toUpperCase()
				const aSum = aName[0] // + ap
				const bName = b.dateTime.toUpperCase()

				if (aName < bName) {
					return -1
				}
				if (aName > bName) {
					return 1
				}
				return 0
			})
			setTasks(temptasks)
		} else {
			setTasksDateTimeSortDirection("desc")
			const temptasks = tasks.sort((a, b) => {
				const aName = a.dateTime.toUpperCase()
				const bName = b.dateTime.toUpperCase()
				if (aName < bName) {
					return 1
				}
				if (aName > bName) {
					return -1
				}
				return 0
			})
			setTasks(temptasks)
		}
	}

	const handleTaskDirectionStatus = () => {
		if (tasksStatusSortDirection == "asc") {
			setTaskStatusSortDirection("desc")
			const temptasks = tasks.sort((a, b) => {
				if (a.completed && !b.completed) return 1
				else if (!a.completed && b.completed) return -1
				else return 0
			})
			setTasks(temptasks)
		} else {
			setTaskStatusSortDirection("asc")
			const temptasks = tasks.sort((a, b) => {
				if (a.completed && !b.completed) return -1
				else if (!a.completed && b.completed) return 1
				else return 0
			})
			setTasks(temptasks)
		}
	}

	const handleTaskDirectionTitle = () => {
		if (tasksTitleSortDirection === "asc") {
			setTasksTitleSortDirection("desc")
			const temptasks = tasks.sort((a, b) => {
				const aName = a.title.toUpperCase()
				const bName = b.title.toUpperCase()
				if (aName < bName) {
					return -1
				}
				if (aName > bName) {
					return 1
				}
				return 0
			})
			setTasks(temptasks)
		} else {
			setTasksTitleSortDirection("asc")
			const temptasks = tasks.sort((a, b) => {
				const aName = a.title.toUpperCase()
				const bName = b.title.toUpperCase()
				if (aName < bName) {
					return 1
				}
				if (aName > bName) {
					return -1
				}
				return 0
			})
			setTasks(temptasks)
		}
	}

	const navigate = useNavigate()
	const handleChange = (event: React.SyntheticEvent, newValue: number) => {
		setValue(newValue)
	}

	const handleCheckBox = async (id: number) => {
		for (let i = 0, len = tasks.length; i < len; i++) {
			if (tasks[i].id == id) {
				const tempTask = { ...tasks[i] }
				tempTask.completed = !tempTask.completed
				const updatedTaskRes = await updateTask(tempTask)
				if (updatedTaskRes != null) {
					const tempTasks = [...tasks]
					tempTasks[i] = tempTask
					setTasks(tempTasks)
				}
				return
			}
		}
	}

	const onHandleDeleteTask = async (id: number) => {
		setIsLoading(true)
		setLoadingMessage("Poistetaan tehtävää...")
		const tempTasks = [...tasks]
		for (let i = 0, len = tempTasks.length; i < len; i++) {
			if (tempTasks[i].id == id) {
				tempTasks.splice(i, 1)
				const deleteRes = await deleteTask(id)
				if (deleteRes != null) {
					toast.success("Tehtävä poistettu onnistuneesti!")
				} else {
					toast.error("Tehtävän poisto epäonnistui!")
				}
				setOpenDeleteTask(false)
				setUpdateTaskTable(false)
				await getUserTasks() //awaitit näihin kaikkiin TODO
				await refetchTasks()
				setIsLoading(false)
				setTasksDateTimeSortDirection("desc")
				return
			}
		}
	}

	const addTask = async (newTask: Task) => {
		setIsLoading(true)
		setLoadingMessage("Tallennetaan tehtävää...")
		const addRes = await createUserTask(newTask)
		if (addRes != null) {
			toast.success("Tehtävä lisätty onnistuneesti")
		} else {
			toast.error("Tehtävän lisääminen epäonnistui!")
		}
		setCreateNewTask(false)
		setUpdateTaskTable(false)
		await getUserTasks()
		await refetchTasks()
		setIsLoading(false)
		setTasksDateTimeSortDirection("desc")
		return
	}

	const openUpdateTaskDialog = (task: Task) => {
		setUpdatedTask(task)
		setUpdateTaskTable(true)
	}

	const openDoneTaskDialog = (task: Task) => {
		setUpdatedTask(task)
		setOpenDoneTask(true)
	}

	useEffect(() => {
		refetchTasks()
	}, [])

	useEffect(() => {
		const temp = []
		for (let i = 0, len = tasks.length; i < len; i++) {
			temp.push(tasks[i].roadCooperativeName)
		}
		setRoadCooperatives(temp)
	}, [tasks])

	const memoizedTasksDone = useMemo(() => {
		return tasks.map((task, index) => {
			if (task.completed)
				return <TasksTableRow task={task} key={task.id} handleCheckBox={handleCheckBox} openUpdateTaskDialog={openUpdateTaskDialog}></TasksTableRow>
		})
	}, [...tasks, tasks])

	const memoizedTasksNotDone = useMemo(() => {
		return tasks.map((task, index) => {
			if (!task.completed)
				return <TasksTableRow task={task} key={task.id} handleCheckBox={handleCheckBox} openUpdateTaskDialog={openUpdateTaskDialog}></TasksTableRow>
		})
	}, [...tasks, tasks])

	const cellStyles = {
		cellPadding: {
			paddingTop: "0px",
			paddingBottom: "3px",
			paddingRight: "5px",
			paddingLeft: "5px",
		},
	}

	return (
		<>
			<Grid
				item
				xs={12}
				md={6}
				sx={{
					height: "100%",
					"@media screen and (max-width: 700px)": {
						margin: "0px",
						padding: "0px",
						maxWidth: "400px",
					},
				}}
			>
				<TableContainer
					elevation={1}
					sx={{
						justifyContent: "flex-start",
						height: "calc(100% - 50px)",
						overflow: "auto",
						alignItems: "flex-start",
						alignContent: "flex-start",
					}}
				>
					<Button></Button>
					<Table>
						<TableHead>
							<TableRow>
								<TableCell
									sx={{
										width: "13%",
										fontWeight: "700",
										fontSize: "0.875rem",
										paddingTop: "0px",
										paddingBottom: "3px",
										paddingRight: "0px",
										paddingLeft: "5px",
									}}
								>
									Tila
								</TableCell>
								<TableCell sx={{ width: "1%", paddingTop: "0px", paddingBottom: "3px", paddingRight: "5px", paddingLeft: "0px" }}></TableCell>
								<TableCell sx={{ width: "36%", fontWeight: "700", fontSize: "0.875rem", ...cellStyles.cellPadding }}>
									Otsikko
									<TableSortLabel
										direction={tasksTitleSortDirection}
										onClick={() => handleTaskDirectionTitle()}
										active={true}
									></TableSortLabel>
								</TableCell>
								<TableCell sx={{ width: "25%", fontWeight: "700", fontSize: "0.875rem", ...cellStyles.cellPadding }}>
									Tiekunta
									<TableSortLabel
										onClick={() => handleTaskDirectionRC()}
										active={true}
										direction={tasksRoadCooperativeSortDirection}
									></TableSortLabel>
								</TableCell>
								<TableCell sx={{ width: "21%", fontWeight: "700", fontSize: "0.875rem", ...cellStyles.cellPadding }}>
									Aika
									<TableSortLabel
										onClick={() => handleTaskDirectionDateTime()}
										active={true}
										direction={tasksDateTimeSortDirection}
									></TableSortLabel>
								</TableCell>
								<TableCell sx={{ width: "4%", ...cellStyles.cellPadding }}></TableCell>
							</TableRow>
						</TableHead>

						<TableBody>{memoizedTasksNotDone}</TableBody>
					</Table>

					<Accordion sx={{ borderTop: 1, borderTopColor: "#e0e0e0" }}>
						<AccordionSummary expandIcon={<ExpandMoreIcon />}>
							<Typography
								sx={{
									fontSize: "1rem",
								}}
							>
								Valmis
							</Typography>
						</AccordionSummary>
						<AccordionDetails sx={{ padding: "0px" }}>
							<Table>
								<TableBody>{memoizedTasksDone}</TableBody>
							</Table>
						</AccordionDetails>
					</Accordion>
				</TableContainer>
				<Grid item xs={12} md={12} sx={{ height: "40px", marginTop: "10px" }}>
					<Stack direction="row" justifyContent="center" alignContent="center">
						<Dialog sx={{ zIndex: "1491" }} open={createNewTask} maxWidth="md" fullWidth>
							<CreateTaskDialog setCreateNewTask={setCreateNewTask} addTask={addTask} allTaskRoadCooperativeNames={allTaskRoadCooperativeNames} />
						</Dialog>
						<Tooltip title="Luo uusi tehtävä" sx={{ zIndex: "1492", marginBottom: "10px" }}>
							<Fab onClick={() => setCreateNewTask(true)} color="primary" sx={{ width: "35px", height: "20px" }}>
								<AddIcon sx={{ fontSize: "20px" }} />
							</Fab>
						</Tooltip>
					</Stack>
				</Grid>
			</Grid>

			<Dialog sx={{ zIndex: "1491" }} open={updateTaskTable} maxWidth="md" fullWidth>
				<UpdateTaskTable
					setUpdateTaskTable={setUpdateTaskTable}
					refetchTasks={refetchTasks}
					task={updatedTask}
					onHandleDeleteTask={onHandleDeleteTask}
				/>
			</Dialog>
		</>
	)
}

const CustomLoadingDialog = ({ isLoading, loadingMessage }: any) => {
	return (
		<Dialog open={isLoading}>
			<CircularProgress sx={{ marginLeft: "auto", marginRight: "auto" }} />
			<Typography>{loadingMessage}</Typography>
		</Dialog>
	)
}

const UpdateTaskTable = ({ setUpdateTaskTable, updateTaskTable, task, onHandleDeleteTask, refetchTasks }: any) => {
	const [openDeleteTask, setOpenDeleteTask] = useState(false)
	const [tasks, setTasks] = useState<Task[]>([])
	const [editTask, setEditTask] = useState<Task>(task)
	const [isLoading, setIsLoading] = useState(false)
	const [loadingMessage, setLoadingMessage] = useState("")
	const [editTitle, setEditTitle] = useState(task.title)
	const [editRoadCooperativeName, setEditRoadCooperativeName] = useState(task.roadCooperativeName)
	const [editContent, setEditContent] = useState(task.content)
	const [editDateTime, setEditDateTime] = React.useState<Dayjs | null>(task.dateTime)

	const handleUpdateTask = async () => {
		const editTaskObj: any = {
			id: task.id,
			title: editTitle,
			content: editContent,
			type: "task",
			roadCooperativeName: editRoadCooperativeName,
			open: false,
		}

		if (editDateTime) {
			const dateTime = editDateTime instanceof Date ? editDateTime.toISOString() : editDateTime
			editTaskObj.dateTime = dateTime
		} else {
			editTaskObj.dateTime = task.dateTime
		}

		setIsLoading(true)
		setLoadingMessage("Muokataan tehtävää...")
		const editRes = await updateTask(editTaskObj)
		if (editRes != null) {
			toast.success("Tehtävä päivitetty onnistuneesti")
		} else {
			toast.error("Tehtävän muokkaaminen epäonnistui!")
		}
		setUpdateTaskTable(false)
		await refetchTasks()
		await getUserTasks()
		setIsLoading(false)
	}

	return (
		<>
			<CustomLoadingDialog isLoading={isLoading} loadingMessage={loadingMessage} />
			<DialogCloseButton closeFunction={() => setUpdateTaskTable(false)} />
			<DeleteChip deleteFunction={() => setOpenDeleteTask(true)} />
			<CustomDialogTitle>Muokkaa tehtävää</CustomDialogTitle>
			<Container
				maxWidth="md"
				sx={{
					overflow: "auto",
					justifyContent: "center",
					height: "100%",
					maxWidth: "80%",
					textAlign: "center",
					display: "flex",
				}}
				elevation={10}
			>
				<Stack direction="column" maxWidth="90%">
					<Stack direction="column" justifyContent="center" alignItems="center" padding="5px">
						<Grid>
							<TextField
								value={editTitle}
								style={{ width: "100%" }}
								required
								label="Otsikko"
								onChange={(e: { target: { value: string } }) => {
									setEditTitle(e.target.value)
								}}
							></TextField>
							<TextField
								value={editRoadCooperativeName}
								style={{ width: "100%" }}
								label="Tiekunta"
								onChange={(e: { target: { value: string } }) => {
									setEditRoadCooperativeName(e.target.value)
								}}
							></TextField>
							<TextField
								value={editContent}
								style={{ width: "100%" }}
								label="Sisältö"
								cols="20"
								rows={9}
								multiline
								onChange={(e: { target: { value: string } }) => {
									setEditContent(e.target.value)
								}}
							></TextField>
							<LocalizationProvider dateAdapter={AdapterDateFns} locale={fiLocale}>
								<DateTimePicker
									views={["year", "month", "day", "hours", "minutes"]}
									label="Päivämäärä/Kellonaika"
									value={editDateTime}
									inputFormat="dd.MM.yyyy HH:mm"
									renderInput={(props) => <TextField {...props} sx={{ maxWidth: "50%", minWidth: "210px" }} />}
									desktopModeMediaQuery=""
									PopperProps={{
										sx: {
											zIndex: 1492,
										},
									}}
									onChange={(value: Dayjs | null) => setEditDateTime(value)}
								/>
							</LocalizationProvider>
						</Grid>
					</Stack>
				</Stack>
			</Container>
			<CustomDialogActions>
				<Button variant="outlined" color="primary" onClick={() => setUpdateTaskTable(false)}>
					Sulje
				</Button>
				<Button
					variant="contained"
					onClick={() => {
						handleUpdateTask()
					}}
				>
					Tallenna muutokset
				</Button>
			</CustomDialogActions>

			<Dialog sx={{ zIndex: "1492" }} open={openDeleteTask}>
				<CustomDialogTitle>Vahvista poistaminen</CustomDialogTitle>
				<DialogCloseButton closeFunction={() => setOpenDeleteTask(false)} />
				<DialogContent sx={{ textAlign: "center", justifyContent: "center", display: "flex", alignItems: "center" }}>
					{" "}
					Haluatko varmasti poistaa?
				</DialogContent>
				<CustomDialogActions>
					<Button
						variant="outlined"
						color="cancel"
						onClick={() => {
							setOpenDeleteTask(false)
						}}
					>
						Peruuta
					</Button>
					<Button
						variant="contained"
						color="close"
						onClick={() => {
							onHandleDeleteTask(task.id)
						}}
					>
						Poista
					</Button>
				</CustomDialogActions>
			</Dialog>
		</>
	)
}

export default AllTasks
