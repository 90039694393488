import isMobile from "ismobilejs"
import React, { useEffect }  from "react"


const KipaLayersFilterBox = (props: {map: MapInstance}) => {
	const map = props.map

	useEffect(() => {
		const dropdown = document.getElementById("wholeDropdown")
		if(isMobile().any){
			dropdown?.classList.add("map-dropdown-mobile")
			const layerButton = document.getElementById("ifMobileItIsButton")
			const content = document.getElementById("ifMobileContent")
			const arrow = document.getElementById("arrow")
			layerButton?.addEventListener("click", () => {
				content?.classList.toggle("map-dropdown-content-visible")
				arrow?.classList.toggle("map-dropdown-arrow-spinning")
			})
		} else {
			dropdown?.classList.add("map-dropdown")
		}
	},[])


	if(!map) return(null)
  

	const handleKinnteistoRajaLayers = (target: EventTarget & HTMLInputElement): void => {
		if(target.checked === false) {
			map.current.setLayoutProperty("KiinteistotunnuksenSijaintitiedot", "visibility", "none")
			map.current.setLayoutProperty("KiinteistorajanSijaintitiedot", "visibility", "none")
			map.current.setLayoutProperty("PalstanSijaintitiedot", "visibility", "none")
			return 
		}
		map.current.setLayoutProperty("KiinteistotunnuksenSijaintitiedot", "visibility", "visible")
		map.current.setLayoutProperty("KiinteistorajanSijaintitiedot", "visibility", "visible")
		map.current.setLayoutProperty("PalstanSijaintitiedot", "visibility", "visible")
	}

	const handleRajamerkkiLayers = (target: EventTarget & HTMLInputElement): void => {
		if(target.checked === false) {
			map.current.setLayoutProperty("RajamerkinSijaintitiedot", "visibility", "none")
			map.current.setLayoutProperty("RajamerkinSijaintitiedot_tunnus", "visibility", "none")
			return 
		}
		map.current.setLayoutProperty("RajamerkinSijaintitiedot", "visibility", "visible")
		map.current.setLayoutProperty("RajamerkinSijaintitiedot_tunnus", "visibility", "visible")
	}




	return (
		<div id="wholeDropdown">
			<h4 className='map-dropdown-te' id="ifMobileItIsButton" >
          Karttakerrokset
				<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="map-dropdown-arrow bi bi-chevron-double-down" id="arrow" viewBox="0 0 16 16">
					<path fillRule="evenodd" d="M1.646 6.646a.5.5 0 0 1 .708 0L8 12.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
					<path fillRule="evenodd" d="M1.646 2.646a.5.5 0 0 1 .708 0L8 8.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
				</svg>
			</h4>
			<div className='map-dropdown-content' id="ifMobileContent" >
				<div className="form-check">
					<input className="form-check-input" type="checkbox" value="" id="checkBox1"  onChange={({ target }) => handleKinnteistoRajaLayers(target)} />
					<label className="form-check-label" >
            Kiinteistörajat
					</label>
				</div>
				<div className="form-check">
					<input className="form-check-input" type="checkbox" value="" id="checkBox2" onChange={({ target }) => handleRajamerkkiLayers(target) } />
					<label className="form-check-label" >
            Rajamerkit
					</label>
				</div>
			</div>
		</div>
	)
}


export default KipaLayersFilterBox