import "./PageContainer.css"

import React, { Suspense, lazy, useEffect, useState } from "react"
import TopNavBar from "../TopNavBar/TopNavBar"
import SideNavBar from "../SideNavBar/SideNavBar"
import Login from "../Login/Login"
import { Routes, Route, Outlet, useNavigate } from "react-router-dom"
import { Box, CircularProgress, Paper, Typography, Button, Divider } from "@mui/material"
import { gql, useQuery } from "@apollo/client"

import Home from "../HomePage/HomePage"
const MeetingsPage = lazy(() => import("../MeetingsPage/meetingPageView/MeetingsPage"))
const RoadCooperative = lazy(() => import("../RoadCooperative/RoadCooperative"))
const OptionsPageLayout = lazy(() => import("../OptionsPage/OptionsPageLayout"))
const CreateRoadCooperativeView = lazy(() => import("../CreateRoadCooperativeView/CreateRoadCooperativeView"))
const ProfilePage = lazy(() => import("../ProfilePage/ProfilePage"))
// import PartnersInfoPage from "../PartnersInfoPage/PartnersInfoPage"
const DocumentsLayout = lazy(() => import("../DocumentsPage/DocumentsLayout"))
const BulletinPage = lazy(() => import("../MeetingsPage/meetingPageView/BulletinPage"))
const CreateBulletinDeliverys = lazy(() => import("../MeetingsPage/meetingPageView/CreateBulletinDeliverys"))
const ErrorPage = lazy(() => import("../HomePage/ErrorPage"))
const RoadCooperativeGeneralInfoView = lazy(() => import("../OptionsPage/RoadCooperativeGeneralInfoView"))
const RoadCooperativePermissionsView = lazy(() => import("../OptionsPage/PermissionView/RoadCooperativePermissionsView"))
const RoadCooperativeUnitsOptionsView = lazy(() => import("../OptionsPage/costCenterAndUnitOptions/RoadCooperativeCostCenterAndUnitsOptionsView"))
const RoadCooperativeFinancialOptionsView = lazy(() => import("../OptionsPage/FinancialView/RoadCooperativeFinancialOptionsView"))
const RoadCooperativeFinancialLiteOptionsView = lazy(() => import("../OptionsPage/FinancialLiteView/RoadCooperativeFinancialLiteOptionsView"))
const MeetingRoomView = lazy(() => import("../MeetingsPage/meetingRoomView/MeetingRoomView"))
const CreateMeetingInvites = lazy(() => import("../MeetingsPage/meetingPageView/CreateMeetingInvites"))
const DocumentsPage = lazy(() => import("../DocumentsPage/DocumentsPage/DocumentsPage"))
const ReportsPage = lazy(() => import("../DocumentsPage/ReportsPage/ReportsPage"))
const UnitizationTool = lazy(() => import("../UnitizationTool/UnitizationTool"))

import JoinMeetingPage from "./JoinMeetingPage"
import { MeetingStateProvider } from "../../utils/providers/meetingProvider"
import authToken from "../../utils/authToken"
import SelectRoadCooperative from "./SelectRoadCooperative"
import { CreateExpenseStateProvider } from "../modals/ExpenseModals/CreateExpenseProvider"
import { CreateFinancesPlanningStateProvider } from "../FinancesPage/subpages/PlanningView/CreateFinancesPlanningProvider"
// Full finances page components
const FinancesPage = lazy(() => import("../FinancesPage/FinancesPage"))
const ExpensesView = lazy(() => import("../FinancesPage/subpages/ExpensesView"))
const AccountingView = lazy(() => import("../FinancesPage/subpages/AccountingView/AccountingView"))
const FinancesDeliveryView = lazy(() => import("../FinancesPage/subpages/FinancesDeliveryView"))
const IncomesView = lazy(() => import("../FinancesPage/subpages/IncomesView/IncomesView"))
const FinancesSetupView = lazy(() => import("../FinancesPage/subpages/SetupView/FinancesSetupView"))
const FinancesPlanningView = lazy(() => import("../FinancesPage/subpages/PlanningView/FinancesPlanningView"))
const FinancesLayout = lazy(() => import("../FinancesPage/subpages/FinancesLayout"))

// Lite finances page components
import { CreateFLFinancesPlanningStateProvider } from "../FinancesLitePage/FLFinancesPlanning/CreateFLFinancesPlanningProvider"
const FinancesLiteSetupView = lazy(() => import("../FinancesLitePage/LiteSetupView/FinancesLiteSetupView"))
const FinancesLitePage = lazy(() => import("../FinancesLitePage/FinancesLitePage"))
const FLFinancesPlanningView = lazy(() => import("../FinancesLitePage/FLFinancesPlanning/FLFinancesPlanningView"))
const FLFinancesDeliveryView = lazy(() => import("../FinancesLitePage/FLFinancesDeliveryView"))

const FINANCES_VERSION_QUERY = gql`
	query GetFinancesVersion {
		roadCooperativeWithJWT {
			roadCooperativeSettingsByRoadCooperativeId {
				nodes {
					financesVersion
				}
			}
		}
	}
`
const PetitionPage = lazy(() => import("../PetitionPage/PetitionPage"))
import { UnitizationMapStateProvider } from "../../utils/providers/unitizationMapProvider"
import userProfileStorage from "../../utils/providers/userProfileProvider/userProfileProvider"
const PageLoadingFallback = () => {
	return (
		<div className="page-content-grid">
			<div className="page-container-default">
				<div style={{ width: "100%", height: "100vh", justifyContent: "center", alignItems: "center", textAlign: "center" }}>
					<Box sx={{ marginTop: "45vh" }}>
						<CircularProgress color="primary" />
						<Typography>Ladataan...</Typography>
					</Box>
				</div>
			</div>
		</div>
	)
}

const PageContainer = () => {
	// console.log(cookie);
	const meetingId: string | undefined = new URLSearchParams(window.location.search).get("meetingId")?.toString()
	const navigate = useNavigate()
	if (meetingId) {
		return (
			<>
				<JoinMeetingPage />
			</>
		)
	}

	if (authToken.getToken()) {
		// checkJWTExp(authToken.getToken())
	}

	if (window.screen.width < 1120 || window.screen.height < 760) {
		const item = localStorage.getItem("mobileMalfunctionInfo")
		if (item === null) {
			return (
				<div className="login-container">
					<Box
						sx={{
							height: "100%",
							width: "100%",
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
						}}
					>
						<Paper
							sx={{
								maxWidth: "400px",
								padding: "10px",
								textAlign: "center",
							}}
						>
							<Typography>
								Huomio! Ohjelman sisältämien toiminnallisuuksien ja laadukkaamman käyttökokemuksen varmistamiseksi suosittelemme ohjelmaa
								käytettäväksi tietokoneella tai tabletilla.{" "}
							</Typography>
							<Divider></Divider>
							<Button
								sx={{
									marginTop: "5px",
								}}
								variant="contained"
								onClick={() => {
									localStorage.setItem("mobileMalfunctionInfo", "true")
									window.location.reload()
								}}
							>
								Jatka
							</Button>
						</Paper>
					</Box>
				</div>
			)
		}
	}

	const token = authToken.getDecodedToken()

	if (token == undefined) {
		return (
			<div>
				<Login />
			</div>
		)
	}

	if (!token?.roadCooperativeId) {
		return (
			<div className="login-container">
				<SelectRoadCooperative />
			</div>
		)
	}

	// Query and update finances version
	const { data, error, loading } = useQuery(FINANCES_VERSION_QUERY)
	const [financesVersion, setFinancesVersion] = useState<FinancesVersionType | null>(null)
	const permission = userProfileStorage({ type: "getActiveRoadCooperative" }).permission

	useEffect(() => {
		if (!data || loading) return
		setFinancesVersion(data.roadCooperativeWithJWT.roadCooperativeSettingsByRoadCooperativeId.nodes[0].financesVersion)
	}, [data])

	return (
		<div className="grid-container" id="grid-container">
			<div className="grid-topNavBar" id="grid-topNavBar">
				<TopNavBar />
			</div>
			<div className="grid-sideNavBar" id="grid-sideNavBar">
				<SideNavBar permission={permission} />
			</div>
			<div className="grid-pageContent" id="grid-pageContent">
				<Suspense fallback={<PageLoadingFallback />}>
					<Routes>
						<Route path="/" element={<Home />} />
						{(permission === "edit" || permission === "municipality") &&
							{
								NULL: <Route path="finances/*" element={<PageLoadingFallback />} />,
								FULL: (
									<React.Fragment>
										<Route path="finances/setup" element={<FinancesSetupView />} />
										<Route
											path="finances/planning"
											element={
												<div className="page-content-grid">
													<div className="page-container-default">
														<CreateFinancesPlanningStateProvider>
															<FinancesPlanningView backNavigationFunction={() => navigate("/finances")} />
														</CreateFinancesPlanningStateProvider>
													</div>
												</div>
											}
										/>
										<Route path="finances/send/:deliveryType" element={<FinancesDeliveryView />} />
										<Route path="finances" element={<FinancesLayout />}>
											<Route index element={<FinancesPage />} />
											<Route
												path="expenses"
												element={
													<CreateExpenseStateProvider>
														<ExpensesView />
													</CreateExpenseStateProvider>
												}
											/>
											<Route path="incomes" element={<IncomesView />} />
											<Route path="accounting" element={<AccountingView />} />
										</Route>
									</React.Fragment>
								),
								LITE: (
									<React.Fragment>
										<Route
											path="finances/planning"
											element={
												<div className="page-content-grid">
													<div className="page-container-default">
														<CreateFLFinancesPlanningStateProvider>
															<FLFinancesPlanningView backNavigationFunction={() => navigate("/finances")} />
														</CreateFLFinancesPlanningStateProvider>
													</div>
												</div>
											}
										/>
										<Route path="finances/send/:deliveryType" element={<FLFinancesDeliveryView />} />
										<Route path="finances" element={<FinancesLitePage />} />
										<Route path="finances/setup" element={<FinancesLiteSetupView />} />
									</React.Fragment>
								),
							}[financesVersion ?? "NULL"]}
						<Route path="profile" element={<ProfilePage />} />
						{authToken.getDecodedToken()?.email.includes("@tievahti.fi") && permission === "edit" && (
							<Route
								path="unitization-tool"
								element={
									<UnitizationMapStateProvider>
										<UnitizationTool />
									</UnitizationMapStateProvider>
								}
							/>
						)}
						{permission !== "municipality" && (
							<Route path="calendar" element={<Outlet />}>
								<Route path="" element={<MeetingsPage />} />
								<Route path="bulletin" element={<BulletinPage />} />
								{/* <Route
								path="meeting_room"
								element={
									permission === "edit" ? (
										<MeetingStateProvider>
											<MeetingRoomView />
										</MeetingStateProvider>
									) : (
										<MeetingRoomView />
									)
								}
							/> */}
								<Route
									path="meeting_room"
									element={
										<MeetingStateProvider>
											<MeetingRoomView />
										</MeetingStateProvider>
									}
								/>
								<Route path="invites" element={<CreateMeetingInvites />} />
								<Route path="bulletin/delivery" element={<CreateBulletinDeliverys />} />
							</Route>
						)}

						<Route path="documents" element={<DocumentsLayout />}>
							<Route index element={<DocumentsPage />} />
							<Route path="documents" element={<DocumentsPage />} />
							<Route path="reports" element={<ReportsPage />} />
						</Route>
						<Route path="roadcooperative" element={<Outlet />}>
							<Route path="" element={<RoadCooperative />} />
							<Route path="create" element={<CreateRoadCooperativeView />} />
						</Route>
						<Route path="petition" element={<PetitionPage />} />
						<Route path="options" element={<OptionsPageLayout />}>
							<Route index element={<RoadCooperativeGeneralInfoView />} />
							<Route path="general" element={<RoadCooperativeGeneralInfoView />} />
							{permission === "edit" && (
								<>
									<Route path="road-usage-units" element={<RoadCooperativeUnitsOptionsView />} />
									<Route path="permissions" element={<RoadCooperativePermissionsView />} />
									<Route
										path="finances"
										element={
											financesVersion === "FULL" ? <RoadCooperativeFinancialOptionsView /> : <RoadCooperativeFinancialLiteOptionsView />
										}
									/>
								</>
							)}
						</Route>
						<Route path="*" element={<ErrorPage />} />
					</Routes>
				</Suspense>
			</div>
		</div>
	)
}

export default PageContainer
