import kipaLayers from "./kipaLayers"


const loadKipaLayers = (map: MapInstance) => {
	map.current.addSource("kipa", {
		type: "vector",
		url : "https://avoin-karttakuva.maanmittauslaitos.fi/kiinteisto-avoin/v3/kiinteistojaotus/WGS84_Pseudo-Mercator/tilejson.json?api-key=e73cf967-d0e5-4150-88f0-931689fcbe82"
	})

	kipaLayers.kipaLayers.forEach((l) => {
		map.current.addLayer(l)
	})
}

export default loadKipaLayers

