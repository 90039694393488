import { Paper } from "@mui/material"
import * as turf from "@turf/turf"
import maplibregl, { Marker, MapboxEvent } from "maplibre-gl"
import ReactDOM from "react-dom"
import React, { useState } from "react"
import { TextField } from "@mui/material"

type markerObject = {
	marker: Marker
	id: string
}

interface LineString {
	type: "LineString"
	coordinates: number[][]
}

interface Polygon {
	type: "Polygon"
	coordinates: number[][]
}

interface Point {
	type: "Point"
	coordinates: number[]
}

type Geometry = LineString | Polygon | Point

interface CustomFeature {
	id: string
	geometry: Geometry
}

interface CustomDrawEvent extends MapboxEvent {
	features: CustomFeature[]
	action: any
}

export const addMapDrawAreaMarkers = (map: MapInstance, toggle: "on" | "off", state: boolean) => {
	if (toggle === "off") {
		markers.forEach((markerObj) => {
			markerObj.marker.remove()
		})
		markers.length = 0
	}

	if (toggle === "on" && state === false) {
		map.current.on("draw.create", (e: CustomDrawEvent) => {
			createArea(e, map)
		})

		map.current.on("draw.update", (e: CustomDrawEvent) => {
			updateArea(e)
		})

		map.current.on("draw.delete", (e: CustomDrawEvent) => {
			deleteArea(e)
		})
	}
}

export const removeAllMarkers = () => {
	markers.forEach((markerObj) => {
		markerObj.marker.remove()
	})
	markers.length = 0
}

const markers: markerObject[] = []

const CustomMarkerPoint = () => {
	const [text, setText] = useState("")
	return (
		<Paper sx={{ width: "200px" }}>
			<input type="text" />
			<TextField label="vapaa teksti" fullWidth value={text} onChange={(e: any) => setText(e.target.value)}></TextField>
		</Paper>
	)
}

const createMarker = (
	map: MapInstance,
	featureId: string,
	center: number[],
	displayArea = 0,
	unit = "m",
	type: "LineString" | "Polygon" | "Point" = "Point"
) => {
	// Luodaan uusi elementti, joka sisältää tekstiä
	const el = document.createElement("div")
	el.id = `map-marker-div-${featureId}`

	// document.body.appendChild(el) // Lisätään elementti dokumenttiin

	if (type === "Point") {
		// el.className = "marker"

		// ReactDOM.render(<CustomMarkerPoint />, el)
		const popup = new maplibregl.Popup({ offset: 25 }).setHTML('<input type="text" placeholder="Kirjoita tähän..." />').setLngLat([center[0], center[1]])
		const marker = new maplibregl.Marker({ draggable: true }).setLngLat([center[0], center[1]]).addTo(map.current)

		marker.setPopup(popup).togglePopup()
		popup.addTo(map.current)

		markers.push({ marker: marker, id: featureId })
	} else {
		el.className = "marker"
		el.innerHTML = `${type === "Polygon" ? "Pinta-ala" : "Pituus"}: ${displayArea.toFixed(2)} ${unit}`
		// document.body.removeChild(el) // Poistetaan elementti dokumentista, kun sitä ei enää tarvita
		// Lisätään uusi markkeri polygonin keskelle
		const marker = new maplibregl.Marker({
			offset: type == "Polygon" ? [0, 0] : [0, -30],
			element: el,
		})
			.setLngLat([center[0], center[1]])
			.addTo(map.current)

		marker.togglePopup()

		markers.push({ marker: marker, id: featureId })
	}
}

const updateMarker = (marker: markerObject, center: number[], displayArea = 0, unit = "m", type: "LineString" | "Polygon" | "Point" = "Point") => {
	// Päivitä markerin sijainti
	marker.marker.setLngLat([center[0], center[1]])
	if (type === "Point") return
	// Päivitä markerin teksti
	const el = marker.marker.getElement()
	if (el) {
		el.innerHTML = `${type === "Polygon" ? "Pinta-ala" : "Pituus"}: ${displayArea.toFixed(2)} ${unit}`
	}
}

const deleteArea = (e: CustomDrawEvent) => {
	const markerObj = markers.find((marker: markerObject) => marker.id === e.features[0].id)
	if (markerObj) {
		markerObj.marker.remove()
	}
}

const updateArea = (e: CustomDrawEvent) => {
	if (e.features[0].geometry.type == "Point") {
		const point: number[] = e.features[0].geometry.coordinates

		const marker = markers.find((marker: markerObject) => marker.id === e.features[0].id)
		if (marker) {
			updateMarker(marker, point)
		}
	}

	if (e.features[0].geometry.type == "LineString") {
		const line: number[][] = e.features[0].geometry.coordinates
		const lastCoordinate: number[] = line[line.length - 1]

		const length = turf.length(turf.lineString(e.features[0].geometry.coordinates), { units: "meters" })

		const marker = markers.find((marker: markerObject) => marker.id === e.features[0].id)
		if (marker) {
			updateMarker(marker, lastCoordinate, length, "m", "LineString")
		}
	}

	if (e.features[0].geometry.type == "Polygon") {
		const [displayArea, unit, center] = calculatePolygonAreaAndCenterPoint(e)

		// Jos polygonille on jo luotu marker, päivitä sen sijainti ja teksti
		const marker = markers.find((marker: markerObject) => marker.id === e.features[0].id)
		if (marker) {
			updateMarker(marker, center.geometry.coordinates, displayArea, unit, "Polygon")
		}
	}
}

const createArea = (e: CustomDrawEvent, map: MapInstance) => {
	if (e.features[0].geometry.type == "Point") {
		console.log(e, " ------- EVENT ------- ")

		const coordinates: number[] = e.features[0].geometry.coordinates

		createMarker(map, e.features[0].id, coordinates)
	}
	if (e.features[0].geometry.type == "LineString") {
		const line: number[][] = e.features[0].geometry.coordinates
		const lastCoordinate: number[] = line[line.length - 1]

		const length = turf.length(turf.lineString(e.features[0].geometry.coordinates), { units: "meters" })
		createMarker(map, e.features[0].id, lastCoordinate, length, "m", "LineString")
	}

	if (e.features[0].geometry.type == "Polygon") {
		const [displayArea, unit, center] = calculatePolygonAreaAndCenterPoint(e)
		createMarker(map, e.features[0].id, center.geometry.coordinates, displayArea, unit, "Polygon")
	}
}

const calculatePolygonAreaAndCenterPoint = (e: any): [number, string, turf.helpers.Feature<turf.helpers.Point, turf.helpers.Properties>] => {
	// Hae piirretty piirros
	const polygon = turf.polygon([e.features[0].geometry.coordinates[0]])
	const area = turf.area(polygon)
	const center = turf.center(turf.points(e.features[0].geometry.coordinates[0]))

	// Muuta pinta-ala sopivaan yksikköön
	let unit = "m²"
	let displayArea = area
	if (area >= 1000 * 10000) {
		unit = "km²"
		displayArea = area / (1000 * 1000)
	} else if (area >= 1000) {
		unit = "ha"
		displayArea = area / 10000
	}
	return [displayArea, unit, center]
}
