import React, { useEffect, useState } from "react"
import PageContainer from "./components/PageContainer/PageContainer"
import { ThemeProvider } from "@mui/material"
import customTheme from "./utils/customTheme/muiTheme"
import { ReactComponent as CheckIcon } from "./resources/icons/checkIcon.svg"
import { ReactComponent as InfoIcon } from "./resources/icons/info.svg"
import { ReactComponent as ErrorIcon } from "./resources/icons/error.svg"
import { ReactComponent as WarningIcon } from "./resources/icons/warning.svg"

import "./utils/style.css"
import { ToastContainer } from "react-toastify"
import config from "./utils/config"

const App = () => {
	const iconResolver = ({ theme, type }: { theme: "light" | "dark"; type: "success" | "error" | "warning" | "info" }) => {
		switch (type) {
			case "error":
				return <ErrorIcon />
			case "success":
				return <CheckIcon />
			case "warning":
				return <WarningIcon />
			case "info":
				return <InfoIcon />
			default:
				return ""
		}
	}

	const [trues, setTrues] = useState(false)

	const secretKey: any = config.SECRET_KEY_FROM_KUNTA

	// Hex-koodin muuntaminen Uint8Arrayksi
	const hexToUint8Array = (hex: string) => {
		return Uint8Array.from(hex.match(/.{1,2}/g)!.map((byte) => parseInt(byte, 16)))
	}

	const getKey = async (key: string) => {
		const decodedKey = hexToUint8Array(key) // Muunnetaan binäärimuotoon
		return await crypto.subtle.importKey("raw", decodedKey, { name: "AES-GCM" }, false, ["encrypt", "decrypt"])
	}

	const decodeMessage = async (encryptedMessage: ArrayBuffer, iv: Uint8Array, key: CryptoKey) => {
		const decrypted = await crypto.subtle.decrypt(
			{
				name: "AES-GCM",
				iv,
			},
			key,
			encryptedMessage
		)

		const dec = new TextDecoder()
		return JSON.parse(dec.decode(decrypted))
	}

	// TODO JATKA TÄSTÄ ETTÄ LISÄÄT MYÖS TÄMÄN TIEVAHDIN URL JA KATSO TYYPPIVIKA

	const acceptedOrigins = [config.KUNTA_FRONTEND_URL, config.FRONTEND_URL]

	useEffect(() => {
		const messageHandler = async (event: any) => {
			if (!acceptedOrigins.includes(event.origin)) {
				console.error("Tuntematon lähde:", event.origin)
				return
			}

			const { encryptedMessage, iv, nonce } = event.data // Ota nonce mukaan

			if (encryptedMessage && iv && nonce) {
				// Tarkista että nonce lähetetään
				const key = await getKey(secretKey) // Sama avain kuin lähettäjässä
				const decodedMessage = await decodeMessage(encryptedMessage, iv, key)
				const { roadCooperative, tievahtiToken, tievahtiRefreshToken, user } = decodedMessage

				// Noncen tarkistus
				if (!nonce) {
					console.error("Vastaanotettu nonce puuttuu")
					return
				}

				if (!localStorage.getItem("authToken") && tievahtiToken) {
					localStorage.setItem("authToken", tievahtiToken)
				}
				if (!localStorage.getItem("authTokenRefresh") && tievahtiRefreshToken) {
					localStorage.setItem("authTokenRefresh", tievahtiRefreshToken)
				}

				try {
					const parsedUser = typeof user === "string" ? JSON.parse(user) : user
					localStorage.setItem("userProfileInfo", JSON.stringify(parsedUser))
				} catch (error) {
					localStorage.setItem("userProfileInfo", JSON.stringify(user))
				}

				localStorage.setItem("selectedRoadCooperative", JSON.stringify(roadCooperative))
				setTrues(true)
			}
		}

		if (window.opener) {
			window.opener.postMessage("windowReady", config.KUNTA_FRONTEND_URL)
		}

		window.addEventListener("message", messageHandler)

		return () => {
			window.removeEventListener("message", messageHandler)
		}
	}, [])

	return (
		<div>
			<>
				<ThemeProvider theme={customTheme.customTheme}>
					<PageContainer />
				</ThemeProvider>
			</>

			<ToastContainer
				role="global"
				icon={iconResolver}
				limit={4}
				position="bottom-right"
				autoClose={9000}
				hideProgressBar={false}
				newestOnTop
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
			/>
		</div>
	)
}

export default App
