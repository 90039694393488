import { Stack } from "@mui/material"
import { Tooltip } from "@mui/material"
import { Button } from "@mui/material"
import { Typography } from "@mui/material"
import React from "react"

interface Props {
	municipalityName?: string
	handleMunicipalityLogoClick: () => void
}

const MunicipalityButton: React.FC<Props> = ({ municipalityName, handleMunicipalityLogoClick }: any) => {
	const vaakunaSrc = `/municipalityLogos/${encodeURIComponent(municipalityName)}.png`

	return (
		<Tooltip title="Tarkastele/tee hakemuksia.">
			<Button
				onClick={handleMunicipalityLogoClick}
				color="primary"
				variant="contained"
				sx={{
					position: "fixed",
					cursor: "pointer",
					minWidth: "auto",
					height: "47px",
					marginTop: "10px",
					marginRight: "5px",
					right: "300px",
					paddingRight: "20px",
					paddingLeft: "50px",
					zIndex: "1000",
					"@media screen and (max-width: 900px)": {
						display: "none",
					},
				}}
			>
				<Stack direction="row" sx={{ width: "auto", height: "48px", alignItems: "center" }}>
					<img
						src={vaakunaSrc}
						style={{
							width: "60px",
							height: "60px",
							objectFit: "contain",
							position: "absolute",
							left: "-15px", // Säädä tätä arvoa tarvittaessa
						}}
						alt={municipalityName}
					/>
					<Typography
						sx={{
							color: "white",
							fontWeight: "700",
							fontSize: "0.8rem",
							display: "inline",
						}}
					>
						{municipalityName}
					</Typography>
				</Stack>
			</Button>
		</Tooltip>
	)
}

export default MunicipalityButton
