let BACKEND_ROOT_URL
let BACKEND_PROXY_HOST
let BACKEND_PROXY_PORT
let MML_API_KEY
let FRONTEND_URL
let KUNTA_FRONTEND_URL
let SECRET_KEY_FROM_KUNTA

if (process.env.REACT_APP_ENVIRONMENT === "development") {
	BACKEND_ROOT_URL = process.env.REACT_APP_BACKEND_ROOT_URL_LOCAL
	BACKEND_PROXY_HOST = process.env.REACT_APP_BACKEND_PROXY_HOST_LOCAL
	BACKEND_PROXY_PORT = Number(process.env.REACT_APP_BACKEND_PROXY_PORT_LOCAL)
	MML_API_KEY = process.env.REACT_APP_MML_API_KEY_LOCAL
	FRONTEND_URL = process.env.REACT_APP_FRONTEND_URL_LOCAL
	KUNTA_FRONTEND_URL = process.env.REACT_APP_KUNTA_FRONTEND_URL_LOCAL
	SECRET_KEY_FROM_KUNTA = process.env.REACT_APP_SECRET_KEY_FROM_KUNTA_LOCAL
} else {
	BACKEND_ROOT_URL = process.env.REACT_APP_BACKEND_ROOT_URL
	BACKEND_PROXY_HOST = process.env.REACT_APP_BACKEND_PROXY_HOST
	BACKEND_PROXY_PORT = Number(process.env.REACT_APP_BACKEND_PROXY_PORT)
	MML_API_KEY = process.env.REACT_APP_MML_API_KEY
	FRONTEND_URL = process.env.REACT_APP_FRONTEND_URL
	KUNTA_FRONTEND_URL = process.env.REACT_APP_KUNTA_FRONTEND_URL
	SECRET_KEY_FROM_KUNTA = process.env.REACT_APP_SECRET_KEY_FROM_KUNTA
}

export default {
	BACKEND_ROOT_URL,
	BACKEND_PROXY_HOST,
	BACKEND_PROXY_PORT,
	MML_API_KEY,
	FRONTEND_URL,
	KUNTA_FRONTEND_URL,
	SECRET_KEY_FROM_KUNTA,
}
