import React from "react"
import { ApolloQueryResult, OperationVariables } from "@apollo/client"
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos"
import EditIcon from "@mui/icons-material/Edit"
import { Box, Collapse, Dialog, DialogContent, IconButton, Table, TableBody, TableCell, TableRow, Typography } from "@mui/material"
import { useState } from "react"
import CustomDialogActions from "../../../../reusables/CustomDialogActions"
import CustomDialogTitle from "../../../../reusables/CustomDialogTitle"
import DialogCloseButton from "../../../../reusables/DialogCloseButton"
import EditEstablishmentDialog from "../../EditEstablishmentDialog"
import CopyableText from "../../../../reusables/CopyableText"
import { MutationIconButton } from "../../../../reusables/MutationButtonChipFab"

const EstablishmentsTableRow = ({
	unit,
	refetch,
	unitsViewType,
}: {
	unit: Establishment
	refetch: (variables?: Partial<OperationVariables> | undefined) => Promise<ApolloQueryResult<any>>
	unitsViewType: UnitsViewType
}) => {
	const [open, setOpen] = useState(false)
	const [openEdit, setOpenEdit] = useState(false)
	const [selectedUnit, setSelecetedUnit] = useState(null)

	const additionalInformation = unit?.additionalInformation
		? unit.additionalInformation.length > 20
			? unit.additionalInformation.slice(0, 20) + "..."
			: unit.additionalInformation
		: ""

	const propertyName = unit.propertyByPropertyId?.propertyName
		? unit.propertyByPropertyId?.propertyName.length > 15
			? unit.propertyByPropertyId?.propertyName.slice(0, 15) + "..."
			: unit.propertyByPropertyId?.propertyName
		: ""

	const combinedText =
		unit.propertyByPropertyId?.propertyTag !== 1 && unit.propertyByPropertyId?.propertyTag !== undefined
			? `${unit.propertyByPropertyId.mmlPropertyId}/${unit.propertyByPropertyId.propertyTag}`
			: unit.propertyByPropertyId?.mmlPropertyId || ""

	return (
		<>
			<Dialog open={openEdit} fullWidth>
				<DialogCloseButton closeFunction={() => setOpenEdit(false)} />
				<CustomDialogTitle>Yksikkötiedon muokkaaminen</CustomDialogTitle>
				<DialogContent>
					<EditEstablishmentDialog functionCallOnDataChange={refetch} id={unit.id} setOpenEdit={setOpenEdit} />
				</DialogContent>
				<CustomDialogActions>
					<></>
				</CustomDialogActions>
			</Dialog>

			<TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
				<TableCell sx={{ paddingBottom: "25px", paddingTop: "25px", width: "5px" }}>
					<Box
						sx={{
							width: "12px",
							height: "12px",
							background: unit.costCenterByCostCenterId.idColor,
							borderRadius: "8px",
							marginRight: "15px",
							marginLeft: "10px",
						}}
					/>
				</TableCell>
				{unitsViewType == "professional" ? (
					<TableCell sx={{ width: "30px" }}>
						{open ? (
							<IconButton size="medium" onClick={() => setOpen(!open)}>
								<ArrowForwardIosIcon fontSize="small" sx={{ transform: "rotate(90deg) translateY(3px) " }} />
							</IconButton>
						) : (
							<IconButton size="medium" onClick={() => setOpen(!open)}>
								<ArrowForwardIosIcon fontSize="small" />
							</IconButton>
						)}
					</TableCell>
				) : (
					<TableCell></TableCell>
				)}
				{unitsViewType == "professional" ? (
					<TableCell align="center">{unit?.roadUsageUnitByRoadUsageUnitId?.roadUsageUnit}</TableCell>
				) : (
					<TableCell align="center">
						<CopyableText text={unit.roadCooperativeMemberByRoadCooperativeMemberId?.name}></CopyableText>
					</TableCell>
				)}
				<TableCell align="center">
					<div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
						<CopyableText text={combinedText} />
						<span style={{ marginTop: "4px", marginRight: "25px" }}>{propertyName}</span> {/* Adds spacing between items */}
					</div>
				</TableCell>
				<TableCell align="center" sx={{ maxWidth: "150px" }}>
					{additionalInformation}
				</TableCell>
				<TableCell align="center" sx={{ maxWidth: "150px" }}>
					{unit.roadUnits}
				</TableCell>

				<TableCell align="center">
					<MutationIconButton
						// color='primary'
						size="small"
						onClick={() => setOpenEdit(true)}
					>
						<EditIcon sx={{ width: "20px", color: "rgba(0, 0, 0, 0.54)" }} />
					</MutationIconButton>
				</TableCell>
			</TableRow>
			<TableRow>
				<TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
					<Collapse in={open} timeout="auto" unmountOnExit>
						<Box sx={{ margin: 1 }}>
							<Table size="small" aria-label="units">
								<TableBody>
									{unitsViewType == "professional" ? (
										<>
											<TableRow>
												<TableCell>
													<Typography sx={{ fontSize: "0.9rem", fontWeight: "500" }}>
														Osakas: {unit.roadCooperativeMemberByRoadCooperativeMemberId?.name}
													</Typography>
												</TableCell>
												<TableCell>
													<Typography sx={{ fontSize: "0.9rem", fontWeight: "500" }}>
														Painoluku (t): {unit.roadUsageUnitByRoadUsageUnitId?.weight}
													</Typography>
												</TableCell>
											</TableRow>
											<TableRow>
												<TableCell>
													<Typography sx={{ fontSize: "0.9rem", fontWeight: "500" }}>
														Käyttömatka (km): {unit.operatingDistance}
													</Typography>
												</TableCell>
												<TableCell>
													<Typography sx={{ fontSize: "0.9rem", fontWeight: "500" }}>Lukumäärä (ha/kpl): {unit.amount}</Typography>
												</TableCell>
											</TableRow>
											<TableRow>
												<TableCell>
													<Typography sx={{ fontSize: "0.9rem", fontWeight: "500" }}>
														Matkan korj.ker: {unit.distanceCorrectionMultiplier}
													</Typography>
												</TableCell>
												<TableCell>
													<Typography sx={{ fontSize: "0.9rem", fontWeight: "500" }}>
														Painoluvun korj.ker: {unit.weightCorrectionMultiplier}
													</Typography>
												</TableCell>
											</TableRow>
											<TableRow>
												<TableCell>
													<Typography sx={{ fontSize: "0.9rem", fontWeight: "500" }}>
														Harkinnanvarainen korj.ker: {unit.discretionalyCorrectionMultiplier}
													</Typography>
												</TableCell>
											</TableRow>
										</>
									) : (
										<>
											<TableRow>
												<TableCell>
													<Typography sx={{ fontSize: "0.9rem", fontWeight: "500" }}>
														Osakas: {unit.roadCooperativeMemberByRoadCooperativeMemberId?.name}
													</Typography>
												</TableCell>
												<TableCell>
													<Typography sx={{ fontSize: "0.9rem", fontWeight: "500" }}>Yksiköt: {unit.roadUnits}</Typography>
												</TableCell>
											</TableRow>
										</>
									)}
								</TableBody>
							</Table>
						</Box>
					</Collapse>
				</TableCell>
			</TableRow>
		</>
	)
}

export default EstablishmentsTableRow
