import React, { createContext, useContext, useMemo, useReducer, useEffect, useState } from "react"
import currentMeetingStorage from "../storages/currentMeetingStorage"

type MeetingDocumentData = string[]

type MeetingParticipants = any

type MeetingAgenda = string[]

type MeetingInfo = {
	title: string
	roadCooperative: string
	roadCooerativeIdentifier: string
	locationOfMeet: string
	dateOfMeet: string
	timeOfMeet: string
	meetingType: {
		onPremises: boolean
		remotely: boolean
	}
	id: number
	videoSDKMeetingId: string
}

type MeetingAttachments = string[]

type Action =
	| { type: "setDocumentData"; payload: MeetingDocumentData }
	| { type: "setMeetingParticipants"; payload: MeetingParticipants | null }
	| { type: "setMeetingAgenda"; payload: MeetingAgenda }
	| { type: "setMeetingOfficers"; payload: MeetingOfficer[] }
	| { type: "setMeetingInfo"; payload: MeetingInfo }
	| { type: "setMeetingAttachments"; payload: MeetingAttachments }
	| { type: "setAgendasWithVote"; payload: any }
	| { type: "setMeetingIsStarted"; payload: boolean }
	| { type: "endMeeting" }

type Dispatch = (action: Action) => void

type State = {
	documentAgenda: MeetingAgenda
	documentData: MeetingDocumentData
	participants: MeetingParticipants | null
	officers: MeetingOfficer[]
	meetingInfo: MeetingInfo
	attachments: MeetingAttachments
	AgendasWithVote: any
	meetingIsStarted: boolean
}

const initialMeetingState: State = {
	documentAgenda: [],
	documentData: [],
	participants: null,
	officers: [
		{ role: "Puheenjohtaja", name: "" },
		{ role: "Sihteeri", name: "" },
		{ role: "Pöytäkirjan tarkastaja", name: "" },
	],
	meetingInfo: {
		title: "",
		roadCooperative: "",
		roadCooerativeIdentifier: "",
		locationOfMeet: "",
		dateOfMeet: "",
		timeOfMeet: "",
		meetingType: {
			onPremises: true,
			remotely: false,
		},
		id: 0,
		videoSDKMeetingId: "",
	},
	attachments: ["Ei liitteitä"],
	AgendasWithVote: [],
	meetingIsStarted: false,
}

const endMeeting = () => {
	currentMeetingStorage({ type: "endMeeting" })
	return initialMeetingState
}

const meetingReducer = (state: State, action: Action): State => {
	switch (action.type) {
		case "setAgendasWithVote":
			currentMeetingStorage({ type: "setAgendasWithVote", payload: action.payload })
			return { ...state, AgendasWithVote: action.payload }
		case "setMeetingAttachments":
			currentMeetingStorage({ type: "setMeetingAttachments", payload: action.payload })
			return { ...state, attachments: action.payload }
		case "setDocumentData":
			currentMeetingStorage({ type: "setMeetingDocumentData", payload: action.payload })
			return { ...state, documentData: action.payload }
		case "setMeetingParticipants":
			currentMeetingStorage({ type: "setMeetingParticipants", payload: action.payload })
			return { ...state, participants: action.payload }
		case "setMeetingAgenda":
			currentMeetingStorage({ type: "setMeetingAgenda", payload: action.payload })
			return { ...state, documentAgenda: action.payload }
		case "setMeetingOfficers":
			currentMeetingStorage({ type: "setMeetingOfficers", payload: action.payload })
			return { ...state, officers: action.payload }
		case "setMeetingInfo":
			currentMeetingStorage({ type: "setMeetingInfo", payload: action.payload })
			return { ...state, meetingInfo: action.payload }
		case "setMeetingIsStarted":
			currentMeetingStorage({ type: "setMeetingIsStarted", payload: action.payload })
			return { ...state, meetingIsStarted: action.payload }
		case "endMeeting":
			return endMeeting()
		default:
			return state
	}
}

type MeetingProviderProps = { children: React.ReactNode }

const MeetingStateContext = createContext<{ state: State; dispatch: Dispatch } | undefined>(undefined)

const MeetingStateProvider = ({ children }: MeetingProviderProps) => {
	const [state, dispatch] = useReducer(meetingReducer, initialMeetingState)
	const [isInitialized, setIsInitialized] = useState(false)

	useEffect(() => {
		const initializeState = async () => {
			const documentData = await currentMeetingStorage({ type: "getMeetingDocumentData" })
			const participants = await currentMeetingStorage({ type: "getMeetingParticipants" })
			const agenda = await currentMeetingStorage({ type: "getMeetingAgenda" })
			const officers = await currentMeetingStorage({ type: "getMeetingOfficers" })
			const info = await currentMeetingStorage({ type: "getMeetingInfo" })
			const attachments = await currentMeetingStorage({ type: "getMeetingAttachments" })
			const agendasWithVote = await currentMeetingStorage({ type: "getAgendasWithVote" })
			const meetingIsStarted = await currentMeetingStorage({ type: "getMeetingStarted" })

			dispatch({ type: "setDocumentData", payload: documentData ?? [] })
			dispatch({ type: "setMeetingParticipants", payload: participants ?? null })
			dispatch({ type: "setMeetingAgenda", payload: agenda ?? [] })
			dispatch({ type: "setMeetingOfficers", payload: officers ?? [] })
			dispatch({ type: "setMeetingInfo", payload: info ?? initialMeetingState.meetingInfo })
			dispatch({ type: "setMeetingAttachments", payload: attachments ?? ["Ei liitteitä"] })
			dispatch({ type: "setAgendasWithVote", payload: agendasWithVote ?? [] })
			dispatch({ type: "setMeetingIsStarted", payload: meetingIsStarted ?? false })

			setIsInitialized(true)
		}

		initializeState()
	}, [])

	const value = useMemo(() => ({ state, dispatch }), [state])

	if (!isInitialized) {
		return <div>Loading...</div>
	}

	return <MeetingStateContext.Provider value={value}>{children}</MeetingStateContext.Provider>
}

const useMeetingState = () => {
	const context = useContext(MeetingStateContext)
	if (context === undefined) {
		throw new Error("useMeetingState hook must be used within a MeetingStateProvider")
	}
	return context
}

export { MeetingStateProvider, useMeetingState }
export type { State }
