import React from "react"
import PartnersTable from "../../../HomePage/HomePageDrawer/DrawerTableViews/PartnerTable/PartnersTable"

const PartnersPopup = () => {
	const PartnerPopupStyle = {
		marginTop: "10px",
	}
	return (
		<div style={PartnerPopupStyle}>
			<PartnersTable wholeRoadCooperative={true} activeCostCenterId={null} activeCostCenterColor=""></PartnersTable>
		</div>
	)
}

export default PartnersPopup
