import React, { useEffect, useState } from "react"
import { DialogContent } from "@mui/material"
import { Button } from "@mui/material"
import CustomDialogTitle from "../../reusables/CustomDialogTitle"
import DialogCloseButton from "../../reusables/DialogCloseButton"
import CustomDialogActions from "../../reusables/CustomDialogActions"
import { gql, useMutation, useQuery } from "@apollo/client"
import CustomNotification from "../../reusables/CustomNotification"
import { Typography } from "@mui/material"
import { Checkbox } from "@mui/material"
import { toast } from "react-toastify"

const CREATE_ACCEPTED_ROAD_COOPERATIVE = gql`
	mutation CreateAcceptedRoadCooperative(
		$rcMemberCount: String = ""
		$roadCooperativeId: String = ""
		$name: String = ""
		$applicantEmail: String = ""
		$applicantName: String = ""
		$applicantPhone: String = ""
		$applicantRcPermissionUnit: String = ""
		$municipalityNameAndCode: String = ""
	) {
		createAcceptedRoadCooperative(
			input: {
				name: $name
				roadCooperativeId: $roadCooperativeId
				rcMemberCount: $rcMemberCount
				municipalityNameAndCode: $municipalityNameAndCode
				applicantRcPermissionUnit: $applicantRcPermissionUnit
				applicantPhone: $applicantPhone
				applicantName: $applicantName
				applicantEmail: $applicantEmail
			}
		) {
			acceptedRoadCooperative {
				name
			}
		}
	}
`

const GET_DATA_TO_RC = gql`
	query GetDataToRc {
		roadCooperativeWithJWT {
			id
			municipality
			name
			permissionUnitId
			roadCooperativeMembersByRoadCooperativeId {
				totalCount
			}
			memberBillingInformationByContactPersonGeneral {
				email
				name
				phoneNumber
			}
		}
	}
`

const GET_MUNICIPALITY_PERMISSION = gql`
	query AllAcceptableRoadCooperatives {
		allAcceptableRoadCooperatives {
			... on accepted_road_cooperative_id {
				accepted
				id
				roadCooperativeId
			}
			... on NoMunicipality {
				message
			}
		}
	}
`

const MunicipalityPermissionDialog = ({ setOpenMunicipalityPopUp }: any) => {
	const [createAcceptedRoadCooperative] = useMutation(CREATE_ACCEPTED_ROAD_COOPERATIVE)
	const [rcInfo, setRcInfo] = useState<any>(null)
	const [isCheckboxChecked, setIsCheckboxChecked] = useState(false)
	const [isCheckboxDisabled, setIsCheckboxDisabled] = useState(true)

	const { loading, data, error, refetch } = useQuery(GET_DATA_TO_RC, {
		onCompleted(data) {
			if (data) {
				setRcInfo(data.roadCooperativeWithJWT)
			}
		},
	})

	const handleDoApplication = () => {
		handleSubmit()
		setOpenMunicipalityPopUp(false)
	}

	const handleSubmit = async () => {
		await createAcceptedRoadCooperative({
			variables: {
				name: rcInfo.name,
				roadCooperativeId: rcInfo.id,
				applicantName: rcInfo.memberBillingInformationByContactPersonGeneral.name,
				applicantPhone: rcInfo.memberBillingInformationByContactPersonGeneral.phoneNumber,
				applicantEmail: rcInfo.memberBillingInformationByContactPersonGeneral.email,
				applicantRcPermissionUnit: rcInfo.permissionUnitId,
				municipalityNameAndCode: rcInfo.municipality,
				rcMemberCount: rcInfo.roadCooperativeMembersByRoadCooperativeId.totalCount.toString(),
			},
			refetchQueries: [{ query: GET_MUNICIPALITY_PERMISSION }],
		})
	}

	const [phoneMissing, setPhoneMissing] = useState(false)
	const [permissionUnitMissing, setPermissionUnitMissing] = useState(false)

	const handleCheckboxChange = (event: any) => {
		const phoneNumber = rcInfo?.memberBillingInformationByContactPersonGeneral.phoneNumber
		const rcPermissionUnit = rcInfo?.permissionUnitId

		if (!phoneNumber) {
			toast.error("Henkilön puhelinnumero puuttuu.")
		} else if (!rcPermissionUnit) {
			toast.error("Käyttöoikeysyksikkotunnus puuttuu.")
		} else {
			setIsCheckboxChecked(event.target.checked)
		}
	}

	useEffect(() => {
		if (rcInfo) {
			const phoneNumber = rcInfo.memberBillingInformationByContactPersonGeneral.phoneNumber
			const rcPermissionUnit = rcInfo.permissionUnitId

			if (!phoneNumber) {
				setIsCheckboxDisabled(true)
				setPhoneMissing(true)
			} else {
				setPhoneMissing(false)
			}

			if (!rcPermissionUnit) {
				setIsCheckboxDisabled(true)
				setPermissionUnitMissing(true)
			} else {
				setPermissionUnitMissing(false)
			}

			if (phoneNumber && rcPermissionUnit) {
				setIsCheckboxDisabled(false)
			}
		}
	}, [rcInfo])

	if (loading) return <p>lataa...</p>
	if (error) return <p>virhe</p>

	return (
		<>
			<CustomDialogTitle>Kuntakohtaiset hakemukset</CustomDialogTitle>
			<DialogCloseButton closeFunction={() => setOpenMunicipalityPopUp(false)} />
			{phoneMissing && <CustomNotification severity="info">Puhelinnumero puuttuu. Voit lisätä puhelinnumeron profiili-osiosta.</CustomNotification>}
			{permissionUnitMissing && (
				<CustomNotification severity="info">Käyttöoikeusyksikkötunnus puuttuu. Voit lisätä sen asetukset-osiosta.</CustomNotification>
			)}
			<DialogContent sx={{ textAlign: "center", justifyContent: "center", display: "flex", alignItems: "center" }}>
				Lähettääksesi hakemuksia kunnalle Tievahti-verkkopalvelun kautta tulee sinun pyytää kunnaltasi lupa hakemusten lähettämiseen. Kun sinut on
				todettu hakukelpoiseksi niin vaakunalla varustettu nappi muuttuu vihreäksi, nappia klikkaamalla pääset tarkastelemaan ja tekemään hakemuksia.
			</DialogContent>
			<DialogContent sx={{ textAlign: "center", justifyContent: "center", display: "flex", alignItems: "center" }}>
				<Typography sx={{ fontWeight: "500", display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
					Kunta voi käyttää tiekuntani tietoja Tievahti-verkkopalvelusta
					<Checkbox onChange={handleCheckboxChange} disabled={isCheckboxDisabled} checked={isCheckboxChecked}></Checkbox>
				</Typography>
			</DialogContent>
			<CustomDialogActions>
				<Button
					onClick={() => {
						setOpenMunicipalityPopUp(false)
					}}
					variant="outlined"
					color="cancel"
				>
					Sulje
				</Button>
				<Button variant="contained" disabled={!isCheckboxChecked} onClick={handleDoApplication}>
					Pyydä lupaa
				</Button>
			</CustomDialogActions>
		</>
	)
}

export default MunicipalityPermissionDialog
