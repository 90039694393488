import { Button } from "@mui/material"
import { Paper } from "@mui/material"
import { Box } from "@mui/material"
import { DialogContent } from "@mui/material"
import { Dialog } from "@mui/material"
import React, { useState } from "react"
import Draggable from "react-draggable"
import PartnersTable from "../HomePage/HomePageDrawer/DrawerTableViews/PartnerTable/PartnersTable"
import CustomDialogActions from "../reusables/CustomDialogActions"
import CustomDialogTitle from "../reusables/CustomDialogTitle"
import DialogCloseButton from "../reusables/DialogCloseButton"
import DialogCloseButtonToDraggable from "../reusables/DialogCloseButtonToDraggable"

const ChatBot = ({ setOpenChatDialog, openChatDialog }: any) => {
	if (!openChatDialog) return <></>

	return (
		<div id="chatBot" style={{ position: "relative", zIndex: "1495", height: "0px" }}>
			<Draggable handle=".chatBot-table-popoup-handler" defaultPosition={{ x: 300, y: 10 }}>
				<Paper
					sx={{
						height: "auto",
						width: "fit-content",
						"@media screen and (max-width: 750px)": {
							padding: "0px",
							margin: "0px",
							maxWidth: "440px",
							position: "absolute",
							left: -290,
							top: 0,
						},
					}}
					elevation={5}
				>
					<Box
						sx={{
							display: "flex",
							justifyContent: "center",
						}}
						className="chatBot-table-popoup-handler"
					>
						<CustomDialogTitle>Tuki</CustomDialogTitle>
						<DialogCloseButtonToDraggable closeFunction={() => setOpenChatDialog(false)} />
					</Box>
					<DialogContent
						sx={{
							minWidth: "400px",
							resize: "both",
							width: "fit-content",
							overflow: "hidden",
							height: "450px",
							paddingRight: "40px",
							paddingLeft: "40px",
							paddingBottom: "40px",
						}}
					>
						<iframe
							src="https://landbot.pro/v3/H-1414540-Z8514I5APEV0EO2N/index.html"
							style={{ width: "100%", borderRadius: "10px", height: "100%" }}
						/>

						<Box
							sx={{
								width: "auto",
							}}
						></Box>
					</DialogContent>
				</Paper>
			</Draggable>
		</div>
	)
}

export default ChatBot
