import { from, HttpLink } from "@apollo/client"
import { setContext } from "@apollo/client/link/context"
import authToken from "../../../utils/authToken"

import config from "../../../utils/config"
import authLink from "./authLink"
import dataLoggerLink from "./dataLoggerLink"
import errorLink from "./errorLink"

const baseURL = config.BACKEND_ROOT_URL

const checkAuthToken = setContext(async () => {
	await authToken.checkToken()
})

const additiveLinkList = () => {
	return from([
		// import and add all Links you want in this link list
		checkAuthToken,
		errorLink,
		dataLoggerLink,
		authLink,
		new HttpLink({
			uri: `${baseURL}/api/graphql`,
			credentials: "same-origin",
		}),
	])
}

export default additiveLinkList
