import { gql, useMutation, useQuery } from "@apollo/client"
import DeleteIcon from "@mui/icons-material/Delete"
import { Box, Button, CircularProgress, IconButton, MenuItem, Stack, TextField } from "@mui/material"
import React, { useEffect, useState } from "react"
import { toast } from "react-toastify"

import { Chip } from "@mui/material"
import isMobile from "ismobilejs"

import { Divider } from "@mui/material"
import MonetaryInput from "../../../utils/MonetaryInput"
import CustomNotification from "../../reusables/CustomNotification"
import { Select } from "@mui/material"
import { InputLabel } from "@mui/material"
import { Typography } from "@mui/material"
import { FormControl } from "@mui/material"
import { DialogContent } from "@mui/material"
import { Dialog } from "@mui/material"
import CustomDialogActions from "../../reusables/CustomDialogActions"
import CustomDialogTitle from "../../reusables/CustomDialogTitle"
import DialogCloseButton from "../../reusables/DialogCloseButton"
import { Tooltip } from "@mui/material"

const GET_ESTABLISHMENT = gql`
	query GetEstablishmentById($id: Int = 7) {
		establishmentById(id: $id) {
			additionalInformation
			amount
			costCenterId
			discretionalyCorrectionMultiplier
			distanceCorrectionMultiplier
			lateralDirection
			lateralDirectionCorrectionMultiplier
			operatingDistance
			propertyId
			roadUnits
			weightCorrectionMultiplier
			roadUsageUnitByRoadUsageUnitId {
				id
				roadUsageUnit
				roadUsageUnitType
				weight
			}
			roadUsageUnitId
			roadCooperativeMemberByRoadCooperativeMemberId {
				id
				name
				userEmail
			}
		}
	}
`

const GET_ALL_ROAD_COOPERATIVE_PROPERTIES = gql`
	query GetAllRoadCooperativeProperties {
		roadCooperativeWithJWT {
			costCentersByRoadCooperativeId {
				nodes {
					id
					idColor
					name
					propertiesByCostCenterId {
						nodes {
							mmlPropertyId
							propertyTag
							propertyName
							id
							costCenterByCostCenterId {
								idColor
								name
							}
						}
					}
				}
			}
		}
	}
`

const GET_ALL_USERS = gql`
	query GetAlMembers {
		roadCooperativeWithJWT {
			roadCooperativeMembersByRoadCooperativeId(orderBy: NAME_ASC) {
				nodes {
					name
					id
					userEmail
				}
			}
		}
	}
`

const UPDATE_ESTABLISHMENT = gql`
	mutation UpdateEstablishment(
		$additionalInformation: String = ""
		$amount: Float = 1.5
		$discretionalyCorrectionMultiplier: Float = 1.5
		$distanceCorrectionMultiplier: Float = 1.5
		$id: Int = 10
		$operatingDistance: Float = 1.5
		$roadCooperativeMemberId: IdType = ""
		$roadUsageUnitId: IdType = ""
		$weightCorrectionMultiplier: Float = 1.5
		$propertyId: IdType = ""
	) {
		updateEstablishmentById(
			input: {
				establishmentPatch: {
					additionalInformation: $additionalInformation
					amount: $amount
					discretionalyCorrectionMultiplier: $discretionalyCorrectionMultiplier
					distanceCorrectionMultiplier: $distanceCorrectionMultiplier
					operatingDistance: $operatingDistance
					roadCooperativeMemberId: $roadCooperativeMemberId
					roadUsageUnitId: $roadUsageUnitId
					weightCorrectionMultiplier: $weightCorrectionMultiplier
					propertyId: $propertyId
				}
				id: $id
			}
		) {
			clientMutationId
		}
	}
`

const GET_ALL_ROAD_USAGE_UNITS = gql`
	query GetAllRoadUsageUnits {
		roadCooperativeWithJWT {
			roadUsageUnitsByRoadCooperativeId {
				nodes {
					id
					roadUsageUnit
					roadUsageUnitType
					weight
				}
			}
		}
	}
`

const DELETE_ESTABLISHMENT = gql`
	mutation DeleteEstablishment($id: Int = 10) {
		deleteEstablishmentById(input: { id: $id }) {
			clientMutationId
		}
	}
`

const GET_UNITS_PRESENTATION_TYPE = gql`
	query GetUnitsPresentationType {
		roadCooperativeWithJWT {
			roadCooperativeSettingsByRoadCooperativeId {
				nodes {
					id
					unitsPresentationType
				}
			}
		}
	}
`

type EditEstablishmentDialogType = {
	functionCallOnDataChange?: () => void
	setOpenEdit: React.Dispatch<React.SetStateAction<boolean>>
	id?: number
}

const EditEstablishmentDialog = ({ functionCallOnDataChange, setOpenEdit, id }: EditEstablishmentDialogType) => {
	const [establishmentData, setEstablishmentData] = useState<Establishment>()
	const [allUsers, setAllUsers] = useState<RoadCooperativeMemberType[] | null>(null)
	const [selectedUser, setSelectedUser] = useState<RoadCooperativeMemberType | null>(null)
	const [selectedProperty, setSelectedProperty] = useState<number | null>(null)
	const [roadUnits, setRoadUnits] = useState(0)
	const [allCCProperties, setAllCCProperties] = useState<Property[]>([])
	const [roadUsageUnit, setRoadUsageUnit] = useState<RoadUsageUnit | null>(null)
	const [openDelete, setOpenDelete] = useState(false)

	const [unitsViewType, setUnitsViewType] = useState<UnitsViewType>("professional")

	useQuery(GET_ALL_ROAD_COOPERATIVE_PROPERTIES, {
		onCompleted: (data) => {
			const costCenters = data.roadCooperativeWithJWT.costCentersByRoadCooperativeId.nodes
			let propertyList: Property[] = []
			for (let i = 0; costCenters.length > i; i++) {
				propertyList = propertyList.concat(costCenters[i].propertiesByCostCenterId.nodes)
			}
			propertyList.sort((a, b): any => {
				const aArr = a?.mmlPropertyId!.split("-") ?? ""
				const bArr = b?.mmlPropertyId!.split("-") ?? ""
				const length = aArr > bArr ? aArr.length : bArr.length
				for (let i = 0; i < length; i++) {
					if (Number(aArr[i]) > Number(bArr[i]) || bArr[i] === undefined) {
						return 1
					}
					if (Number(aArr[i]) < Number(bArr[i]) || aArr[i] === undefined) {
						return -1
					}
				}
				return 0
			})
			setAllCCProperties(propertyList)
		},
	})

	const { data: roadUsageUnits, loading: roadUsageUnitsLoading, error: roadUsageUnitsError } = useQuery(GET_ALL_ROAD_USAGE_UNITS)
	const [deleteEstablishment] = useMutation(DELETE_ESTABLISHMENT)
	const [updateEstablishment] = useMutation(UPDATE_ESTABLISHMENT)

	const { data } = useQuery(GET_UNITS_PRESENTATION_TYPE, {
		onCompleted(data) {
			setUnitsViewType(data.roadCooperativeWithJWT.roadCooperativeSettingsByRoadCooperativeId.nodes[0].unitsPresentationType)
		},
	})

	useQuery(GET_ESTABLISHMENT, {
		fetchPolicy: "network-only",
		variables: {
			id: id,
		},
		onCompleted: (data) => {
			setEstablishmentData(data.establishmentById)
			setSelectedUser(data.establishmentById.roadCooperativeMemberByRoadCooperativeMemberId)
			setRoadUsageUnit(data.establishmentById.roadUsageUnitByRoadUsageUnitId)
			setSelectedProperty(data.establishmentById.propertyId)
		},
	})

	useQuery(GET_ALL_USERS, {
		onCompleted: (data) => {
			setAllUsers(data.roadCooperativeWithJWT.roadCooperativeMembersByRoadCooperativeId.nodes)
		},
	})

	useEffect(() => {
		if (!establishmentData) return
		setRoadUnits(
			Math.round(
				establishmentData.operatingDistance *
					(roadUsageUnit?.weight ?? 0) *
					establishmentData.amount *
					establishmentData.discretionalyCorrectionMultiplier *
					establishmentData.weightCorrectionMultiplier *
					establishmentData.distanceCorrectionMultiplier
			)
		)
	}, [establishmentData, roadUsageUnit])

	const handleSaveChanges = () => {
		console.log(selectedUser, establishmentData, roadUsageUnit)
		if (establishmentData && roadUsageUnit != null && selectedUser) {
			// toast.loading("Tallennetaan muutoksia", { role: "global", toastId: "estUpdate" })
			updateEstablishment({
				variables: {
					additionalInformation: establishmentData.additionalInformation,
					roadUsageUnitId: Number(roadUsageUnit.id),
					discretionalyCorrectionMultiplier: Math.round(establishmentData.discretionalyCorrectionMultiplier * 1000) / 1000,
					distanceCorrectionMultiplier: Math.round(establishmentData.distanceCorrectionMultiplier * 1000) / 1000,
					operatingDistance: Math.round(establishmentData.operatingDistance * 1000) / 1000,
					amount: Math.round(establishmentData.amount * 1000) / 1000,
					roadCooperativeMemberId: Number(selectedUser.id),
					weightCorrectionMultiplier: Math.round(establishmentData.weightCorrectionMultiplier * 1000) / 1000,
					propertyId: selectedProperty,
					id: id,
				},
				onCompleted(data, clientOptions) {
					if (functionCallOnDataChange) functionCallOnDataChange()
					setOpenEdit(false)
				},
				onError(error, clientOptions) {
					null
				},
			})
		}
	}

	const handleDeleteEstablishmment = () => {
		// toast.loading("Poistetaan yksikköä", { role: "global", toastId: "estDelete" })
		deleteEstablishment({
			variables: { id: id },
			onCompleted: () => {
				if (functionCallOnDataChange) functionCallOnDataChange()
			},
			onError: () => {
				null
			},
		})
		setOpenEdit(false)
	}

	const handleChangeRoadUsageUnit = (usageUnit: RoadUsageUnit) => {
		if (usageUnit === undefined || !establishmentData) return
		setEstablishmentData({ ...establishmentData, roadUsageUnitId: usageUnit.id })
		setRoadUsageUnit(usageUnit)
	}

	return (
		<Box sx={{ textAlign: "center" }}>
			<Dialog sx={{ zIndex: "9999" }} open={openDelete}>
				<DialogCloseButton closeFunction={() => setOpenDelete(false)} />
				<CustomDialogTitle>Poista yksikkö</CustomDialogTitle>
				<DialogContent>
					<Typography>Haluatko varmasti poistaa yksikkötiedon?</Typography>
				</DialogContent>
				<CustomDialogActions>
					<Button
						variant="outlined"
						color="cancel"
						onClick={() => {
							setOpenDelete(false)
						}}
					>
						Peruuta
					</Button>
					<Button variant="contained" color="close" onClick={handleDeleteEstablishmment}>
						Kyllä
					</Button>
				</CustomDialogActions>
			</Dialog>
			{!establishmentData || !allCCProperties || !unitsViewType ? (
				<CircularProgress />
			) : (
				{
					easy: (
						<EasyEditView
							allCCProperties={allCCProperties}
							allUsers={allUsers}
							establishmentData={establishmentData}
							handleDeleteEstablishmment={handleDeleteEstablishmment}
							handleSaveChanges={handleSaveChanges}
							selectedProperty={selectedProperty}
							selectedUser={selectedUser}
							setEstablishmentData={setEstablishmentData}
							setSelectedProperty={setSelectedProperty}
							setSelectedUser={setSelectedUser}
							setOpenDelete={setOpenDelete}
						/>
					),
					professional: (
						<ProfessionalEditView
							allCCProperties={allCCProperties}
							allUsers={allUsers}
							establishmentData={establishmentData}
							handleChangeRoadUsageUnit={handleChangeRoadUsageUnit}
							handleDeleteEstablishmment={handleDeleteEstablishmment}
							handleSaveChanges={handleSaveChanges}
							roadUnits={roadUnits}
							roadUsageUnit={roadUsageUnit}
							roadUsageUnits={roadUsageUnits}
							roadUsageUnitsError={roadUsageUnitsError}
							roadUsageUnitsLoading={roadUsageUnitsLoading}
							selectedProperty={selectedProperty}
							selectedUser={selectedUser}
							setEstablishmentData={setEstablishmentData}
							setSelectedProperty={setSelectedProperty}
							setSelectedUser={setSelectedUser}
							setOpenDelete={setOpenDelete}
						/>
					),
				}[unitsViewType]
			)}
		</Box>
	)
}

type EasyEditViewProps = {
	establishmentData: Establishment
	setEstablishmentData: React.Dispatch<React.SetStateAction<Establishment | undefined>>
	selectedProperty: number | null
	setSelectedProperty: React.Dispatch<React.SetStateAction<number | null>>
	allCCProperties: Property[]
	selectedUser: RoadCooperativeMemberType | null
	allUsers: RoadCooperativeMemberType[] | null
	setSelectedUser: React.Dispatch<React.SetStateAction<RoadCooperativeMemberType | null>>
	handleDeleteEstablishmment: () => void
	handleSaveChanges: () => void
	setOpenDelete: React.Dispatch<React.SetStateAction<boolean>>
}

const EasyEditView = ({
	establishmentData,
	selectedProperty,
	setEstablishmentData,
	allCCProperties,
	setSelectedProperty,
	allUsers,
	handleDeleteEstablishmment,
	handleSaveChanges,
	selectedUser,
	setSelectedUser,
	setOpenDelete,
}: EasyEditViewProps) => {
	return (
		<>
			<FormControl fullWidth sx={{ marginTop: "10px", marginBottom: "5px", minWidth: "218px", maxWidth: "446px" }}>
				<InputLabel>Kiinteistötunnus</InputLabel>
				<Select
					inputProps={{ sx: { zIndex: "1308" } }}
					SelectDisplayProps={{ sx: { zIndex: "1308" } }}
					MenuProps={{ sx: { zIndex: "1308" } }}
					SelectProps={{ sx: { zIndex: 1308 } }}
					select
					sx={{ borderRadius: "10px" }}
					onChange={(e: { target: { value: number } }) => setEstablishmentData({ ...establishmentData, propertyId: e.target.value })}
					value={selectedProperty ?? ""}
					label="Kiinteistötunnus"
					variant="outlined"
				>
					{allCCProperties.map((property: any) => {
						return (
							<Tooltip value={property.id} title={property.costCenterByCostCenterId.name} key={property.id}>
								<MenuItem value={property.id} onClick={() => setSelectedProperty(property.id)}>
									{property.mmlPropertyId}
									{property.propertyTag !== 1 && property.propertyTag ? <>/{property.propertyTag}</> : null}{" "}
								</MenuItem>
							</Tooltip>
						)
					})}
				</Select>
			</FormControl>
			<MonetaryInput
				maximumDecimals={3}
				valueSetter={(v: number) => setEstablishmentData({ ...establishmentData, amount: v })}
				value={establishmentData.amount ?? 0}
				baseProps={{ sx: { width: "218px" } }}
				label="Tieyksiköt"
				variant="outlined"
			/>
			<TextField
				inputProps={{
					maxLength: 500,
				}}
				onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEstablishmentData({ ...establishmentData, additionalInformation: e.target.value })}
				value={establishmentData.additionalInformation}
				multiline
				maxRows={4}
				label="Lisätietoa"
				variant="outlined"
				sx={{ width: "218px", paddingBottom: "10px" }}
				fullWidth
			></TextField>
			<Divider sx={{ width: "80%", marginLeft: "10%" }}></Divider>
			<CustomNotification severity="info" CollapseProps={{ width: "80%", marginLeft: "10%" }} TypographyProps={{ fontWeight: "500", fontSize: "0.8rem" }}>
				Voit siirtää yksiköt osakkaalta toiselle valitsemalla uusi osakas alla olevasta pudotusvalikosta
			</CustomNotification>
			<FormControl sx={{ marginTop: "10px", width: "80%" }}>
				<InputLabel sx={{ marginTop: "10px" }}>Osakas</InputLabel>
				<Select
					select
					value={selectedUser?.id ?? ""}
					label={<Typography sx={{ fontWeight: "500" }}>Osakas</Typography>}
					fullWidth
					sx={{ maxWidth: "100%", margin: 0, marginTop: "10px", zIndex: "1308", borderRadius: "10px" }}
					inputProps={{ sx: { zIndex: "1308" } }}
					SelectDisplayProps={{ sx: { zIndex: "1308" } }}
					MenuProps={{ sx: { zIndex: "1308" } }}
					SelectProps={{ sx: { zIndex: 1308 } }}
				>
					{!allUsers ? (
						<MenuItem value={0}>ladataan...</MenuItem>
					) : (
						allUsers.map((user: RoadCooperativeMemberType, index: number) => {
							return (
								<MenuItem value={user.id} key={index} onClick={() => setSelectedUser(user)}>
									{user.name}
								</MenuItem>
							)
						})
					)}
				</Select>
			</FormControl>
			{isMobile().any ? (
				<IconButton
					onClick={() => setOpenDelete(true)}
					icon={<DeleteIcon />}
					sx={{
						position: "absolute",
						top: "5px",
						left: "5px",
						// width: '100%',
						// overflow: 'hidden'
					}}
				>
					<DeleteIcon />
				</IconButton>
			) : (
				<Chip
					// color='primary'
					label="Poista"
					variant="contained"
					onClick={() => setOpenDelete(true)}
					icon={<DeleteIcon />}
					sx={{
						position: "absolute",
						top: "10px",
						left: "10px",
						// width: '100%',
						// overflow: 'hidden'
					}}
				/>
			)}
			<Stack direction="row" sx={{ bottom: "5px", right: "5px", position: "absolute" }}>
				<Button sx={{ marginTop: 0 }} variant="contained" color="primary" onClick={handleSaveChanges}>
					Tallenna{" "}
				</Button>
			</Stack>
		</>
	)
}

type ProfessionalEditViewProps = {
	establishmentData: Establishment
	setEstablishmentData: React.Dispatch<React.SetStateAction<Establishment | undefined>>
	selectedProperty: number | null
	setSelectedProperty: React.Dispatch<React.SetStateAction<number | null>>
	allCCProperties: Property[]
	roadUsageUnitsLoading: boolean
	roadUsageUnitsError: any
	roadUsageUnits: any
	handleChangeRoadUsageUnit: (usageUnit: RoadUsageUnit) => void
	roadUnits: number
	roadUsageUnit: RoadUsageUnit | null
	selectedUser: RoadCooperativeMemberType | null
	allUsers: RoadCooperativeMemberType[] | null
	setSelectedUser: React.Dispatch<React.SetStateAction<RoadCooperativeMemberType | null>>
	handleDeleteEstablishmment: () => void
	handleSaveChanges: () => void
	setOpenDelete: React.Dispatch<React.SetStateAction<boolean>>
}

const ProfessionalEditView = ({
	establishmentData,
	selectedProperty,
	setEstablishmentData,
	allCCProperties,
	setSelectedProperty,
	allUsers,
	handleChangeRoadUsageUnit,
	handleDeleteEstablishmment,
	handleSaveChanges,
	roadUnits,
	roadUsageUnits,
	roadUsageUnit,
	roadUsageUnitsError,
	roadUsageUnitsLoading,
	selectedUser,
	setSelectedUser,
	setOpenDelete,
}: ProfessionalEditViewProps) => {
	return (
		<>
			<TextField
				inputProps={{ sx: { zIndex: "1308" } }}
				SelectDisplayProps={{ sx: { zIndex: "1308" } }}
				MenuProps={{ sx: { zIndex: "1308" } }}
				SelectProps={{ sx: { zIndex: 1308 } }}
				select
				sx={{ borderRadius: "10px", width: "218px", height: "56px" }}
				onChange={(e: { target: { value: number } }) => setEstablishmentData({ ...establishmentData, propertyId: e.target.value })}
				value={selectedProperty ?? ""}
				label="Kiinteistötunnus"
				variant="outlined"
			>
				{allCCProperties.map((property: any) => {
					return (
						<MenuItem value={property.id} onClick={() => setSelectedProperty(property.id)} key={property.id}>
							<Tooltip value={property.id} title={property.costCenterByCostCenterId.name} key={property.id}>
								<Typography sx={{ width: "100%", fontWeight: "400" }}>
									{property.mmlPropertyId} {property.propertyTag ? <>/{property.propertyTag}</> : null}
								</Typography>
							</Tooltip>
						</MenuItem>
					)
				})}
			</TextField>

			<TextField
				inputProps={{
					maxLength: 100,
					sx: { zIndex: "1308" },
				}}
				fullWidth
				SelectDisplayProps={{ sx: { zIndex: "1308" } }}
				MenuProps={{ sx: { zIndex: "1308" } }}
				SelectProps={{ sx: { zIndex: 1308 } }}
				variant="outlined"
				sx={{ borderRadius: "10px", maxWidth: "218px" }}
				id="select-usage-unit-type"
				select
				label="Liikennelaji"
				value={establishmentData.roadUsageUnitId ?? ""}
				onChange={(e: { target: { value: number } }) => setEstablishmentData({ ...establishmentData, roadUsageUnitId: e.target.value })}
			>
				{roadUsageUnitsLoading || roadUsageUnitsError ? (
					<MenuItem value={0}>Ladataan...</MenuItem>
				) : (
					roadUsageUnits.roadCooperativeWithJWT.roadUsageUnitsByRoadCooperativeId.nodes.map((t: RoadUsageUnit) => {
						return (
							<MenuItem onClick={() => handleChangeRoadUsageUnit(t)} key={t} value={t.id}>
								{t.roadUsageUnitType} {t.roadUsageUnit} {t.weight}
							</MenuItem>
						)
					})
				)}
			</TextField>
			<TextField disabled value={roadUsageUnit?.weight ?? 0} sx={{ width: "218px" }} label="Painoluku (t)" variant="outlined"></TextField>

			<MonetaryInput
				maximumDecimals={3}
				valueSetter={(v: number) => setEstablishmentData({ ...establishmentData, weightCorrectionMultiplier: v })}
				value={establishmentData.weightCorrectionMultiplier}
				baseProps={{ sx: { width: "218px" } }}
				label="Painoluvun korj.ker."
				variant="outlined"
			/>
			<MonetaryInput
				maximumDecimals={3}
				valueSetter={(v: number) => setEstablishmentData({ ...establishmentData, operatingDistance: v })}
				value={establishmentData.operatingDistance}
				baseProps={{ sx: { width: "218px" } }}
				label="Käyttömatka (km)"
				variant="outlined"
			/>
			<MonetaryInput
				maximumDecimals={3}
				valueSetter={(v: number) => setEstablishmentData({ ...establishmentData, distanceCorrectionMultiplier: v })}
				value={establishmentData.distanceCorrectionMultiplier}
				baseProps={{ sx: { width: "218px" } }}
				label="Matkan korj.ker."
				variant="outlined"
			/>

			<MonetaryInput
				maximumDecimals={3}
				valueSetter={(v: number) => setEstablishmentData({ ...establishmentData, amount: v })}
				value={establishmentData.amount ?? 0}
				baseProps={{ sx: { width: "218px" } }}
				label="Lukumäärä (ha/kpl)"
				variant="outlined"
			/>
			<MonetaryInput
				maximumDecimals={3}
				valueSetter={(v: number) => setEstablishmentData({ ...establishmentData, discretionalyCorrectionMultiplier: v })}
				value={establishmentData.discretionalyCorrectionMultiplier}
				baseProps={{ sx: { width: "218px" } }}
				label="Harkinnanvarainen korj.ker."
				variant="outlined"
			/>
			<TextField
				inputProps={{
					maxLength: 500,
				}}
				onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEstablishmentData({ ...establishmentData, additionalInformation: e.target.value })}
				value={establishmentData.additionalInformation}
				multiline
				maxRows={4}
				label="Lisätietoa"
				variant="outlined"
				sx={{ width: "218px", paddingBottom: "10px" }}
				fullWidth
			></TextField>
			<TextField
				disabled
				onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEstablishmentData({ ...establishmentData, roadUnits: roadUnits })}
				value={roadUnits}
				label="Tieyksiköt"
				sx={{ width: "218px" }}
				variant="outlined"
			></TextField>
			<Divider sx={{ width: "80%", marginLeft: "10%" }}></Divider>
			<CustomNotification severity="info" CollapseProps={{ width: "80%", marginLeft: "10%" }} TypographyProps={{ fontWeight: "500", fontSize: "0.8rem" }}>
				Voit siirtää yksiköt osakkaalta toiselle valitsemalla uusi osakas alla olevasta pudotusvalikosta
			</CustomNotification>
			<FormControl sx={{ marginTop: "10px", width: "80%" }}>
				<InputLabel sx={{ marginTop: "10px" }}>Osakas</InputLabel>
				<Select
					select
					value={selectedUser?.id ?? ""}
					label={<Typography sx={{ fontWeight: "500" }}>Osakas</Typography>}
					fullWidth
					sx={{ maxWidth: "100%", margin: 0, marginTop: "10px", zIndex: "1308", borderRadius: "10px" }}
					inputProps={{ sx: { zIndex: "1308" } }}
					SelectDisplayProps={{ sx: { zIndex: "1308" } }}
					MenuProps={{ sx: { zIndex: "1308" } }}
					SelectProps={{ sx: { zIndex: 1308 } }}
				>
					{!allUsers ? (
						<MenuItem value={0}>ladataan...</MenuItem>
					) : (
						allUsers.map((user: RoadCooperativeMemberType, index: number) => {
							return (
								<MenuItem value={user.id} key={index} onClick={() => setSelectedUser(user)}>
									{user.name}
								</MenuItem>
							)
						})
					)}
				</Select>
			</FormControl>
			{isMobile().any ? (
				<IconButton
					onClick={() => setOpenDelete(true)}
					icon={<DeleteIcon />}
					sx={{
						position: "absolute",
						top: "5px",
						left: "5px",
						// width: '100%',
						// overflow: 'hidden'
					}}
				>
					<DeleteIcon />
				</IconButton>
			) : (
				<Chip
					// color='primary'
					label="Poista"
					variant="contained"
					onClick={() => setOpenDelete(true)}
					icon={<DeleteIcon />}
					sx={{
						position: "absolute",
						top: "10px",
						left: "10px",
						// width: '100%',
						// overflow: 'hidden'
					}}
				/>
			)}
			<Stack direction="row" sx={{ bottom: "5px", right: "5px", position: "absolute" }}>
				<Button sx={{ marginTop: 0 }} variant="contained" color="primary" onClick={handleSaveChanges}>
					Tallenna{" "}
				</Button>
			</Stack>
		</>
	)
}

export default EditEstablishmentDialog
