import { Theme, createTheme } from "@mui/material/styles"
import { green } from "@mui/material/colors"

import { Palette as MuiPallete, PaletteOptions as MuiPaletteOptions } from "@mui/material/styles/createPalette"

//Tämä mahdollistaa close ja cancel värit ilman että ts sekoaa..
declare module "@mui/material/styles/createPalette" {
	interface Palette {
		close?: Palette["primary"]
		cancel?: Palette["primary"]
		primaryActive?: Palette["primary"]
	}

	interface PaletteOptions {
		close?: PaletteOptions["primary"]
		cancel?: PaletteOptions["primary"]
		primaryActive?: Palette["primary"]
	}
}

// tämä mahdollistaa colorin overriden..
declare module "@mui/material/Button" {
	export interface ButtonPropsColorOverrides {
		close: true
		cancel: true
		primaryActive: true
	}
}

declare module "@mui/material/SvgIcon" {
	export interface SvgIconPropsColorOverrides {
		close: true
		cancel: true
		primaryActive: true
	}
}
// interface customPalette extends Palette {
// }

// interface customTheme extends Theme {
// 	palette: customPalette
// }

const customTheme = createTheme({
	palette: {
		primary: {
			main: "#7FC646",
			contrastText: "rgba(255,255,255,0.87)",
			light: "#70d273",
			dark: "#4ca239",
		},
		primaryActive: {
			main: "#287800",
			contrastText: "rgba(255,255,255,0.87)",
			light: "#70d273",
			dark: "#4ca239",
		},
		secondary: {
			main: "#FEAD2D",
			light: "#ffe3b8",
			dark: "#af6903",
			contrastText: "rgba(6,5,5,0.87)",
		},
		close: {
			main: "#eb1118",
			contrastText: "rgba(255,255,255,0.87)",
			light: "#fd6d72",
			dark: "#d60e14",
		},
		cancel: {
			main: "#505050",
			contrastText: "#505050",
			light: "",
			dark: "",
		},
		grey: {
			700: "#505050",
			300: "#DDDDDD",
		},
		text: {
			primary: "#505050",
		},
	},
	typography: {
		// allVariants: {
		// 	fontSize: "0.8rem",
		// },
		button: {
			fontWeight: 500,
			lineHeight: 2.15,
			fontSize: "0.9rem",
			// letterSpacing: "0.13em",
			letterSpacing: "0em",
			textTransform: "none",
		},
		fontFamily: "Poppins",
	},
	components: {
		MuiTableRow: {
			styleOverrides: {
				hover: {
					backgroundColor: "#FEAD2D",
					color: "#FEAD2D",
				},
			},
		},
		MuiTextField: {
			styleOverrides: {
				root: {
					"& .MuiOutlinedInput-root": {
						borderRadius: "10px",
					},
					// marginTop: '10px',
					margin: "5px",
					// padding: '10px',
					"& .Mui-disabled": {
						color: "rgba(0, 0, 0, 0.6)", // (default alpha is 0.38)
					},
					"& .MuiOutlinedInput-root.Mui-disabled": {
						"& fieldset": {
							borderColor: "rgba(0, 0, 0, 0.15)",
							// color: "rgba(0, 0, 0, 0.9)"
						},
					},
					"& .MuiOutlinedInput-notchedOutline": {
						borderColor: "rgba(0, 0, 0, 0.3)",
					},
				},
			},
		},
		MuiSelect: {
			styleOverrides: {
				filled: {
					background: "green",
					borderRadius: "10px",
				},
			},
		},

		MuiButton: {
			styleOverrides: {
				root: {
					// marginTop: '5px',
					// padding: "5px",
					minWidth: "100px",
					fontSize: "1rem",
					borderRadius: "10px",
				},
			},
		},
		MuiDialogTitle: {
			styleOverrides: {
				root: {
					color: "rgb(80 80 80)",
				},
			},
		},
		MuiPaper: {
			styleOverrides: {
				root: {
					borderRadius: "10px",
					// paddingLeft: "50px",
					// paddingRight: "50px",
				},
			},
		},
		MuiTypography: {
			styleOverrides: {
				root: {
					color: "#505050",
					fontWeight: "700",
				},
			},
		},
		MuiTab: {
			styleOverrides: {
				root: {
					color: "#505050",
					fontWeight: "700",
					letterSpacing: "0",
					fontSize: "0.875rem",
				},
			},
		},
		MuiToggleButton: {
			styleOverrides: {
				root: {
					borderRadius: "10px",
				},
			},
		},
		MuiChip: {
			styleOverrides: {
				root: {
					borderRadius: "10px",
				},
			},
		},
		// MuiFormLabel: {
		// 	styleOverrides: {
		// 		root: {
		// 			fontWeight: "700",
		// 		},
		// 	},
		// },
	},
})

const customThemeDark = createTheme({
	palette: {
		primary: {
			main: "#fafafa",
			contrastText: "rgba(255,255,255,0.87)",
			light: "#70d273",
			dark: "#4ca239",
		},
		secondary: {
			main: "#505050",
			light: "#ef9717",
			dark: "#af6903",
			contrastText: "rgba(6,5,5,0.87)",
		},
		grey: {
			700: "#505050",
		},
	},

	typography: {
		button: {
			fontWeight: 500,
			lineHeight: 2.15,
			fontSize: "0.9rem",
			letterSpacing: "0.13em",
			textTransform: "none",
		},
		fontFamily: "Poppins",
	},
	components: {
		MuiTextField: {
			styleOverrides: {
				root: {
					marginTop: "10px",
					marginLeft: "5px",
					paddingRight: "10px",
				},
			},
		},
		MuiSelect: {
			styleOverrides: {
				filled: green,
			},
		},
		MuiButton: {
			styleOverrides: {
				root: {
					marginTop: "5px",
					padding: "5px",
					fontSize: "1rem",
				},
			},
		},
	},
})

export default {
	customTheme,
	customThemeDark,
}
