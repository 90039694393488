import axiosClient from "../axiosClient/axiosClient"

const createRoadCooperative = async (requestBody: any) => {
	try {
		const res = await axiosClient({
			method: "post",
			url: "/api/roadCooperative/create",
			data: requestBody,
		})
		return res
	} catch (error) {
		console.error(error)
		return null
	}
}

export { createRoadCooperative }
