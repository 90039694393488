import { DialogContent } from "@mui/material"
import { Button } from "@mui/material"
import { Typography } from "@mui/material"
import { Dialog } from "@mui/material"
import React, { SetStateAction } from "react"
import DialogCloseButton from "../../reusables/DialogCloseButton"
import CustomDialogTitle from "../../reusables/CustomDialogTitle"
import CustomDialogActions from "../../reusables/CustomDialogActions"

type NoticationDialogProps = {
	dialogTitle: string
	executefunction?: (functionParams?: any) => void
	functionParams?: any
	setOpenState: React.Dispatch<SetStateAction<boolean>>
	openState: boolean
	executeButtonText?: string
	children?: React.ReactNode
}

const NotificationDialogWithZIndex = ({
	dialogTitle,
	children,
	executefunction = () => null,
	functionParams,
	setOpenState,
	openState,
	executeButtonText,
}: NoticationDialogProps) => {
	const handleFunction = () => {
		try {
			if (functionParams) {
				executefunction(functionParams)
			} else {
				executefunction()
			}
		} catch (error) {
			console.error("Error in NotificationDialog executeFunction")
		}
		setOpenState(false)
	}

	return (
		<Dialog open={openState} sx={{ zIndex: "1305" }}>
			<DialogCloseButton closeFunction={() => setOpenState(false)} />
			<CustomDialogTitle>{dialogTitle}</CustomDialogTitle>
			<DialogContent>
				<Typography>{children}</Typography>
			</DialogContent>
			<CustomDialogActions>
				<Button variant="outlined" color="cancel" onClick={() => setOpenState((prevValue) => !prevValue)}>
					{" "}
					Peruuta{" "}
				</Button>
				<Button variant="contained" color="close" onClick={handleFunction}>
					{" "}
					{executeButtonText ? executeButtonText : <>Jatka</>}{" "}
				</Button>
			</CustomDialogActions>
		</Dialog>
	)
}

export default NotificationDialogWithZIndex
