import axios from "axios"
import { toast } from "react-toastify"
import authToken from "../../utils/authToken"
import config from "../../utils/config"
import userProfileStorage from "../../utils/providers/userProfileProvider/userProfileProvider"
import axiosClient from "../axiosClient/axiosClient"
import { clearWholeStorage } from "../../components/UnitizationTool/unitizationToolUtils/unitizationStorage"
import { removeAllMarkersFromStorage } from "../../components/UnitizationTool/MapFeatureSelectBox/RouteDistanceCalculator/markersStorage"

const baseURL = config.BACKEND_ROOT_URL

const logoutUser = () => {
	const requestBody = {
		token: authToken.getToken(),
	}
	axios
		.post(`${baseURL}/logout`, requestBody, {
			headers: {
				Authorization: "Bearer " + authToken.getToken(),
			},
		})
		.catch((err) => {
			console.error(" --- Error in logout: ", err, " --- ")
		})
		.finally(() => {
			userProfileStorage({ type: "logout" })
			authToken.deleteToken()
			authToken.deleteRefreshToken()
			removeAllMarkersFromStorage()
			window.location.href = "/"
		})
}

const forceLogoutUser = () => {
	const requestBody = {
		token: authToken.getToken(),
	}
	axios
		.post(`${baseURL}/logout/force`, requestBody, {
			headers: {
				Authorization: "Bearer " + authToken.getToken(),
			},
		})
		.catch((err) => {
			console.error(" --- Error in logout: ", err, " --- ")
		})
		.finally(() => {
			userProfileStorage({ type: "logout" })
			authToken.deleteToken()
			authToken.deleteRefreshToken()
			removeAllMarkersFromStorage()
			window.location.href = "/"
		})
}

const getUserRoadCooperatives = async () => {
	try {
		const res = await axiosClient({
			method: "post",
			url: "/api/user/allRoadCooperatives",
		})

		return res
	} catch (error) {
		console.error(error)
		return null
	}
}

const getUserNotes = async () => {
	try {
		const res = await axiosClient({
			method: "get",
			url: "/api/user/allNotes",
		})

		return res
	} catch (error) {
		console.error(error)
		return null
	}
}

const createUserNote = async (note: Note) => {
	try {
		const res = await axiosClient({
			method: "post",
			url: "/api/user/createNote",
			data: note,
		})

		return res
	} catch (error) {
		console.error(error)
		return null
	}
}

const updateNote = async (note: Note) => {
	try {
		if (note.id == undefined) throw 403

		const res = await axiosClient({
			method: "post",
			url: "/api/user/updateNote",
			data: note,
		})

		return res
	} catch (error) {
		console.error(error)
		return null
	}
}

const deleteNote = async (id: string) => {
	try {
		if (id == undefined) throw 403

		const res = await axiosClient({
			method: "post",
			url: "/api/user/deleteNote",
			data: id,
		})

		return res
	} catch (error) {
		console.error(error)
		return null
	}
}

const selectRoadCooperative = async (name?: string, id?: string) => {
	try {
		if (!id || !name) throw Error("invalid values")
		const res = await axiosClient({
			method: "post",
			url: "/api/user/selectRoadCooperative",
			data: {
				id: id,
			},
		})
		await userProfileStorage({
			type: "setActiveRoadCooperative",
			payload: { roadCooperative: name, role: "Puheenjohtaja", id: id, permission: res.data.permission },
		})
		authToken.setToken(res.data.token)

		// Tyhjennetään yksiköintiin liittyvä localStarage data kun tiekunta vaihtuu
		clearWholeStorage()
		// Clear the prompt so that the prompt will be done again if road cooperative is switched
		sessionStorage.removeItem("accountRenewPrompted")
		window.location.reload()
	} catch (error) {
		console.error(error)
		return null
	}
}

interface customUserBillingInfo extends UserBillingInformationType {
	roadCooperativeName: string
	isMember?: boolean
}

const getAllUserBillingInformations = async () => {
	const permission = userProfileStorage({ type: "getActiveRoadCooperative" }).permission
	try {
		if (permission !== "municipality") {
			const res = await axiosClient({
				method: "GET",
				url: "/api/user/allUserMemberAndBillingInfos",
			})

			const data = await res.data
			const membersInfos: customUserBillingInfo[] = []
			for (let i = 0; data.length > i; i++) {
				const newInfoObj: customUserBillingInfo = {
					billingAddress: data[i].billing_address,
					businessId: data[i].business_id,
					contactPerson: data[i].contact_person,
					countryCode: data[i].country_code,
					eInvoiceAddress: data[i].e_invoice_address,
					eInvoiceOperator: data[i].e_invoice_operator,
					email: data[i].email,
					id: data[i].id,
					invoiceSendingType: data[i].invoice_sending_type,
					languageCode: data[i].language_code,
					meetingInviteType: data[i].meeting_invite_type,
					name: data[i].name,
					postcode: data[i].postcode,
					phoneNumber: data[i].phone_number,
					postalDistrict: data[i].postal_district,
					roadCooperativeName: data[i].road_cooperative_name,
					isMember: data[i].type == "member" ? true : false,
				}
				membersInfos.push(newInfoObj)
			}
			return membersInfos
		} else return null
	} catch (error) {
		console.error(error)

		return null
	}
}

const registerUser = async (requestBody: any) => {
	try {
		const res = await axios({ url: `${baseURL}/register`, method: "post", data: requestBody })
		if (res.status < 300) {
			return { status: 200, res }
		} else {
			throw { status: 400, res }
		}
	} catch (error) {
		console.error(error)
		return { status: 400, error }
	}
}

const verifyUserEmail = async (verificationId: string) => {
	try {
		const body = {
			verificationId: verificationId,
		}
		console.log(body)
		const res = await axios({ url: `${baseURL}/verifyEmail`, method: "post", data: body })
		console.log(res)
		if (res.status < 300) {
			return res
		} else {
			throw 400
		}
	} catch (err) {
		return { status: 400 }
	}
}

const changePassword = async (changePasswordId: string, newPassword: string) => {
	try {
		const body = {
			changePasswordId: changePasswordId,
			password: newPassword,
		}
		console.log(body)
		const changePasswordRes = await axios({
			url: baseURL + "/login/changePassword",
			method: "post",
			data: body,
		})
		if (changePasswordRes.status < 300) {
			return changePasswordRes
		} else {
			throw 400
		}
	} catch (error: any) {
		if (error.message === "Network Error") {
			console.error(error)
			toast.error("Yhteyden muodostaminen palvelimelle epäonnistui. Yritä myöhemmin uudelleen.", { role: "global" })
		} else {
			toast.error("Virhe salasanan vaihtamisessa. Yritä myöhemmin uudelleen.")
		}
	}
}

const loginUser = async (email: string, password: string) => {
	try {
		const requestBody = {
			loginId: email,
			password: password,
		}
		const loginRes = await axios({
			url: baseURL + "/login",
			method: "post",
			data: requestBody,
		})
		authToken.setToken(loginRes.data.data.token)
		authToken.setRefreshToken(loginRes.data.data.refreshToken)
		userProfileStorage({ type: "setUserProfile", payload: { name: loginRes.data.data.name, email: loginRes.data.data.email } })
		window.location.reload()
	} catch (error: any) {
		if (error.message === "Network Error") {
			console.error(error)
			toast.error("Yhteyden muodostaminen palvelimelle epäonnistui. Yritä myöhemmin uudelleen.", { role: "global" })
		} else if (error.response && error.response.status === 403) {
			toast.error("Virhe kirjautumisessa. Vahvista käyttäjä sähköpostiin tulleesta linkistä ennen kirjautumista.")
		} else {
			toast.error("Virhe kirjautumisessa. Sähköposti tai salasana väärin.", { role: "global" })
		}
	}
}

export {
	getUserRoadCooperatives,
	selectRoadCooperative,
	getAllUserBillingInformations,
	registerUser,
	loginUser,
	getUserNotes,
	createUserNote,
	updateNote,
	deleteNote,
	logoutUser,
	forceLogoutUser,
	verifyUserEmail,
	changePassword,
}
