import React, { ReactElement, useEffect, useState } from "react"
import { TableRow } from "@mui/material"
import { Stack } from "@mui/material"
import { TextField } from "@mui/material"
import { TableCell } from "@mui/material"
import { TableHead } from "@mui/material"
import { Table } from "@mui/material"

const ContactSearch = ({ contacts, setFilteredContactList }: any) => {
	const handleFilterChange = (value: string) => {
		if (!value) {
			setFilteredContactList(contacts)
		} else {
			const temp: Contact[] = []
			for (let i = 0, len = contacts.length; i < len; i++) {
				if (
					contacts[i].name?.toUpperCase().includes(value.toUpperCase()) ||
					contacts[i].additionalInformation?.toUpperCase().includes(value.toUpperCase())
				) {
					if (!temp.includes(contacts[i])) {
						temp.push(contacts[i])
					}
				}
			}
			setFilteredContactList(temp)
		}
	}

	return (
		<>
			<Table stickyHeader size="medium" padding="none">
				<TableHead>
					<TableRow>
						<TableCell sx={{ width: "1px", margin: 0, padding: 0 }}></TableCell>
						<Stack
							fullWidth
							spacing={3}
							direction={"row"}
							sx={{
								paddingLeft: "0px",
								alignItems: "center",
								justifyContent: "center",
								marginTop: "5px",
							}}
						>
							<TextField
								size="small"
								fullWidth
								label="Hae"
								color="primary"
								sx={{ maxWidth: "300px", width: screen.width < 600 ? 250 : 460 }}
								onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleFilterChange(e.target.value)}
							/>
						</Stack>
					</TableRow>
				</TableHead>
			</Table>
		</>
	)
}

export default ContactSearch
